import { type Dispatch, createContext, useReducer, useEffect, useCallback } from 'react'
import { type AuthUser, type AuthType } from '../hooks/use-auth'
import { sendGet } from '../hooks/use-fetch'

const initialAuth: AuthType = {
  user: null,
  loading: true,
  setCurrentUser: async () => {},
  miCredsLogin: () => {},
  miLaunchPadLogin: () => {},
  logout: () => {},
  impersonate: async () => {},
  endImpersonation: async () => {},
  isImpersonating: () => false,
  setCurrentOrg: async () => {}
}

export const AuthContext = createContext<AuthType>(initialAuth)
export const AuthDispatchContext = createContext<Dispatch<recordUpdate>>(() => null)

export type recordUpdate = loadingUpdate | userUpdate
export interface loadingUpdate {
  type: 'setLoading'
  value: boolean
}

export interface userUpdate {
  type: 'setUser'
  user: AuthUser | null
}

export function AuthProvider (props: { children: JSX.Element }): JSX.Element {
  const setCurrentUser = useCallback(async () => {
    const { response } = await sendGet('Account/CurrentUser')
    try {
      const data = response
      dispatch({ user: data, type: 'setUser' })
    } catch (e) {
      dispatch({ user: null, type: 'setUser' })
    }
    dispatch({ type: 'setLoading', value: false })
  }, [])

  const [auth, dispatch] = useReducer(authReducer, { ...initialAuth, setCurrentUser })

  let active = true

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      await setCurrentUser()
    }
    if (active) {
      dispatch({ type: 'setLoading', value: true })

      void loadData()
    }
    return () => {
      active = false
    }
  }, [])

  return (
    <AuthContext.Provider value={auth}>
      <AuthDispatchContext.Provider value={dispatch}>
        {props.children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  )
}

function authReducer (
  auth: AuthType,
  action: recordUpdate
): AuthType {
  switch (action.type) {
    case 'setUser':
    {
      const newAuth: AuthType = { ...auth, user: action.user }
      return newAuth
    }
    case 'setLoading':{
      return { ...auth, loading: action.value }
    }
    default: {
      throw Error('Unknown action: ')
    }
  }
}
