import { Box, InputLabel, Radio, RadioGroup, type SxProps } from '@mui/material'

interface Props {
  readOnly?: boolean
  initialValue?: number
  sx?: SxProps
  name?: string
  label?: string
  labelContent?: JSX.Element
  required?: boolean
  showRequiredText?: boolean
  error?: boolean
  checkedBackgroundColor?: string
  checkedBorderColor?: string
  onValueSelected?: (value: number) => void
  onClick?: (e: React.MouseEvent) => void
}

export const RatingRadio = (props: Props): JSX.Element => {
  const handleValueSelected = (value: string): void => {
    if (props.onValueSelected != null) {
      props.onValueSelected(parseInt(value))
    }
  }

  return <Box>
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <InputLabel
        sx={{ color: 'black' }}
        id={props.name}
        required={props.required === true && props.showRequiredText !== true}
        disableAnimation={true}
        variant="standard"
      >
        {props.labelContent ?? props.label}
      </InputLabel>
      {props.required !== true && props.showRequiredText === true && <Box sx={{ fontWeight: 200 }}>Optional</Box>}
      {props.required === true && props.showRequiredText === true && <Box sx={{ fontWeight: 400, color: '#E00000' }}>Required</Box>}
    </Box>

    <RadioGroup
      row={true}
      value={props.initialValue ?? ''}
      sx={{ ...props.sx, border: props.error === true ? '1px solid #E00000' : '', borderRadius: '4px', flexWrap: 'nowrap' }}
      data-testid='ratingRadio'
      onChange={(e, value) => { handleValueSelected(value) }}
      onClick={props.onClick}
    >
      <RadioButton value={0} readOnly={props.readOnly} dataTestId='rating-0' checkedBackgroundColor={props.checkedBackgroundColor} checkedBorderColor={props.checkedBorderColor} />
      <RadioButton value={1} readOnly={props.readOnly} dataTestId='rating-1' checkedBackgroundColor={props.checkedBackgroundColor} checkedBorderColor={props.checkedBorderColor} />
      <RadioButton value={2} readOnly={props.readOnly} dataTestId='rating-2' checkedBackgroundColor={props.checkedBackgroundColor} checkedBorderColor={props.checkedBorderColor} />
      <RadioButton value={3} readOnly={props.readOnly} dataTestId='rating-3' checkedBackgroundColor={props.checkedBackgroundColor} checkedBorderColor={props.checkedBorderColor} />
      <RadioButton value={4} readOnly={props.readOnly} dataTestId='rating-4' checkedBackgroundColor={props.checkedBackgroundColor} checkedBorderColor={props.checkedBorderColor} />
    </RadioGroup>
  </Box>
}

interface RadioButtonProps {
  value: number
  readOnly?: boolean
  checked?: boolean
  checkedBackgroundColor?: string
  checkedBorderColor?: string
  dataTestId?: string
}

export const RadioButton = (props: RadioButtonProps): JSX.Element => {
  return <Radio
    value={props.value}
    disableRipple={true}
    disabled={props.readOnly}
    checked={props.checked}
    icon={<RadioIcon value={props.value} checked={false} />}
    checkedIcon={<RadioIcon value={props.value} checked={true} backgroundColor={props.checkedBackgroundColor} borderColor={props.checkedBorderColor} />}
    inputProps={{ 'aria-label': props.value.toString() }}
    sx={{ p: '0 3px' }}
    data-testid={props.dataTestId}
  />
}

interface RadioIconProps {
  value: number
  checked: boolean
  backgroundColor?: string
  borderColor?: string
}

const RadioIcon = (props: RadioIconProps): JSX.Element => {
  return <Box
    sx={{
      width: '2em',
      height: '2em',
      border: `1px solid ${props.borderColor ?? '#59555B'}`,
      borderRadius: '1.25em',
      textAlign: 'center',
      alignContent: 'center',
      fontWeight: '600',
      background: props.checked ? (props.backgroundColor != null ? undefined : 'linear-gradient(96.34deg, #021E40 0%, #006699 100%)') : 'transparent',
      backgroundColor: props.checked && props.backgroundColor != null ? props.backgroundColor : undefined,
      color: props.checked ? 'white' : 'black'
    }}
  >
    {props.value}
  </Box>
}
