interface Props {
  width?: string
  height?: string
  opacity?: string | number
}

export const ResourceLayers = (props: Props): JSX.Element => {
  return <svg width={props.width ?? '16'} height={props.height ?? '16'} opacity={props.opacity} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 6.83333L14 8.33333L8 11.3333L2 8.33333L5 6.83333M11 10.1667L14 11.6667L8 14.6667L2 11.6667L5 10.1667M8 2L14 5L8 8L2 5L8 2Z" stroke="#3B353E" strokeWidth="2"/>
  </svg>
}
