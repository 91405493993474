import { Grid, Typography } from '@mui/material'
import { SummaryCard } from '../../customComponents/SummaryCard'
import { FormatDateAsTimeAgo } from '../../../utils/dateUtils'
import { type ImplementationTimeSpan } from '../../../models/ImplementationTimeSpan'
import { useEffect, useState } from 'react'
import { GetImplementationTimeSpans } from '../../../services/ImplementationService'
import { generatePath, useNavigate } from 'react-router'
import { AppRoutes } from '../../Routes'

export const Implementation = (): JSX.Element => {
  const nav = useNavigate()

  const [timeSpans, setTimeSpans] = useState<ImplementationTimeSpan[]>([])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const implementationTimeSpans = await GetImplementationTimeSpans()
      setTimeSpans(implementationTimeSpans)
    }

    void fetchData()
  }, [])

  const goToPage = (timeSpan: ImplementationTimeSpan): void => {
    const route = generatePath(AppRoutes.IMPLEMENTATION_INSTANCE, { timeSpanId: timeSpan.id.toString() })
    nav(route)
  }

  return <>
    <Typography variant='h3' className='page-header' data-testid='pageHeader'>Implementation</Typography>

    <Grid container spacing={2} sx={{ mt: '2em' }}>
      {timeSpans.map(timeSpan => (
        <Grid item key={timeSpan.id}>
          <SummaryCard
            superTitle='Implementation Plan'
            title={`${timeSpan.startYear}/${timeSpan.startYear + 1}`}
            content='CETA'
            footerContent={
              <>
                {timeSpan.lastUpdated != null &&
                  <Typography>{FormatDateAsTimeAgo(timeSpan.lastUpdated)}</Typography>
                }
              </>
            }
            dataTestId={`${timeSpan.startYear}-card`}
            onClick={() => { goToPage(timeSpan) }}
          />
        </Grid>
      ))}
    </Grid>
  </>
}
