import { Box, Card, Typography } from '@mui/material'

interface Props {
  superTitle?: string
  title: string
  icon?: JSX.Element
  width?: string
  height?: string
  content: string | JSX.Element
  footerContent?: string | JSX.Element
  dataTestId?: string
  onClick?: () => void
}

export const SummaryCard = (props: Props): JSX.Element => {
  const clickableStyle = props.onClick != null ? { cursor: 'pointer', ':hover': { backgroundColor: '#E9E9E9' } } : {}

  return <Card
    onClick={props.onClick}
    data-testid={props.dataTestId}
    sx={{ ...clickableStyle, p: '1em', width: props.width ?? '15em', height: props.height ?? '15em', borderRadius: '1em', position: 'relative' }}
  >
    {props.superTitle != null && <Typography>{props.superTitle}</Typography>}
    <Typography sx={{ fontWeight: 600, fontSize: '1.5em' }}>{props.title}</Typography>

    <Box sx={{ display: 'flex', mt: '1em' }}>
      {props.icon}
      <Box sx={{ ml: props.icon != null ? '1em' : '' }}>
        {typeof props.content === 'string' ? <Typography>{props.content}</Typography> : props.content}
      </Box>
    </Box>

    {props.footerContent != null &&
      <Box sx={{ position: 'absolute', bottom: 0, p: '.5em', color: '#707385' }} data-testid='footerContent'>
        {typeof props.footerContent === 'string' ? <Typography>{props.footerContent}</Typography> : props.footerContent}
      </Box>
    }
  </Card>
}
