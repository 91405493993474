import type { CETAPlanSectionModel } from '../../../../models/CETAPlanSectionModel'
import { CETAGoalDetails } from './CETAGoalDetails'
import { Box, Breadcrumbs, Card, Link, Typography } from '@mui/material'
import { RatingRadio } from '../../../customComponents/RatingRadio'
import { useEffect, useState } from 'react'
import generatePDF, { Margin } from 'react-to-pdf'
import { GetCETAPlanSections } from '../../../../services/CETAClassroomService'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { BlueButton } from '../../../customComponents/buttons/BlueButton'
import DownloadIcon from '@mui/icons-material/Download'
import { AppRoutes } from '../../../Routes'
import { TranslateSectionDates } from '../../../../utils/cetaUtils'

export const CETAPlanSectionPrint = (): JSX.Element => {
  const [sections, setSections] = useState<CETAPlanSectionModel[]>([])
  const { classroomId, tab, sectionIds } = useParams()
  const nav = useNavigate()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const sectionVariable = await GetCETAPlanSections(sectionIds?.split(',') ?? [])
      sectionVariable.forEach(s => {
        s = TranslateSectionDates(s)
      })
      setSections(sectionVariable)
    }

    void fetchData()
  }, [])

  const downloadSections = async (): Promise<void> => {
    const getTargetElement = (): HTMLElement | null => document.getElementById('pdf-container')
    await generatePDF(getTargetElement, { method: 'open', page: { margin: Margin.MEDIUM, format: 'letter', orientation: 'portrait' } })
  }

  const goBack = (): void => {
    if (classroomId !== undefined) {
      const backRoute = generatePath(AppRoutes.CETA_OVERVIEW, { classroomId, tab: tab ?? '' })
      nav(backRoute)
    } else {
      nav(-1)
    }
  }

  return <>
    <Box>
      <Breadcrumbs maxItems={2} aria-label="breadcrumb" sx={{ margin: '1.25em' }}>
        <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={goBack} data-testid='backToPlan'>
          <ArrowBackIcon sx={{ verticalAlign: 'middle', mr: '5px' }} />
          Back to Sections
        </Link>
      </Breadcrumbs>
    </Box>

    <Typography variant='h3' className='page-header'>Section Action Plans</Typography>

    <Box sx={{ width: '75%', display: 'flex', marginLeft: '1em', marginBottom: '1em', alignContent: 'center' }}>
      <Typography sx={{ width: '75%', fontWeight: '500' }} variant='h5'>Selected Sections</Typography>

      <BlueButton onClick={downloadSections} sx={{ marginLeft: '1em', justifyContent: 'space-between' }} dataTestId='downloadGoals'>
        Download Action Plans
        <DownloadIcon />
      </BlueButton>
    </Box>

    <div id='pdf-container'>
      {sections?.map((sec, sectionIndex) => {
        return <Card data-testid={`${sec.name}`} key={`${sec.name}${sectionIndex}paper`}>
          <Box sx={{ margin: '1em' }}>
            <Typography variant='h4' sx={{ my: '.5em' }}>{sec.name}</Typography>

            <CETAGoalDetails goal={sec.goal} includeProgressUpdates={true} />

            {sec.indicators.map((indicator) => {
              return indicator.goal != null && <Box key={`indHeading${indicator.name}`}>
                <Typography variant='h5' sx={{ my: '.5em' }}>{indicator.name}</Typography>

                <Box display='flex' alignItems='center' mb='1em' data-testid='indicatorGoalHeader'>
                  <Typography fontSize='1.25em' mr='1em'>Indicator Goal</Typography>

                  <RatingRadio
                    readOnly={true}
                    initialValue={indicator.goal.targetMeasure}
                    checkedBackgroundColor='#50AE1F'
                    checkedBorderColor='#2D6510'
                  />
                </Box>

                <CETAGoalDetails goal={indicator.goal} includeProgressUpdates={true} />
              </Box>
            })}
          </Box>
        </Card>
      })}
    </div>
  </>
}
