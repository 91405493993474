import { Apps, Check, Close, KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material'
import { Box, Button, Menu, MenuItem, TextField, Typography } from '@mui/material'
import { type ChangeEvent, useEffect, useState } from 'react'
import { type OrganizationOption } from '../../../models/OrganizationOption'
import { GetOrganizationsForUser } from '../../../services/OrganizationService'
import { ProfileImage } from '../../customComponents/user/ProfileImage'
import { useAuth } from '../../../hooks/use-auth'

interface Props {
  isMobileView: boolean
}

export const OrganizationSwitcher = (props: Props): JSX.Element => {
  const { user, setCurrentOrg } = useAuth()

  const [selectedOrg, setSelectedOrg] = useState<OrganizationOption | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [orgOptions, setOrgOptions] = useState<OrganizationOption[]>([])
  const [filteredOrgs, setFilteredOrgs] = useState<OrganizationOption[]>([])
  const [isShowingMore, setIsShowingMore] = useState<boolean>(false)
  const [selectedName, setSelectedName] = useState<string>('')

  const visibleOrgs = isShowingMore ? filteredOrgs : filteredOrgs.slice(0, 6)

  useEffect(() => {
    if (user?.currentOrgId != null) {
      const selected = orgOptions.find((o) => o.id === user.currentOrgId)
      if (selected != null) {
        setSelectedOrg(selected)
        // put selected org at the top of the list
        setFilteredOrgs([selected, ...orgOptions.filter((o) => o.id !== user.currentOrgId)])
      }
    }
  }, [user?.currentOrgId, orgOptions])

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const orgs = await GetOrganizationsForUser()
      setOrgOptions(orgs)
      setFilteredOrgs(orgs)
    }

    void loadData()
  }, [])

  const onNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSelectedName(e.target.value)
    setFilteredOrgs(orgOptions.filter((o) => o.name.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const handleOrgSelected = async (org: OrganizationOption): Promise<void> => {
    if (selectedOrg?.id === org.id) {
      return
    }

    setSelectedOrg(org)
    setAnchorEl(null)
    await setCurrentOrg(org.id)
  }

  return <>
    <Button
      onClick={(e) => { setAnchorEl(e.currentTarget) }}
      data-testid='orgSwitcher'
      sx={{
        display: props.isMobileView ? { xs: 'flex', md: 'none' } : { xs: 'none', md: 'flex' },
        width: props.isMobileView ? '120px' : '248px',
        border: '1px solid #BBBBBB',
        borderRadius: '8px',
        justifyContent: 'space-between',
        mr: '2em'
      }}
    >
      <Apps sx={{ fill: '#5F6368', width: '33px', height: '33px', mr: '.25em' }} />

      {selectedOrg != null &&
        <>
          <ProfileImage name={selectedOrg.name} imageKey={selectedOrg.imageKey} />

          {!props.isMobileView &&
            <Typography
              ml='.25em'
              color='#3B353E'
              fontWeight='600'
              textTransform='none'
              textOverflow='ellipsis'
              whiteSpace='nowrap'
              overflow='hidden'
            >
              {selectedOrg.name}
            </Typography>
          }
        </>
      }

      {anchorEl != null &&
        <KeyboardArrowUp sx={{ color: '#5F6368' }} />
      }

      {anchorEl == null &&
        <KeyboardArrowDown sx={{ color: '#5F6368' }} />
      }
    </Button>

    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      keepMounted={true}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={anchorEl != null}
      onClose={() => { setAnchorEl(null) }}
      disablePortal={true}
      sx={{
        display: props.isMobileView ? { xs: 'block', md: 'none' } : { xs: 'none', md: 'block' },
        mt: '1em'
      }}
    >
      <Box sx={{ width: '365px' }}>
        <Box sx={{ p: '1em', display: 'flex', justifyContent: 'space-between' }}>
          <Typography fontSize='1.1875em'>Your Organizations</Typography>
          <Close sx={{ cursor: 'pointer' }} onClick={() => { setAnchorEl(null) }} />
        </Box>

        <Box sx={{ position: 'relative', mb: '1em' }}>
          <TextField
            name='orgSearch'
            data-testid='orgSearch'
            placeholder='Find organization...'
            onChange={onNameChange}
            onKeyDown={(e) => { e.stopPropagation() }}
            value={selectedName}
            inputProps={{ sx: { pl: '2.25em' } }}
            sx={{ width: '-webkit-fill-available', mx: '1em' }}
          />
          <Search sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '1em' }} />
        </Box>

        {visibleOrgs.map((org) => {
          const isSelected = selectedOrg?.id === org.id

          return <MenuItem
            key={org.id}
            disableRipple={isSelected}
            onClick={async () => { await handleOrgSelected(org) }}
            data-testid='orgMenuItem'
            sx={{
              backgroundColor: isSelected ? '#D9D9D9 !important' : 'white',
              cursor: isSelected ? 'default' : 'pointer',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ProfileImage name={org.name} imageKey={org.imageKey} />

              <Typography
                fontWeight={isSelected ? '600' : '500'}
                ml='1em'
                maxWidth='250px'
                textOverflow='ellipsis'
                overflow='hidden'
              >
                {org.name}
              </Typography>
            </Box>

            {isSelected && <Check />}
          </MenuItem>
        })}

        {filteredOrgs.length > 6 && !isShowingMore &&
          <Typography sx={{ cursor: 'pointer', my: '1em', ml: '2em' }} onClick={() => { setIsShowingMore(true) }} data-testid='showMore'>
            + Show more
          </Typography>
        }
        {filteredOrgs.length > 6 && isShowingMore &&
          <Typography sx={{ cursor: 'pointer', my: '1em', ml: '2em' }} onClick={() => { setIsShowingMore(false) }} data-testid='showLess'>
            - Show less
          </Typography>
        }
      </Box>
    </Menu>
  </>
}
