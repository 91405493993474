import { EditOutlined, EmailOutlined, LocalPhoneOutlined, LocationOnOutlined, PersonOutlined } from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, InputLabel, Typography } from '@mui/material'
import { IconWithLabel } from '../IconWithLabel'
import { useState } from 'react'
import { Modal } from '@oaisd/michdev.components.react'
import { UpdateUser } from '../../../services/UserManagementService'
import { toast } from 'react-toastify'
import { type UserInfoFormValues, UserDetailsEdit } from './DetailsEdit'
import type { User } from '../../../models/User'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  user: User
  allowEdit: boolean
  allowDelete?: boolean
  updateUser?: (user: User) => void
  deleteUser?: () => void
}

export const UserDetailsView = (props: Props): JSX.Element => {
  const [editMode, setEditMode] = useState<boolean>(false)

  const handleSave = async (form: UserInfoFormValues): Promise<void> => {
    const { success, errors } = await UpdateUser(form)
    const updatedUser = { ...props.user }
    updatedUser.phoneNumber = form.phoneNumber
    updatedUser.email = form.email
    updatedUser.firstName = form.firstName
    updatedUser.lastName = form.lastName
    updatedUser.title = form.title
    updatedUser.address = form.address

    if (props.updateUser != null) {
      props.updateUser(updatedUser)
    }

    if (!success) {
      errors.forEach((error) => {
        toast.error(error)
      })
      return
    }
    toast.success('User saved successfully')
    setEditMode(false)
  }

  const handleDelete = (): void => {
    if (props.deleteUser != null) {
      props.deleteUser()
    }
  }

  const handleEditClose = (): void => {
    setEditMode(false)
  }

  return <>
    <Accordion sx={{ width: '100%' }} disableGutters>
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon data-testid='expand' />} >
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
            <PersonOutlined />
            <Box>
              <InputLabel data-testid='fullName' sx={{ marginLeft: '.625em', overflowWrap: 'break-word', whiteSpace: 'break-spaces', color: 'black', fontWeight: '500' }}>{`${props.user.firstName} ${props.user.lastName}`}</InputLabel>
              <InputLabel data-testid='title' sx={{ marginLeft: '.625em', overflowWrap: 'break-word', whiteSpace: 'break-spaces', color: 'black', fontSize: '.875em' }}>{props.user.title}</InputLabel>
            </Box>
          </Box>
        </Grid>
      </AccordionSummary>

      <AccordionDetails sx={{ marginTop: '0em' }}>
        <Grid item xs={12} sx={{ marginTop: '0em', marginBottom: '.75em' }}>
          <IconWithLabel icon={<EmailOutlined />} labelText={props.user.email} dataTestId='email' />
        </Grid>

        {props.user.phoneNumber != null && props.user.phoneNumber !== '' && <Grid item xs={12} sx={{ marginTop: '.75em', marginBottom: '.75em' }}>
          <IconWithLabel icon={<LocalPhoneOutlined />} labelText={props.user.phoneNumber} dataTestId='primaryPhoneNumber' />
        </Grid>}

        {props.user.address != null && <Grid item xs={12} sx={{ marginTop: '.75em', marginBottom: '0em' }}>
          <IconWithLabel icon={<LocationOnOutlined />} labelText={props.user.address.street} dataTestId='street' />
          {props.user.address.apartment != null && <Typography sx={{ ml: '2.125em' }} data-testid='apartment'>{props.user.address.apartment}</Typography>}
          <Typography sx={{ ml: '2.125em' }} data-testid='cityAndState'>{`${props.user.address.city}, ${props.user.address.state}`}</Typography>
          <Typography sx={{ ml: '2.125em' }} data-testid='zip'>{props.user.address.zip}</Typography>
        </Grid>}

        <Grid item xs={12} sx={{ marginTop: '0em', display: 'grid', marginBottom: '0em', justifyContent: 'right' }}>
          {props.allowEdit &&
            <IconButton aria-label="settings" data-testid='editUserInfo' onClick={() => { setEditMode(true) }}>
              <EditOutlined sx={{ fill: 'black' }} />
            </IconButton>
          }

          {props.allowDelete === true &&
            <IconButton aria-label="delete" data-testid='deleteUserInfo' onClick={handleDelete} sx={{ float: 'right' }}>
              <CloseIcon fontSize='large' sx={{ fill: 'black' }} />
            </IconButton>
          }
        </Grid>
      </AccordionDetails>
    </Accordion>

    {editMode && <Modal
      title='Edit User Information'
      open={true}
      maxWidth='sm'
      noButtons={true}
      buttonClassName='modal-button'
      onClose={handleEditClose}
      onConfirm={handleEditClose}
      confirmationContent={
        <UserDetailsEdit contact={props.user} leaveEditMode={() => { setEditMode(false) }} handleSave={handleSave} />
      }
    />}
  </>
}
