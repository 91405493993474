export class Colors {
  static readonly ACTIVE_BACKGROUND = '#AFEA90'
  static readonly ACTIVE_TEXT = '#2D6510'
  static readonly ACTIVE_BORDER = '#82DA53'
  static readonly INACTIVE_BACKGROUND = '#FFB6D1'
  static readonly INACTIVE_TEXT = '#7C0732'
  static readonly INACTIVE_BORDER = '#DB7399'
  static readonly PENDING_BACKGROUND = '#88CAEA'
  static readonly PENDING_TEXT = '#004A70'
  static readonly PENDING_BORDER = '#21AEDE'
}
