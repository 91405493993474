import { Modal } from '../../customComponents/Modal'
import { Box, CircularProgress, IconButton, MenuItem, Select, type SelectChangeEvent, Typography } from '@mui/material'
import { IconWithLabel } from '../../customComponents/IconWithLabel'
import { EditOutlined, FiberManualRecord, Fingerprint, LocalPhoneOutlined, LocationOnOutlined } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { type BuildingEditModel } from '../../../models/BuildingEditModel'
import { GetBuildingDetails, UpdateBuildingStatus, UploadBuildingLogo } from '../../../services/OrganizationService'
import { ProfileImage } from '../../customComponents/user/ProfileImage'
import { ActiveStatus } from '../../../utils/genericTypes'
import { Colors } from '../../../utils/colors'
import { type SelectOption } from '@oaisd/michdev.components.react'
import { toast } from 'react-toastify'

const statusOptions: Array<SelectOption<string>> = Object.values(ActiveStatus).map((key) => ({ id: key, name: key }))

interface Props {
  buildingId: number
  onClose: () => void
  onSubmit: () => void
}

export const EditBuildingModal = (props: Props): JSX.Element => {
  const [building, setBuilding] = useState<BuildingEditModel | null>(null)

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const details = await GetBuildingDetails(props.buildingId.toString())
      setBuilding(details)
    }

    void loadData()
  }, [props.buildingId])

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.item(0)
    if (file == null || building == null) return

    const s3key = await UploadBuildingLogo(props.buildingId.toString(), file)
    const updatedBuilding = { ...building, imageKey: s3key }
    setBuilding(updatedBuilding)

    props.onSubmit() // Refresh the building logo in the list, even if we don't click submit
  }

  const handleSubmit = async (): Promise<void> => {
    if (building == null) return

    await UpdateBuildingStatus(building.id.toString(), building.status)
    toast.success('Building status updated')

    props.onSubmit()
    props.onClose()
  }

  const handleStatusChange = (e: SelectChangeEvent): void => {
    if (building == null) return

    const newStatus = e.target.value as ActiveStatus
    const updatedBuilding = { ...building, status: newStatus }
    setBuilding(updatedBuilding)
  }

  let statusColor = '#726F74'
  if (building?.status === ActiveStatus.Active) {
    statusColor = Colors.ACTIVE_TEXT
  }
  if (building?.status === ActiveStatus.Inactive) {
    statusColor = Colors.INACTIVE_TEXT
  }

  return <Modal
    title="Edit Building"
    open={true}
    confirmButtonText='Save Status'
    onClose={props.onClose}
    onConfirm={handleSubmit}
    bodyContent={
      building == null
        ? <CircularProgress />
        : <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mx: '.625em' }}>
            <Box sx={{ position: 'relative' }}>
              <ProfileImage name={building.name} imageKey={building.imageKey} height='5.5em' width='5.5em' />

              <IconButton
                component='label'
                aria-label='Change Logo Image'
                role=''
                sx={{ position: 'absolute', right: 0, bottom: 0, backgroundColor: '#D9D9D9', ':hover': { backgroundColor: '#A7A5A5 !important' } }}
              >
                <EditOutlined sx={{ fill: 'black' }} />
                <input hidden type="file" name='file' onChange={handleFileSelect} />
              </IconButton>
            </Box>
          </Box>

          <Select
            value={building.status}
            sx={{ fontSize: '.875em', height: '1.75em', mt: '1em' }}
            inputProps={{ 'aria-label': 'Building Status' }}
            data-testid='buildingStatusSelect'
            onChange={handleStatusChange}
            renderValue={(value) =>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FiberManualRecord sx={{ color: statusColor, fontSize: '1em', mr: '5px' }} />
                {value}
              </Box>
            }
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.id} value={option.id} data-testid={`statusMenuItem-${option.name}`}>{option.name}</MenuItem>
            ))}
          </Select>

          <Typography sx={{ fontWeight: '600', mt: '1em' }}>{building?.name}</Typography>
          <IconWithLabel icon={<Fingerprint />} sx={{ mt: '.5em' }} labelText={building.eemCode} dataTestId='eemCode' />

          {building.phone != null &&
            <IconWithLabel icon={<LocalPhoneOutlined />} sx={{ mt: '.5em' }} labelText={building.phone} dataTestId='phone' />
          }

          {building.address != null &&
            <>
              <IconWithLabel
                icon={<LocationOnOutlined />}
                sx={{ mt: '.5em' }}
                whiteSpace='normal'
                labelText={building.address.street}
                dataTestId='address'
              />

              <Typography
                sx={{ ml: '2.125em', mt: '.5em' }}
                data-testid='cityAndState'
              >
                {`${building.address.city}, ${building.address.state}`}
              </Typography>

              <Typography sx={{ ml: '2.125em', mt: '.5em' }} data-testid='zip'>{building.address.zip}</Typography>
            </>
          }
        </>
    }
  />
}
