import { Box, Typography } from '@mui/material'
import { EventDisplay } from '../../../customComponents/EventDisplay'
import { ProfileImage } from '../../../customComponents/user/ProfileImage'
import { type CETAGoal } from '../../../../models/CETAPlanSectionModel'
import { CETAGoalActionDetails } from './CETAGoalActionDetails'
import { BlueButton } from '../../../customComponents/buttons/BlueButton'
import { useState } from 'react'
import { ProgressNoteModal } from '../ProgressUpdates/ProgessNoteModal'
import { AddCircle, Check, Close } from '@mui/icons-material'
import { Modal } from '../../../customComponents/Modal'
import { MarkGoalIncomplete } from '../../../../services/CETAClassroomService'
import { GoalCompletionModal } from '../ProgressUpdates/GoalCompletionModal'
import { PurpleButton } from '../../../customComponents/buttons/PurpleButton'
import { toast } from 'react-toastify'
import { CETAProgressNoteDetails } from './CETAProgressNoteDetails'

interface Props {
  goal: CETAGoal | undefined
  includeProgressUpdates?: boolean
  readOnly?: boolean
  onSave?: () => void
}

export const CETAGoalDetails = (props: Props): JSX.Element => {
  const [isAddingProgressNote, setIsAddingProgressNote] = useState(false)
  const [isMarkingGoalComplete, setIsMarkingGoalComplete] = useState(false)
  const [isMarkingGoalIncomplete, setIsMarkingGoalIncomplete] = useState(false)
  const [isGoalComplete, setIsGoalComplete] = useState(props.goal?.isComplete ?? false)

  const startAddingProgressNote = (): void => {
    setIsAddingProgressNote(true)
  }

  const markGoalComplete = (): void => {
    setIsGoalComplete(true)
  }

  const markGoalIncomplete = async (): Promise<void> => {
    await MarkGoalIncomplete(props.goal?.id ?? 0)
    toast.success('Goal update saved')
    setIsMarkingGoalIncomplete(false)
    setIsGoalComplete(false)
  }

  return <>
    {props.goal != null &&
      <Box data-testid='goalDetails'>
        <Box display='flex' alignItems='center'>
          <EventDisplay event={{ title: 'Target Date', date: props.goal.targetGoalDate }} />
          <ProfileImage name={props.goal.owner.name} imageKey={props.goal.owner.imageKey} sx={{ ml: '1em' }} />
          <Typography ml='.5em'>{props.goal.owner.name}</Typography>
        </Box>

        <Typography mt='1em'>{props.goal.summary}</Typography>

        <Typography mt='1em' mb='.5em' fontWeight='600'>Actions</Typography>

        {props.goal.actions.map((action, index) =>
          <CETAGoalActionDetails
            key={index}
            readOnly={props.readOnly ?? false}
            action={action}
            showStatus={true}
            onUpdateStatus={props.onSave}
          />
        )}

        {props.goal.actions.length === 0 && <Typography sx={{ mb: '.5em' }}>No actions have been added</Typography>}

        {props.includeProgressUpdates === true &&
          <>
            {props.goal.notes.length > 0 &&
              <Typography mt='1em' mb='.5em' fontWeight='600'>Progress Updates</Typography>
            }

            {props.goal.notes.map((note, index) => (
              <CETAProgressNoteDetails
                key={index}
                note={note}
                readOnly={props.readOnly}
                goal={props.goal}
                onSave={props.onSave}
              />
            ))}

            {props.readOnly === false &&
              <>
                <Box>
                  <BlueButton onClick={startAddingProgressNote} dataTestId='addProgressNote'>
                    Add Progress Note
                    <AddCircle sx={{ ml: '.5em' }} />
                  </BlueButton>
                </Box>

                {!isGoalComplete &&
                  <PurpleButton onClick={() => { setIsMarkingGoalComplete(true) }} sx={{ mt: '2em' }} dataTestId='markGoalComplete'>
                    Mark Goal Complete
                    <Check sx={{ ml: '.5em' }} />
                  </PurpleButton>
                }

                {isGoalComplete &&
                  <PurpleButton onClick={() => { setIsMarkingGoalIncomplete(true) }} sx={{ mt: '2em' }} dataTestId='markGoalIncomplete'>
                    Mark Goal Incomplete
                    <Close sx={{ ml: '.5em' }} />
                  </PurpleButton>
                }
              </>
            }

            {isAddingProgressNote &&
              <ProgressNoteModal
                note={null}
                goalId={props.goal.id ?? 0}
                onClose={() => { setIsAddingProgressNote(false) }}
                onSave={props.onSave}
              />
            }

            {isMarkingGoalComplete &&
              <GoalCompletionModal
                goalId={props.goal.id ?? 0}
                onClose={() => { setIsMarkingGoalComplete(false) }}
                onSave={markGoalComplete}
              />
            }

            {isMarkingGoalIncomplete &&
              <Modal
                open={true}
                title='Mark Goal Incomplete'
                confirmButtonText='Confirm'
                onClose={() => { setIsMarkingGoalIncomplete(false) }}
                onConfirm={markGoalIncomplete}
                bodyContent='This goal was previously marked as complete. Are you sure you want to mark it as incomplete?'
              />
            }
          </>
        }
      </Box>
    }
  </>
}
