import { type AuthUser } from '../hooks/use-auth'

export class AuthFunctions {
  static readonly IMPERSONATION = 'Impersonation'
  static readonly IMPERSONATION_EDIT = 'Impersonation - Edit'
  static readonly PROJECT_SETTINGS = 'Project Settings'
  static readonly PROJECT_SETTINGS_EDIT = 'Project Settings - Edit'
  static readonly RCN_MANAGEMENT = 'RCN Management'
  static readonly RCN_MANAGEMENT_EDIT = 'RCN Management - Edit'
  static readonly RCN_ADMINISTRATION = 'RCN Administration'
  static readonly RCN_ADMINISTRATION_EDIT = 'RCN Administration - Edit'
  static readonly ISD_MANAGEMENT = 'ISD Management'
  static readonly ISD_MANAGEMENT_EDIT = 'ISD Management - Edit'
  static readonly ISD_ADMINISTRATION = 'ISD Administration'
  static readonly ISD_ADMINISTRATION_EDIT = 'ISD Administration - Edit'
  static readonly DISTRICT_MANAGEMENT = 'District Management'
  static readonly DISTRICT_MANAGEMENT_EDIT = 'District Management - Edit'
  static readonly DISTRICT_ADMINISTRATION = 'District Administration'
  static readonly DISTRICT_ADMINISTRATION_EDIT = 'District Administration - Edit'
  static readonly CETA_PARTICIPATION = 'CETA Participation'
  static readonly CETA_PARTICIPATION_EDIT = 'CETA Participation - Edit'
  static readonly CETA_TEACHER = 'CETA Teacher'
  static readonly CETA_TEACHER_EDIT = 'CETA Teacher - Edit'
  static readonly CETA_COACH = 'CETA Coach'
  static readonly CETA_COACH_EDIT = 'CETA Coach - Edit'
  static readonly ANNUAL_PLANNING_ADMINISTRATION = 'Annual Planning Administration'
  static readonly ANNUAL_PLANNING_ADMINISTRATION_EDIT = 'Annual Planning Administration - Edit'
  static readonly ANNUAL_PLANNING_CONTRIBUTION = 'Annual Planning Contribution'
  static readonly ANNUAL_PLANNING_CONTRIBUTION_EDIT = 'Annual Planning Contribution - Edit'
  static readonly ANNUAL_PLANNING_REVIEW = 'Annual Planning Review'
  static readonly ANNUAL_PLANNING_REVIEW_EDIT = 'Annual Planning Review - Edit'
  static readonly FISCAL_AGENCY_REVIEW = 'Fiscal Agency Review'
  static readonly FISCAL_AGENCY_REVIEW_EDIT = 'Fiscal Agency Review - Edit'
  // static readonly CONTRACT_COLLABORATION = 'Contract Collaboration'
  // static readonly CONTRACT_COLLABORATION_EDIT = 'Contract Collaboration - Edit'
  // static readonly CONTRACT_ADMINISTRATION = 'Contract Administration'
  // static readonly CONTRACT_ADMINISTRATION_EDIT = 'Contract Administration - Edit'
  // static readonly REPORTS = 'Reports'
  // static readonly REPORTS_EDIT = 'Reports - Edit'
  // static readonly BUILDING_ACCESS = 'Building Access'
  // static readonly BUILDING_ACCESS_EDIT = 'Building Access - Edit'
  // static readonly FOCUS_STUDENT_MANAGEMENT = 'Focus Student Management'
  // static readonly FOCUS_STUDENT_MANAGEMENT_EDIT = 'Focus Student Management - Edit'
  // static readonly FOCUS_STUDENT_IMPLEMENTATION = 'Focus Student Implementation'
  // static readonly FOCUS_STUDENT_IMPLEMENTATION_EDIT = 'Focus Student Implementation - Edit'
}

export const hasPermission = (user: AuthUser, permission: string): boolean => {
  return user.userFunctions.includes(permission)
}

export const descriptionForPermission = (permission: string): string => {
  switch (permission) {
    case AuthFunctions.IMPERSONATION:
      return 'Allows the user to act as another user in the system'
    case AuthFunctions.PROJECT_SETTINGS:
      return 'Allows the user to manage system-wide settings'
    case AuthFunctions.RCN_MANAGEMENT:
      return 'Allows the user to manage the access of all RCNs in the system'
    case AuthFunctions.RCN_ADMINISTRATION:
      return 'Allows the user to manage their RCN'
    case AuthFunctions.ISD_MANAGEMENT:
      return 'Allows the user to manage the access of all ISDs in their RCN'
    case AuthFunctions.ISD_ADMINISTRATION:
      return 'Allows the user to manage their ISD'
    case AuthFunctions.DISTRICT_MANAGEMENT:
      return 'Allows the user to manage the access of all districts in their ISD'
    case AuthFunctions.DISTRICT_ADMINISTRATION:
      return 'Allows the user to manage their district'
    case AuthFunctions.CETA_PARTICIPATION:
      return 'Allows the user to be a member of a team that collaborates on assessments for a CETA classroom'
    case AuthFunctions.CETA_TEACHER:
      return 'Allows the user to be the teacher of a CETA classroom'
    case AuthFunctions.CETA_COACH:
      return 'Allows the user to be the coach of a CETA classroom'
    case AuthFunctions.ANNUAL_PLANNING_ADMINISTRATION:
      return 'Allows the user to manage annual planning'
    case AuthFunctions.ANNUAL_PLANNING_CONTRIBUTION:
      return 'Allows the user to contribute to the annual plan'
    case AuthFunctions.ANNUAL_PLANNING_REVIEW:
      return 'Allows the user to view the annual plan'
    case AuthFunctions.FISCAL_AGENCY_REVIEW:
      return 'Allows the user to view the fiscal agency'
    default:
      return ''
    // case AuthFunctions.CONTRACT_COLLABORATION:
    //   return ''
    // case AuthFunctions.CONTRACT_ADMINISTRATION:
    //   return ''
    // case AuthFunctions.REPORTS:
    //   return ''
    // case AuthFunctions.BUILDING_ACCESS:
    //   return ''
    // case AuthFunctions.FOCUS_STUDENT_MANAGEMENT:
    //   return ''
    // case AuthFunctions.FOCUS_STUDENT_IMPLEMENTATION:
    //   return ''
  }
}
