import { Box } from '@mui/material'
import { type UserSearchValues, UserSearch } from '../../customComponents/user/Search'
import { UserList } from '../../customComponents/user/List'
import { useContext, useEffect, useState } from 'react'
import { GetBuildingOptionsForOrg, GetOrganizationOptionsForUser, GetUsersFromSearchCriteria } from '../../../services/UserManagementService'
import { generatePath, useNavigate } from 'react-router'
import type { User } from '../../../models/User'
import { AccessStatus, OrganizationType, type SelectableOption } from '../../../utils/genericTypes'
import { type SelectOption } from '../../customComponents/SelectWithLabel'
import { AppRoutes } from '../../Routes'
import { AddUserModal } from './users/AddUserModal'
import { AccessRequestModal } from './users/AccessRequestModal'
import { BreadcrumbContext, SetBreadcrumbContext } from '../../../contextProviders/BreadcrumbProvider'
import { type OrganizationDetailsModel } from '../../../models/OrganizationDetailsModel'
import { OrgTabs } from './OrganizationDetails'

const accessStatusOptions: Array<SelectOption<string>> = [{ id: '', name: 'All' }]
Object.values(AccessStatus).forEach((key) => {
  accessStatusOptions.push({ id: key, name: key })
})

interface Props {
  primaryOrg: OrganizationDetailsModel
}

export function OrganizationUsers (props: Props): JSX.Element {
  const nav = useNavigate()
  const breadcrumbs = useContext(BreadcrumbContext)
  const setBreadcrumbs = useContext(SetBreadcrumbContext)

  const [userList, setUserList] = useState<User[]>([])
  const [organizationList, setOrganizationList] = useState<SelectableOption[]>([])
  const [buildingList, setBuildingList] = useState<SelectableOption[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const [isReviewingUser, setIsReviewingUser] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)

  const defaultSearchValues: UserSearchValues = {
    primaryOrgId: props.primaryOrg.id,
    secondaryOrgId: null,
    building: null,
    accessStatus: null,
    name: ''
  }

  const loadTableData = async (): Promise<void> => {
    setUserList(await GetUsersFromSearchCriteria(defaultSearchValues))
    setLoading(false)
  }

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      setBuildingList(await GetBuildingOptionsForOrg(props.primaryOrg.id))
      setOrganizationList(await GetOrganizationOptionsForUser())
      await loadTableData()
    }
    void loadData()
  }, [])

  const reviewUserClick = (user: User): void => {
    setSelectedUser(user)
    setIsReviewingUser(true)
  }

  const editUserClick = (user: User): void => {
    const currentRoute = generatePath(AppRoutes.ORGANIZATION_DETAILS, { id: props.primaryOrg.id.toString(), tab: OrgTabs.Users })
    setBreadcrumbs([...breadcrumbs, { name: props.primaryOrg.name, route: currentRoute }])

    const detailsRoute = generatePath(AppRoutes.USER_DETAILS, { userId: user.id.toString(), orgId: null })
    nav(detailsRoute)
  }

  const addUserClick = (): void => {
    setShowAddModal(true)
  }

  const searchValueChanged = async (searchValues: UserSearchValues): Promise<void> => {
    setUserList(await GetUsersFromSearchCriteria(searchValues))
  }

  return <Box sx={{ width: 'fit-content' }}>
    <Box sx={{ mt: '1em', mb: '1em' }}>
      <UserSearch
        primaryOrgId={props.primaryOrg.id}
        accessLabel={props.primaryOrg.type === OrganizationType.Project ? 'Status' : 'Access Status'}
        hideOrganizations={props.primaryOrg.type === OrganizationType.District}
        hideBuildings={props.primaryOrg.type !== OrganizationType.District}
        buildingOptions={buildingList}
        organizationOptions={organizationList}
        accessStatusOptions={accessStatusOptions}
        onButtonClick={addUserClick}
        filtersUpdated={searchValueChanged}
      />
    </Box>

    <UserList
      accessLabel={props.primaryOrg.type === OrganizationType.Project ? 'Status' : 'Access Status'}
      hideAccessLevel={props.primaryOrg.type === OrganizationType.Project}
      hideOrganizations={props.primaryOrg.type !== OrganizationType.Project}
      hideBuildings={props.primaryOrg.type === OrganizationType.Project || props.primaryOrg.type === OrganizationType.RCN}
      users={userList}
      loading={loading}
      editUser={editUserClick}
      reviewUser={reviewUserClick}
    />

    {showAddModal && <AddUserModal
      primaryOrg={props.primaryOrg}
      onClose={() => { setShowAddModal(false) }}
    />}

    {isReviewingUser && selectedUser != null &&
      <AccessRequestModal
        userId={selectedUser.id}
        orgId={props.primaryOrg.id}
        orgType={props.primaryOrg.type}
        closeModal={() => { setIsReviewingUser(false) }}
        onSubmit={loadTableData}
      />
    }
  </Box>
}
