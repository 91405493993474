import { Modal, type SelectOption } from '@oaisd/michdev.components.react'
import { type SyntheticEvent, useEffect, useState } from 'react'
import { type EEMOrganization } from '../../../models/EEMOrganization'
import { AddDistrict, GetAvailableDistrictsFromEEM } from '../../../services/OrganizationService'
import MUIAutoComplete from '../../customComponents/MUIAutoComplete'
import { Box, Typography } from '@mui/material'
import { IconWithLabel } from '../../customComponents/IconWithLabel'
import { Fingerprint, LocalPhoneOutlined, LocationOnOutlined } from '@mui/icons-material'

interface Props {
  parentOrgId: number
  onClose: () => void
  onSubmit: () => void
}

export const AddDistrictModal = (props: Props): JSX.Element => {
  const [availableDistricts, setAvailableDistricts] = useState<EEMOrganization[]>([])
  const [selectedDistrict, setSelectedDistrict] = useState<EEMOrganization | null>(null)
  const [selectedDistrictOption, setSelectedDistrictOption] = useState<SelectOption<string | number> | null>()

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const districts = await GetAvailableDistrictsFromEEM(props.parentOrgId.toString())
      setAvailableDistricts(districts)
    }

    void loadData()
  }, [])

  const handleSubmit = async (): Promise<void> => {
    if (selectedDistrict == null) {
      return
    }

    await AddDistrict(props.parentOrgId.toString(), selectedDistrict.id.toString())
    props.onClose()
    props.onSubmit()
  }

  const selectDistrict = (e: SyntheticEvent, value: { id: string | number, name: string } | null): void => {
    const district = availableDistricts.find(d => d.id === value?.id) ?? null

    setSelectedDistrictOption(value)
    setSelectedDistrict(district)
  }

  return <Modal
    title='Add District'
    open={true}
    cancelButtonText='Cancel'
    confirmButtonText='Add District'
    buttonClassName='modal-button'
    confirmButtonClassName='modal-confirm-button'
    isUpdateDisabled={selectedDistrict == null}
    contentSx={{ borderBottom: '1px solid #CBC8CC' }}
    onClose={props.onClose}
    onConfirm={handleSubmit}
    confirmationContent={
      <>
        <MUIAutoComplete
          label='District'
          name='district'
          options={availableDistricts}
          value={selectedDistrictOption ?? undefined}
          sx={{ mt: '1em' }}
          onChange={selectDistrict}
        />

        {selectedDistrict != null &&
          <Box sx={{ mx: '1em' }}>
            <Typography sx={{ fontWeight: '600', mt: '1em' }}>{selectedDistrict?.name} EEM Details</Typography>
            <IconWithLabel icon={<Fingerprint />} sx={{ mt: '.5em' }} labelText={selectedDistrict.eemCode} dataTestId='eemCode' />
            <IconWithLabel icon={<LocalPhoneOutlined />} sx={{ mt: '.5em' }} labelText={selectedDistrict.phone} dataTestId='phone' />

            <IconWithLabel
              icon={<LocationOnOutlined />}
              sx={{ mt: '.5em' }}
              whiteSpace='normal'
              labelText={selectedDistrict.address}
              dataTestId='address'
            />

            <Typography
              sx={{ ml: '2.125em', mt: '.5em' }}
              data-testid='cityAndState'
            >
              {`${selectedDistrict.city}, ${selectedDistrict.state}`}
            </Typography>

            <Typography sx={{ ml: '2.125em', mt: '.5em' }} data-testid='zip'>{selectedDistrict.zip}</Typography>
          </Box>
        }
      </>
    }
  />
}
