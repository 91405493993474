import { type Dispatch, createContext, useState, useEffect } from 'react'
import { type BreadcrumbInfo } from '../pages/customComponents/BreadcrumbNav'

export const BreadcrumbContext = createContext<BreadcrumbInfo[]>([])
export const SetBreadcrumbContext = createContext<Dispatch<BreadcrumbInfo[]>>(() => null)

export const BreadcrumbProvider = (props: { children: JSX.Element }): JSX.Element => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbInfo[]>([])

  useEffect(() => {
    const breadcrumbs = sessionStorage.getItem('breadcrumbs')
    if (breadcrumbs != null) {
      setBreadcrumbs(JSON.parse(breadcrumbs))
    }
  }, [])

  const updateBreadcrumbs = (newBreadcrumbs: BreadcrumbInfo[]): void => {
    setBreadcrumbs(newBreadcrumbs)
    sessionStorage.setItem('breadcrumbs', JSON.stringify(newBreadcrumbs))
  }

  return <BreadcrumbContext.Provider value={breadcrumbs}>
    <SetBreadcrumbContext.Provider value={updateBreadcrumbs}>
      {props.children}
    </SetBreadcrumbContext.Provider>
  </BreadcrumbContext.Provider>
}
