import { type SxProps, Table, TableContainer, TablePagination } from '@mui/material'
import * as React from 'react'
import { ColumnSort, SortOrder, type Column, type RowData, DEFAULT_CHECKBOX_ID_COLUMN_NAME } from './DataTable.model'
import DataTableBody from './DataTableBody'
import DataTableHead from './DataTableHead'
import { useEffect } from 'react'

interface DataTableProps {
  columns: Column[]
  rows: RowData[]
  name: string
  sx?: SxProps
  loading?: boolean
  sortable?: boolean
  initialColumnSorts?: ColumnSort[]
  hasCheckbox?: boolean
  checkboxIdColumnName?: string
  initialCheckedAll?: boolean
  initialCheckedItems?: any[]
  totalRecords?: number
  page: number
  dataTestId?: string

  onSortChange?: (column: Column, columnSorts: ColumnSort[]) => void
  onHeaderCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  onRowCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, row: RowData) => void
  onRowClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: RowData) => void
  onPageChange?: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void
  onRowsPerPageChange?: (rowsPerPage: number) => void
}

export default function DataTable (props: DataTableProps): JSX.Element {
  const {
    columns, rows, loading, sortable, initialColumnSorts, hasCheckbox, checkboxIdColumnName, initialCheckedAll, initialCheckedItems,
    totalRecords, page, dataTestId,
    onSortChange, onHeaderCheckboxChange, onRowCheckboxChange, onRowClick, onPageChange, onRowsPerPageChange,
    ...rest
  } = props

  const [allChecked, setAllChecked] = React.useState<boolean>(initialCheckedAll ?? false)
  const [checkedItems, setCheckedItems] = React.useState<any[]>(initialCheckedItems ?? [])
  const [columnSorts, setColumnSorts] = React.useState<ColumnSort[]>(initialColumnSorts ?? [])
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(5)

  useEffect(() => {
    if (initialColumnSorts != null && initialColumnSorts.length > 0 && onSortChange != null) {
      const column = columns.find(c => c.key === initialColumnSorts[0].key)
      if (column != null) {
        onSortChange(column, initialColumnSorts)
      }
    }

    const storedRowsPerPage = localStorage.getItem(`${props.name}_rowsPerPage`)
    if (storedRowsPerPage != null) {
      const rowsPerPageValue = parseInt(storedRowsPerPage)
      setRowsPerPage(rowsPerPageValue)
      if (onRowsPerPageChange != null) {
        onRowsPerPageChange(rowsPerPageValue)
      }
    }
  }, [])

  useEffect(() => {
    _onPageChange(null, 0)
  }, [totalRecords])

  const _onSortHeaderClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, column: Column): void => {
    let columnSort = columnSorts.find(v => v.key === column.key)
    if (columnSort == null) {
      columnSort = new ColumnSort(column.key)
    } else {
      columnSort = { ...columnSort, order: columnSort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC }
    }
    const tmpColumnSorts = [columnSort]
    setColumnSorts(tmpColumnSorts)
    if (onSortChange != null) {
      onSortChange(column, tmpColumnSorts)
    }
  }

  const _onHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      setCheckedItems(rows.map(row => row[checkboxIdColumnName ?? DEFAULT_CHECKBOX_ID_COLUMN_NAME]))
    } else {
      setCheckedItems([])
    }
    setAllChecked(event.target.checked)
    if (onHeaderCheckboxChange != null) {
      onHeaderCheckboxChange(event, event.target.checked)
    }
  }

  const _onRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, row: RowData): void => {
    const tempCheckedItems = checkedItems.filter(v => v !== row[checkboxIdColumnName ?? DEFAULT_CHECKBOX_ID_COLUMN_NAME])
    if (event.target.checked) {
      tempCheckedItems.push(row[checkboxIdColumnName ?? DEFAULT_CHECKBOX_ID_COLUMN_NAME])
    }
    setAllChecked(tempCheckedItems.length === rows.length)
    setCheckedItems(tempCheckedItems)
    if (onRowCheckboxChange != null) {
      onRowCheckboxChange(event, event.target.checked, row)
    }
  }

  const _onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number): void => {
    if (onPageChange != null) {
      onPageChange(event, page)
    }
  }

  const _onRowsPerPageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const newRowsPerPage = parseInt(event.target.value)
    setRowsPerPage(newRowsPerPage)

    localStorage.setItem(`${props.name}_rowsPerPage`, newRowsPerPage.toString())

    if (onRowsPerPageChange != null) {
      onRowsPerPageChange(newRowsPerPage)
    }
  }

  return <TableContainer>
    <Table size='medium' {...rest} data-testid={dataTestId ?? props.name}>
      <DataTableHead
        columns={columns}
        loading={loading}
        sortable={sortable}
        columnSorts={columnSorts}
        hasCheckbox={hasCheckbox}
        allChecked={allChecked}
        onHeaderCheckboxChange={_onHeaderCheckboxChange}
        onSortHeaderClick={_onSortHeaderClick}
      />
      <DataTableBody
        columns={columns}
        rows={rows}
        loading={loading}
        hasCheckbox={hasCheckbox}
        checkboxIdColumnName={checkboxIdColumnName}
        checkedItems={checkedItems}
        onRowCheckboxChange={_onRowCheckboxChange}
        onRowClick={onRowClick}
      />
    </Table>

    {loading !== true &&
      <TablePagination
        component='div'
        count={totalRecords ?? 0}
        rowsPerPageOptions={[5, 10, 25, 50]}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={_onPageChange}
        onRowsPerPageChange={_onRowsPerPageChange}
      />
    }
  </TableContainer>
}
