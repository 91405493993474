import { Box, InputLabel, type SxProps } from '@mui/material'

interface IconLabelProps {
  icon: JSX.Element
  labelText: string
  sx?: SxProps
  labelSx?: SxProps
  labelColor?: string
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line' | 'break-spaces'
  dataTestId?: string
}

export const IconWithLabel = (props: IconLabelProps): JSX.Element => {
  return (
    <Box sx={{ ...props.sx, display: 'flex', justifyContent: 'left', flexDirection: 'row', width: '100%' }}>
      {props.icon}
      <InputLabel data-testid={props.dataTestId ?? props.labelText} sx={{ ...props.labelSx, marginLeft: '.625em', overflowWrap: 'break-word', whiteSpace: props.whiteSpace ?? 'break-spaces', color: props.labelColor ?? 'black' }}>{`${props.labelText}`}</InputLabel>
    </Box>
  )
}
