import { CircularProgress, Grid } from '@mui/material'
import { NeedsAttentionCard } from './needsAttention/NeedsAttentionCard'
import { useEffect, useState } from 'react'
import { type NeedsAttentionModel } from '../../../models/NeedsAttentionModel'
import { GetNeedsAttention } from '../../../services/NeedsAttentionService'
import { AdminContactCard } from './users/AdminContactCard'
import { type User } from '../../../models/User'
import { GetAdminContacts } from '../../../services/OrganizationService'
import { type OrganizationDetailsModel } from '../../../models/OrganizationDetailsModel'

interface Props {
  primaryOrg: OrganizationDetailsModel
}

export const OrganizationOverview = (props: Props): JSX.Element => {
  const [needAttentionEntity, setNeedAttentionEntity] = useState<NeedsAttentionModel | null>(null)
  const [adminContacts, setAdminContacts] = useState<User[]>([])

  const fetchAdminContacts = async (): Promise<void> => {
    const adminContacts = await GetAdminContacts(props.primaryOrg.id.toString())
    setAdminContacts(adminContacts)
  }

  const needsAttention = async (): Promise<void> => {
    const needAttention = await GetNeedsAttention(props.primaryOrg.id.toString())
    setNeedAttentionEntity(needAttention)
  }

  const fetchData = async (): Promise<void> => {
    await Promise.all([
      fetchAdminContacts(), needsAttention()
    ])
  }

  useEffect(() => {
    void fetchData()
  }, [props.primaryOrg.id])

  if (needAttentionEntity == null) {
    return <CircularProgress />
  }

  return <Grid container spacing={2}>
    <Grid item xs={4}>
      <AdminContactCard primaryOrg={props.primaryOrg} adminContacts={adminContacts} onSave={fetchData} />
    </Grid>
    <Grid item xs={4} sx={{ minWidth: '20em' }}>
      <NeedsAttentionCard primaryOrg={props.primaryOrg} needAttentionEntity={needAttentionEntity} />
    </Grid>
  </Grid>
}
