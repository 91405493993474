import { sendGet, sendPost } from '../hooks/use-fetch'
import { type CETATemplateInstructions } from '../models/CETATemplateInstructions'
import { type CETAUpdateDates } from '../models/CETAUpdateDates'

export async function GetCETATemplateInstructions (): Promise<CETATemplateInstructions> {
  const { response } = await sendGet('CETATemplate/GetInstructions')
  return response
}

export async function SaveCETATemplateInstructions (template: CETATemplateInstructions): Promise<void> {
  await sendPost('CETATemplate/SaveInstructions', template)
}

export async function GetCETAUpdateDates (): Promise<CETAUpdateDates> {
  const { response } = await sendGet('CETATemplate/GetCETAUpdateDates')
  return response
}

export async function SaveCETAUpdateDates (dates: CETAUpdateDates): Promise<void> {
  await sendPost('CETATemplate/SaveCETAUpdateDates', dates)
}
