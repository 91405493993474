import { sendGet, sendPost } from '../hooks/use-fetch'
import type { AnnualPlanNameAndOrgName } from '../models/AnnualPlanNameAndOrgName'
import type { AnnualPlanTeamAndOptions } from '../models/AnnualPlanTeamAndOptions'
import type { AnnualPlanTeamMember } from '../models/AnnualPlanTeamMember'
import type { AnnualPlanTeamSaveRequest } from '../models/AnnualPlanTeamSaveRequest'
import type { PlanOrganizationTask } from '../models/PlanOrganizationTask'

export async function GetAnnualPlanTeam (annualPlanId: number): Promise<AnnualPlanTeamMember[]> {
  const { response } = await sendGet(`AnnualPlan/GetAnnualPlanTeam?annualPlanId=${annualPlanId}`)
  return response
}

export async function GetAnnualPlanTeamAndOptions (annualPlanId: number): Promise<AnnualPlanTeamAndOptions> {
  const { response } = await sendGet(`AnnualPlan/GetAnnualPlanTeamAndOptions?annualPlanId=${annualPlanId}`)
  return response
}

export async function SaveAnnualPlanTeam (teamRequest: AnnualPlanTeamSaveRequest): Promise<void> {
  const { response } = await sendPost('AnnualPlan/SaveAnnualPlanTeam', teamRequest)
  return response
}

export async function SendPlanOrgTaskReminder (taskId: number): Promise<void> {
  const { response } = await sendPost('AnnualPlan/SendTaskReminder', { taskId })
  return response
}

export async function GetTasksForAnnualPlan (annualPlanId: number): Promise<PlanOrganizationTask[]> {
  const { response } = await sendGet(`AnnualPlan/GetTasksForAnnualPlan?annualPlanId=${annualPlanId}`)
  return response
}

export async function GetUsersPlanRole (annualPlanId: number): Promise<string> {
  const request = await sendGet(`AnnualPlan/GetUsersPlanRole?annualPlanId=${annualPlanId}`)
  const role = request.response
  return role
}

export async function GetAnnualPlanAndOrgName (annualPlanId: string, organizationId: string): Promise<AnnualPlanNameAndOrgName> {
  const { response } = await sendGet(`AnnualPlan/GetAnnualPlanAndOrgName?annualPlanId=${annualPlanId}&&orgId=${organizationId}`)
  return response
}
