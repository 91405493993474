import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { SetBreadcrumbContext } from '../../../contextProviders/BreadcrumbProvider'
import { generatePath, useNavigate } from 'react-router'
import { AppRoutes } from '../../Routes'
import { RCNSelection } from './RCNSelection'
import { type OrganizationOption } from '../../../models/OrganizationOption'

export function Planning (): JSX.Element {
  const nav = useNavigate()
  const setBreadcrumbs = useContext(SetBreadcrumbContext)

  const handleRcnSelected = async (rcn: OrganizationOption): Promise<void> => {
    const currentRoute = generatePath(AppRoutes.PLANNING)
    setBreadcrumbs([{ name: 'RCNs', route: currentRoute }])

    const detailsRoute = generatePath(
      AppRoutes.PLANNING_INSTANCE,
      { rcnId: rcn.id.toString() }
    )
    nav(detailsRoute)
  }

  return (
    <Box data-testid='planning' sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
      }}
      >
        <Typography variant='h3' className='page-header'> Annual Planning </Typography>
      </Box>

      <RCNSelection onRCNSelect={handleRcnSelected} />
    </Box>
  )
}
