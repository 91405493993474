import { type Response, sendGet, sendPost } from '../hooks/use-fetch'
import type { CETAClassroomMemberRequest } from '../models/CETAClassroomMemberRequest'
import type { AddCETAClassroomModel } from '../models/AddCETAClassroomModel'
import type { CETAAssessment } from '../models/CETAAssessment'
import type { CETAInvitation } from '../models/CETAInvitation'
import type { CETATeamMember } from '../models/CETATeamMember'
import type { ClassroomDashboardModel } from '../models/ClassroomDashboardModel'
import type { User } from '../models/User'
import type { AddCETAClassroomDDLOptions } from '../pages/pageComponents/CETAs/AddCETAClassroomModal'
import type { CETAClassroomListModel } from '../pages/pageComponents/CETAs/CETAClassroomList'
import type { CETAClassroomSearchValues } from '../pages/pageComponents/CETAs/CETAClassroomSearch'
import type { CetaTeamMemberAndTeacherName } from '../pages/pageComponents/CETAs/CETAOverview'
import type { SelectableOption, ValidationResult } from '../utils/genericTypes'
import type { PlanOverviewSection } from '../models/PlanOverviewSection'
import type { CETAReconcileAssessment } from '../models/CETAReconcileAssessment'
import type { CETAReconcileIndicatorRequest } from '../models/CETAReconcileIndicatorRequest'
import type { CETAClassroom } from '../models/CETAClassroom'
import type { CETAPlanSectionModel } from '../models/CETAPlanSectionModel'
import type { CETAPlanGoalRequest } from '../models/CETAPlanGoalRequest'
import type { CETAGoalLock } from '../models/CETAGoalLock'
import type { CETATemplateInstructions } from '../models/CETATemplateInstructions'
import type { ProgressUpdatesOverviewSection } from '../models/ProgressUpdatesOverviewSectionCardModel'
import type { CETAProgressReportSectionModel } from '../models/CETAProgressReportModel'
import { type CETAResource } from '../models/CETAResource'

export async function DenyCETAInvitation (classroomId: number): Promise<void> {
  const { response } = await sendPost(`CETAClassroom/DenyCETAInvitation?classroomId=${classroomId}`, {})
  return response
}

export async function ApproveCETAInvitation (classroomId: number): Promise<void> {
  const { response } = await sendPost(`CETAClassroom/ApproveCETAInvitation?classroomId=${classroomId}`, {})
  return response
}

export async function GetCETAInvitation (classroomId: number): Promise<CETAInvitation> {
  const { response } = await sendPost(`CETAClassroom/GetCETAInvitation?classroomId=${classroomId}`, {})
  return response
}

export async function AddCETAClassroom (cetaClass: AddCETAClassroomModel): Promise<ValidationResult> {
  const { response } = await sendPost('CETAClassroom/AddCETAClassroom', cetaClass)
  return response
}

export async function GetCETAClassrooms (searchValues: CETAClassroomSearchValues): Promise<CETAClassroomListModel[]> {
  const { response } = await sendPost('CETAClassroom/GetCETAClassrooms', searchValues)
  return response
}

export async function GetTeacherAndCoachOptionsForCurrentOrg (): Promise<AddCETAClassroomDDLOptions> {
  const { response } = await sendGet('CETAClassroom/GetTeacherAndCoachOptionsForCurrentOrg')
  return response
}

export async function GetUserDetails (userId: string): Promise<User> {
  const request = await sendGet(`CETAClassroom/GetUserDetails?userId=${userId}`)
  const user: User = request.response
  return user
}

export async function GetCETADashboardDetails (classroomId: string): Promise<ClassroomDashboardModel> {
  const { response } = await sendGet(`CETAClassroom/GetDashboardDetails?classroomId=${classroomId}`)
  return response
}

export async function ToggleCETATask (classroomId: string, taskKey: string): Promise<void> {
  await sendPost('CETAClassroom/ToggleTask', { classroomId, taskKey })
}

export async function GetNameAndTeamMemberInfo (classroomId: string): Promise<CetaTeamMemberAndTeacherName> {
  const { response } = await sendGet(`CETAClassroom/GetNameAndTeamMemberInfo?classroomId=${classroomId}`)
  return response
}

export async function GetClassroomDetails (classroomId: string): Promise<CETAClassroom> {
  const { response } = await sendGet(`CETAClassroom/GetClassroomDetails?classroomId=${classroomId}`)
  return response
}

export async function SetUserStartedCeta (classroomId: string): Promise<void> {
  const { response } = await sendPost(`CETAClassroom/SetUserStartedCeta?classroomId=${classroomId}`, {})
  return response
}

export async function CreateSnapshot (classroomId: string): Promise<void> {
  await sendPost(`CETAClassroom/CreateSnapshot?classroomId=${classroomId}`, {})
}

export async function CancelSnapshot (snapshotId: number): Promise<void> {
  await sendPost(`CETAClassroom/CancelSnapshot?snapshotId=${snapshotId}`, {})
}

export async function FinalizeSnapshot (snapshotId: string, treatAsUpdate: boolean): Promise<void> {
  await sendPost('CETAClassroom/FinalizeSnapshot', { snapshotId, treatAsUpdate })
}

export async function StartCETAAssessment (snapshotId: number): Promise<void> {
  await sendPost(`CETAClassroom/StartCETAAssessment?snapshotId=${snapshotId}`, {})
}

export async function SendReminder (classroomId: string, userId: number): Promise<void> {
  await sendPost(`CETAClassroom/SendReminder?classroomId=${classroomId}&userId=${userId}`, {})
}

export async function SendReminders (classroomId: string): Promise<void> {
  await sendPost(`CETAClassroom/SendReminders?classroomId=${classroomId}`, {})
}

export async function GetCETATeam (classroomId: string): Promise<CETATeamMember[]> {
  const { response } = await sendGet(`CETAClassroom/GetCETATeam?classroomId=${classroomId}`)
  return response
}

export async function GetPossibleTeamMembers (classroomId: string): Promise<SelectableOption[]> {
  const { response } = await sendGet(`CETAClassroom/GetPossibleTeamMembers?classroomId=${classroomId}`)
  return response
}

export async function AddCETAClassroomMember (memberInfo: CETAClassroomMemberRequest): Promise<ValidationResult> {
  const { response } = await sendPost('CETAClassroom/AddCETAClassroomMember', memberInfo)
  return response
}

export async function GetCETAAssessment (snapshotId: string): Promise<CETAAssessment> {
  const { response } = await sendGet(`CETAClassroom/GetCETAAssessment?snapshotId=${snapshotId}`)
  return response
}

export async function GetPlanOverview (classroomId: string): Promise<PlanOverviewSection[]> {
  const { response } = await sendGet(`CETAClassroom/GetPlanOverview?classroomId=${classroomId}`)
  return response
}

export async function GetProgressUpdatesOverview (classroomId: string): Promise<ProgressUpdatesOverviewSection[]> {
  const { response } = await sendGet(`CETAClassroom/GetProgressUpdatesOverview?classroomId=${classroomId}`)
  return response
}

export async function GetTemplateInstructions (classroomId: string): Promise<CETATemplateInstructions> {
  const { response } = await sendGet(`CETAClassroom/GetTemplateInstructions?classroomId=${classroomId}`)
  return response
}

export async function SaveCETAAssessment (assessment: CETAAssessment): Promise<void> {
  await sendPost('CETAClassroom/SaveCETAAssessment', assessment)
}

export async function CompleteOutstandingAssessments (snapshotId: string): Promise<void> {
  await sendPost(`CETAClassroom/CompleteOutstandingAssessments?snapshotId=${snapshotId}`, {})
}

export async function UpdateCETAClassroomMember (memberInfo: CETAClassroomMemberRequest): Promise<ValidationResult> {
  const { response } = await sendPost('CETAClassroom/AddCETAClassroomMember', memberInfo)
  return response
}

export async function RemoveCETAClassroomMember (memberInfo: CETAClassroomMemberRequest): Promise<ValidationResult> {
  const { response } = await sendPost('CETAClassroom/RemoveCETAClassroomMember', memberInfo)
  return response
}

export async function GetParticipant (userId: number): Promise<User> {
  const request = await sendGet(`UserManagement/GetUserDetails?userId=${userId}`)
  const user: User = request.response
  return user
}

export async function GetReconcileAssessment (snapshotId: string): Promise<CETAReconcileAssessment> {
  const { response } = await sendGet(`CETAClassroom/GetReconcileAssessment?snapshotId=${snapshotId}`)
  return response
}

export async function SaveReconcileIndicator (indicator: CETAReconcileIndicatorRequest): Promise<void> {
  await sendPost('CETAClassroom/SaveReconcileIndicator', indicator)
}

export async function GetCETAPlanSection (sectionId: number): Promise<CETAPlanSectionModel> {
  const { response } = await sendGet(`CETAClassroom/GetCETAPlanSection?sectionId=${sectionId}`)
  return response
}

export async function GetCETAPlanSections (sectionIds: string[]): Promise<CETAPlanSectionModel[]> {
  const { response } = await sendPost('CETAClassroom/GetCETAPlanSections', sectionIds)
  return response
}

export async function SaveCETAPlanGoal (request: CETAPlanGoalRequest): Promise<void> {
  await sendPost('CETAClassroom/SaveCETAPlanGoal', request)
}

export async function FinalizeSectionPlan (sectionId: number): Promise<string[]> {
  const { errors } = await sendPost(`CETAClassroom/FinalizeSectionPlan?sectionId=${sectionId}`, {})
  return errors
}

export async function LockSectionGoal (sectionId: number): Promise<CETAGoalLock | undefined> {
  const { response } = await sendPost(`CETAClassroom/LockSectionGoal?sectionId=${sectionId}`, {})
  return response
}

export async function LockIndicatorGoal (indicatorId: number): Promise<CETAGoalLock | undefined> {
  const { response } = await sendPost(`CETAClassroom/LockIndicatorGoal?indicatorId=${indicatorId}`, {})
  return response
}

export async function UnlockSectionGoal (sectionId: number): Promise<void> {
  await sendPost(`CETAClassroom/UnlockSectionGoal?sectionId=${sectionId}`, {})
}

export async function UnlockIndicatorGoal (indicatorId: number): Promise<void> {
  await sendPost(`CETAClassroom/UnlockIndicatorGoal?indicatorId=${indicatorId}`, {})
}

export async function UnlockEntireSection (sectionId: number): Promise<void> {
  await sendPost(`CETAClassroom/UnlockEntireSection?sectionId=${sectionId}`, {})
}

export async function SaveProgressNote (noteId: number, goalId: number, note: string, noteDate: Date | null): Promise<Response> {
  return await sendPost('CETAClassroom/SaveProgressNote', { noteId, goalId, note, noteDate })
}

export async function DeleteProgressNote (noteId: number): Promise<void> {
  await sendPost(`CETAClassroom/DeleteProgressNote?noteId=${noteId}`, {})
}

export async function UpdateActionStatus (actionId: number, status: string): Promise<void> {
  await sendPost('CETAClassroom/UpdateActionStatus', { actionId, status })
}

export async function MarkGoalComplete (goalId: number, notes: string): Promise<Response> {
  return await sendPost('CETAClassroom/MarkGoalComplete', { goalId, notes })
}

export async function MarkGoalIncomplete (goalId: number): Promise<void> {
  await sendPost('CETAClassroom/MarkGoalIncomplete', { goalId })
}

export async function GetProgressReport (classroomId: string): Promise<CETAProgressReportSectionModel[]> {
  const { response } = await sendGet(`CETAClassroom/GetProgressReport?classroomId=${classroomId}`)
  return response
}

export async function ContinueClassroom (classroomId: string): Promise<void> {
  await sendPost(`CETAClassroom/ContinueClassroom?classroomId=${classroomId}`, {})
}

export async function ArchiveClassroom (classroomId: string): Promise<void> {
  await sendPost(`CETAClassroom/ArchiveClassroom?classroomId=${classroomId}`, {})
}

export async function GetCETAResources (): Promise<CETAResource[]> {
  const { response } = await sendGet('CETAClassroom/GetCETAResources')
  return response
}

export async function SaveCETAResource (id: number, name: string, link: string): Promise<number> {
  const { response } = await sendPost('CETAClassroom/SaveCETAResource', { id, name, link })
  return response
}

export async function DeleteCETAResource (resourceId: number): Promise<void> {
  await sendPost(`CETAClassroom/DeleteCETAResource?resourceId=${resourceId}`, {})
}
