import { Modal } from '@oaisd/michdev.components.react'
import type { CETAInvitation } from '../../../models/CETAInvitation'
import { UserDetails } from '../../customComponents/user/Details'
import { Box, Grid, Typography } from '@mui/material'
import { ApproveCETAInvitation, DenyCETAInvitation, GetCETAInvitation } from '../../../services/CETAClassroomService'
import { BlueButton } from '../../customComponents/buttons/BlueButton'
import { BlockButton } from '../../customComponents/buttons/BlockButton'
import { useEffect, useState } from 'react'
import { AccessStatus, SecurityLevel, YesNoAnswer } from '../../../utils/genericTypes'
import type { User } from '../../../models/User'
import { useAuth } from '../../../hooks/use-auth'
import { CETAClassroomStage } from '../../../models/CETAClassroomStage'

interface Props {
  cetaId: number
  onApprove: () => void
  onDeny: () => void
  onCancel: () => void
}

const initialMember: User = {
  id: 0,
  firstName: '',
  lastName: '',
  fullName: '',
  title: '',
  email: '',
  address: {
    id: 0,
    street: '',
    city: '',
    state: '',
    zip: ''
  },
  updatedAt: new Date(),
  updatedBy: '',
  accessStatus: AccessStatus.Active,
  isActive: true,
  buildings: [],
  organizations: [],
  securityLevel: SecurityLevel.DistrictUser
}

export const CETAInvitationModal = (props: Props): JSX.Element => {
  const auth = useAuth()
  const userId = auth.user?.id
  const rcnContract: string = '1. Is this CETA being completed as part of the RCN Contract Activities?'
  const purpose: string = '2. What is your purpose for completing the CETA?'
  const coachExists: string = '3. Is there a START-trained coach on this team?'
  const coachNameLabel: string = '4. Who is the coach for this classroom?'
  const [inviteInformation, setInviteInformation] = useState<CETAInvitation>({ cetaId: 0, cetaPurpose: '', hasStartCoach: YesNoAnswer.Unsure, isRCNContract: YesNoAnswer.Unsure, teacherId: 0, requestingUser: initialMember, coach: initialMember, stage: CETAClassroomStage.AwaitingApproval })
  const [showApproval, setShowApproval] = useState(false)

  const getInvite = async (): Promise<void> => {
    const invite = await GetCETAInvitation(props.cetaId)
    if (invite.teacherId === userId && invite.stage === CETAClassroomStage.AwaitingApproval) {
      setShowApproval(true)
    } else {
      setShowApproval(false)
    }
    setInviteInformation(invite)
  }

  useEffect(() => {
    if (userId != null) {
      void getInvite()
    }
  }, [userId])

  const denyCETA = async (): Promise<void> => {
    if (inviteInformation !== null) {
      await DenyCETAInvitation(inviteInformation.cetaId)
      props.onDeny()
    }
  }

  const approveCETA = async (): Promise<void> => {
    if (inviteInformation !== null) {
      await ApproveCETAInvitation(inviteInformation.cetaId)
      props.onApprove()
    }
  }

  return <Modal
    title={'CETA Invitation'}
    open={true}
    maxWidth='md'
    onClose={props.onCancel}
    onConfirm={() => {}}
    noButtons={true}
    confirmationContent={<>
      <Grid container spacing={3}>
        <Grid item columns={3}>
          <Box sx={{ mb: '.5em' }}>
            <UserDetails
              imageKey={inviteInformation.requestingUser?.imageKey}
              detailName={`${inviteInformation.requestingUser.firstName} ${inviteInformation.requestingUser.lastName}`}
              isOrganizationView={true}
              infoDisplay={'Requestor Information'}
              users={[inviteInformation.requestingUser]}
              isEditable={false}
              onUpdate={() => {}}
            />
          </Box>
        </Grid>
        <Grid item columns={3}>
          <Box sx={{ mb: '.5em' }}>
            <Typography sx={{ fontWeight: 'bold', marginBottom: '1em' }} variant='body2'>{rcnContract}</Typography>
            <Typography data-testid='rcnContractResult' sx={{ marginBottom: '1em' }} variant='body2'>{inviteInformation.isRCNContract}</Typography>
            <Typography sx={{ fontWeight: 'bold', marginBottom: '1em' }} variant='body2'>{purpose}</Typography>
            <Typography data-testid='purposeResult' sx={{ marginBottom: '1em' }} variant='body2'>{inviteInformation.cetaPurpose}</Typography>
            <Typography sx={{ fontWeight: 'bold', marginBottom: '1em' }} variant='body2'>{coachExists}</Typography>
            <Typography data-testid='coachResult' variant='body2'>{inviteInformation.hasStartCoach}</Typography>
            { inviteInformation.coach === null || inviteInformation.coach === undefined
              ? <></>
              : <> <Typography sx={{ fontWeight: 'bold', marginBottom: '1em', marginTop: '1em' }} variant='body2'>{coachNameLabel}</Typography>
                <Typography data-testid='coachResult' variant='body2'>{`${inviteInformation.coach?.firstName ?? ''} ${inviteInformation.coach?.lastName ?? ''}`}</Typography>
              </>
            }
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: '1em', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        { showApproval && <BlockButton onClick={async () => { await denyCETA() }} dataTestId='denyButton' hoverColor='white' backgroundColor='#BF0649' hoverBackgroundColor='#BF0649' color='white' >Deny CETA</BlockButton>}
        <BlockButton backgroundColor='white' onClick={props.onCancel} dataTestId='closeButton'>Close</BlockButton>
        { showApproval && <BlueButton onClick={async () => { await approveCETA() }} dataTestId='approveButton'>Approve</BlueButton>}
      </Box>
    </>
    }
  />
}
