import { type MouseEvent, useEffect, useState } from 'react'
import { Box, Card, CircularProgress, Grid, type SxProps, Typography } from '@mui/material'
import { GetPlanOverview, GetTemplateInstructions } from '../../../../services/CETAClassroomService'
import type { PlanOverviewSection } from '../../../../models/PlanOverviewSection'
import { IconWithLabel } from '../../../customComponents/IconWithLabel'
import { BlockButton } from '../../../customComponents/buttons/BlockButton'
import { CETAPlanSection } from './CETAPlanSection'
import { CETAInstructionsKey } from '../../../../models/CETATemplateInstructions'
import { generatePath, useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../Routes'
import { BlueButton } from '../../../customComponents/buttons/BlueButton'
import { toast } from 'react-toastify'
import type { PlanOverviewSectionCardModel } from '../../../../models/PlanOverviewSectionCardModel'
import { AddCircle, AddCircleOutline, CheckCircle, CheckCircleOutline, Close, Download, Error, ErrorOutline } from '@mui/icons-material'
import { CETAOverviewTabs } from '../CETAOverview'

interface Props {
  readOnly: boolean
  classroomId: string
  viewingPlanDetails: boolean
  onFinalizeSectionPlan: () => void
  setViewingPlanDetails: (viewingPlanDetails: boolean) => void
}

export const CETAPlanOverview = (props: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [cards, setCards] = useState<PlanOverviewSectionCardModel[]>([])
  const [planningInstructions, setPlanningInstructions] = useState('')
  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(null)
  const [isSelectingSections, setIsSelectingSections] = useState<boolean>(false)

  const nav = useNavigate()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)
      await Promise.all([
        fetchSections(),
        fetchInstructions()
      ])
      setIsLoading(false)
    }

    void fetchData()
  }, [])

  const fetchSections = async (): Promise<void> => {
    const planOverviewResponse = await GetPlanOverview(props.classroomId)
    const cardModel: PlanOverviewSectionCardModel[] = []
    planOverviewResponse.forEach(por => cardModel.push({ section: por, isSelected: false }))
    setCards(cardModel)
  }

  const fetchInstructions = async (): Promise<void> => {
    const instructionResponse = await GetTemplateInstructions(props.classroomId)
    setPlanningInstructions(instructionResponse.planning)
  }

  const handleViewDetails = (e: MouseEvent, section: PlanOverviewSection): void => {
    e.stopPropagation()
    props.setViewingPlanDetails(true)
    setSelectedSectionId(section.id)
  }

  const handleBack = (): void => {
    props.setViewingPlanDetails(false)
    setSelectedSectionId(null)
  }

  const selectCard = (id: number, hasGoals: boolean): void => {
    if (!hasGoals) {
      toast.error('This section does not have any goals associated with it.')
      return
    }

    const copiedCards: PlanOverviewSectionCardModel[] = [...cards]

    copiedCards.forEach(c => {
      if (c.section.id === id) {
        c.isSelected = !c.isSelected
      }
    })

    setCards(copiedCards)
  }

  const printSelection = (): void => {
    if (cards.find(c => c.isSelected) == null) {
      toast.error('No sections are selected.')
      return
    }
    const sectionsToPrint: string[] = cards.filter((c) => c.isSelected).map(c => c.section.id.toString())
    const printRoute = generatePath(AppRoutes.CETA_SECTION_PRINT, { classroomId: props.classroomId, tab: CETAOverviewTabs.Plan, sectionIds: sectionsToPrint.join(',') })
    nav(printRoute)
  }

  if (isLoading) {
    return <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box>
  }

  return <>
    {!props.viewingPlanDetails &&
      <Grid container spacing={2}>
        <Grid item width='65%'>
          <Box sx={{ display: 'flex', marginLeft: '2em', justifyContent: 'space-between' }}>
            <Typography variant='h5' fontWeight='600'>Sections</Typography>

            {isSelectingSections &&
              <Box>
                <BlueButton onClick={() => { setIsSelectingSections(false) }} sx={{ marginLeft: '1em', justifyContent: 'space-between' }} dataTestId='cancelSelection'>
                  Cancel
                  <Close sx={{ ml: '.25em' }} />
                </BlueButton>

                <BlueButton onClick={printSelection} sx={{ marginLeft: '1em', justifyContent: 'space-between' }} dataTestId='downloadGoals'>
                  Download Selected Sections
                  <Download sx={{ ml: '.25em' }} />
                </BlueButton>
              </Box>
            }

            {!isSelectingSections &&
              <BlueButton onClick={() => { setIsSelectingSections(true) }} sx={{ marginLeft: '1em', justifyContent: 'space-between' }} dataTestId='setSections'>
                Select sections for Action Plan
                <Download sx={{ ml: '.25em' }} />
              </BlueButton>
            }
          </Box>

          {isSelectingSections &&
            <Typography sx={{ marginLeft: '2em' }} variant='body1'>Select the sections you'd like to generate an action plan for</Typography>
          }

          <Grid container spacing={2} sx={{ mt: '1em', ml: '2em' }}>
            {cards.map((card, index) => {
              const hasGoals = card.section.sectionGoalsCreated > 0 || card.section.indicatorGoalsCreated > 0

              const iconSx: SxProps = { cursor: 'pointer', position: 'relative', left: 0, top: 20, zIndex: 1, backgroundColor: 'white', borderRadius: '16px', color: '#004A70' }

              return <Grid item key={index}>
                <Box>
                  {isSelectingSections && card.isSelected &&
                    <CheckCircle
                      data-testid={`${card.section.name}-checkIcon`}
                      sx={iconSx}
                      onClick={() => { selectCard(card.section.id, hasGoals) }}
                    />
                  }

                  {isSelectingSections && !card.isSelected && hasGoals &&
                    <AddCircle
                      data-testid={`${card.section.name}-addIcon`}
                      sx={iconSx}
                      onClick={() => { selectCard(card.section.id, hasGoals) }}
                    />
                  }

                  {isSelectingSections && !hasGoals &&
                    <Error
                      data-testid={`${card.section.name}-errorIcon`}
                      sx={iconSx}
                      onClick={() => { selectCard(card.section.id, hasGoals) }}
                    />
                  }

                  <Card
                    onClick={() => { selectCard(card.section.id, hasGoals) }}
                    data-testid={`${card.section.name}-card`}
                    sx={{
                      position: 'relative',
                      width: '270px',
                      height: '270px',
                      borderRadius: '16px',
                      backgroundColor: index % 2 === 0 ? 'rgb(188, 230, 245)' : 'rgb(216, 217, 235)',
                      ':hover': isSelectingSections ? { cursor: 'pointer', backgroundColor: index % 2 === 0 ? 'rgb(150, 201, 219)' : 'rgb(188, 189, 206)' } : {},
                      border: isSelectingSections && card.isSelected ? '6px solid #004A70' : ''
                    }}
                  >
                    <Box sx={{ m: '1em' }}>
                      <Typography sx={{ fontWeight: 600, fontSize: '1.25em' }}>{card.section.name}</Typography>

                      <Box sx={{ display: 'flex', my: '2em' }}>
                        {card.section.indicatorGoalsCreated === 0 && card.section.sectionGoalsCreated === 0 &&
                          <Box>
                            <IconWithLabel
                              icon={<AddCircleOutline />}
                              labelText={`Strengths (${card.section.strengths})`}
                              dataTestId={`${card.section.name}-strengths`}
                            />

                            <IconWithLabel
                              icon={<ErrorOutline />}
                              labelText={`Consider As Priority (${card.section.considerPriority})`}
                              dataTestId={`${card.section.name}-priorities`}
                              sx={{ mt: '.5em' }}
                            />
                          </Box>
                        }

                        {(card.section.indicatorGoalsCreated > 0 || card.section.sectionGoalsCreated > 0) &&
                          <Box>
                            <IconWithLabel
                              icon={<CheckCircleOutline />}
                              labelText={`Indicator Goal Created (${card.section.indicatorGoalsCreated})`}
                              dataTestId={`${card.section.name}-indicators`}
                            />

                            {card.section.sectionGoalsCreated > 0 &&
                              <IconWithLabel
                                icon={<CheckCircle />}
                                labelText='Section Goal Created'
                                dataTestId={`${card.section.name}-sections`}
                                sx={{ mt: '.5em' }}
                              />
                            }
                          </Box>
                        }
                      </Box>

                      <Box sx={{ marginTop: '3em' }}>
                        <BlockButton
                          disabled={isSelectingSections}
                          sx={{ position: 'absolute', bottom: 0, mb: '1em' }}
                          backgroundColor='rgb(255, 255, 255, .5)'
                          onClick={(e) => { handleViewDetails(e, card.section) }}
                          dataTestId={card.section.name + '-detailsButton'}
                        >
                          View Section Details
                        </BlockButton>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            })}
          </Grid>
        </Grid>

        <Grid item sx={{ marginLeft: '2em' }}>
          <Card
            sx={{
              backgroundColor: '#FAFEFF',
              maxWidth: '300px',
              border: '1px solid #21AEDE',
              p: '1em'
            }}
          >
            <Typography variant='h5' color={'#004A70'}>
              {CETAInstructionsKey.PlanningInstructions}
            </Typography>

            <div className='ql-editor' dangerouslySetInnerHTML={{ __html: planningInstructions }} />

            <Typography variant='body1' fontWeight='600' sx={{ mb: '1em', marginTop: '1em' }}> Legend</Typography>
            <IconWithLabel sx={{ mb: '.5em' }} icon={<CheckCircleOutline />} labelText='Goal Created - Indicator' />
            <IconWithLabel sx={{ mb: '.5em' }} icon={<CheckCircle />} labelText='Goal Created - Section' />
            <IconWithLabel sx={{ mb: '.5em' }} icon={<ErrorOutline />} labelText='Consider As Priority' />
            <IconWithLabel sx={{ mb: '.5em' }} icon={<AddCircleOutline />} labelText='Strength' />
          </Card>
        </Grid>
      </Grid>
    }

    {props.viewingPlanDetails && selectedSectionId != null &&
      <CETAPlanSection
        readOnly={props.readOnly}
        classroomId={props.classroomId}
        sectionId={selectedSectionId}
        goBack={handleBack}
        onFinalize={props.onFinalizeSectionPlan}
        refetchOverviewSections={fetchSections}
      />
    }
  </>
}
