import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { type AuthUser, useAuth } from '../../hooks/use-auth'

interface Props {
  allow: (user: AuthUser) => boolean
}

export const ProtectedRoute = (props: Props): JSX.Element => {
  const auth = useAuth()

  if (auth.loading) return <></>

  if (auth.user == null || !auth.user.isActive || auth.user.noAccount || !props.allow(auth.user)) {
    return <NotAllowed />
  }

  return <Outlet />
}

export const NotAllowed = (): JSX.Element => {
  return (
    <Box justifyContent="center" display={'flex'}>
      You are not authorized to access this page
    </Box>
  )
}
