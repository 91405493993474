import { Box, Card, TextField, Typography } from '@mui/material'
import { type NeedsAttentionModel } from '../../../../models/NeedsAttentionModel'
import { OrganizationAccordion } from './OrganizationAccordion'
import { AccessRequestAccordion } from './AccessRequestAccordion'
import { WarningCard } from '../../../customComponents/WarningCard'
import { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { type OrganizationDetailsModel } from '../../../../models/OrganizationDetailsModel'
import { OrganizationType } from '../../../../utils/genericTypes'
import { type OrganizationOverviewModel } from '../../../../models/OrganizationOverviewModel'
import { useAuth } from '../../../../hooks/use-auth'
import { AuthFunctions, hasPermission } from '../../../../utils/permissions'

interface Props {
  primaryOrg: OrganizationDetailsModel
  needAttentionEntity: NeedsAttentionModel
}

export const NeedsAttentionCard = (props: Props): JSX.Element => {
  const auth = useAuth()

  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredOrgs, setFilteredOrgs] = useState<OrganizationOverviewModel[]>(props.needAttentionEntity.organizations)
  const [filteredAccessRequests, setFilteredAccessRequests] = useState(props.needAttentionEntity.accessRequests)

  useEffect(() => {
    setFilteredOrgs(props.needAttentionEntity.organizations)
    setFilteredAccessRequests(props.needAttentionEntity.accessRequests)
  }, [props.needAttentionEntity])

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    setSearchValue(value)

    const orgs = props.needAttentionEntity.organizations.filter(o =>
      (o.name.toLowerCase().includes(value.toLowerCase())))
    const accessRequests = props.needAttentionEntity.accessRequests.filter(ar =>
      `${ar.firstName} ${ar.lastName}`.toLowerCase().includes(value.toLowerCase()))

    setFilteredOrgs(orgs)
    setFilteredAccessRequests(accessRequests)
  }

  const subOrgType = (): string => {
    if (props.primaryOrg.type === OrganizationType.Project) {
      return OrganizationType.RCN
    } else if (props.primaryOrg.type === OrganizationType.RCN) {
      return OrganizationType.ISD
    } else if (props.primaryOrg.type === OrganizationType.ISD) {
      return OrganizationType.District
    }

    return ''
  }

  const rcns = filteredOrgs.filter(o => o.type === OrganizationType.RCN)
  const isds = filteredOrgs.filter(o => o.type === OrganizationType.ISD)
  const districts = filteredOrgs.filter(o => o.type === OrganizationType.District)

  return <Card sx={{ p: '1.25em', backgroundColor: '#F3F3F380', border: '1px solid #CBC8CC' }}>
    <Typography variant='h4' sx={{ fontWeight: '600', textAlign: 'center', fontSize: '1.5em' }}>Needs Attention</Typography>

    {props.primaryOrg.type !== OrganizationType.District &&
      <Typography variant='h6' sx={{ mt: '.5em' }}>
        {props.needAttentionEntity.onboardedOrgsTotal} {subOrgType()}s have been onboarded
      </Typography>
    }

    <Box sx={{ position: 'relative', mt: '.5em' }}>
      <TextField
        label='Name'
        name='nameSearch'
        onChange={onNameChange}
        value={searchValue}
        sx={{ width: '100%' }}
        data-testid='nameSearch'
      />
      <SearchIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '0.5em' }} />
    </Box>

    {rcns.length > 0 && <Typography variant='h5' sx={{ fontWeight: '600', fontSize: '1.25em', mt: '.5em' }}>RCN</Typography>}

    {rcns.map((rcn, index) => (
      <Box key={index} sx={{ my: '.625em' }} data-testid='needsAttentionItem'>
        <OrganizationAccordion
          primaryOrg={props.primaryOrg}
          organization={rcn}
          canViewOrg={auth.user != null && hasPermission(auth.user, AuthFunctions.RCN_MANAGEMENT)}
        />
      </Box>
    ))}

    {isds.length > 0 && <Typography variant='h5' sx={{ fontWeight: '600', fontSize: '1.25em', mt: '.5em' }}>ISD</Typography>}

    {isds.map((isd, index) => (
      <Box key={index} sx={{ my: '.625em' }} data-testid='needsAttentionItem'>
        <OrganizationAccordion
          primaryOrg={props.primaryOrg}
          organization={isd}
          canViewOrg={auth.user != null && hasPermission(auth.user, AuthFunctions.ISD_MANAGEMENT)}
        />
      </Box>
    ))}

    {districts.length > 0 && <Typography variant='h5' sx={{ fontWeight: '600', fontSize: '1.25em', mt: '.5em' }}>District</Typography>}

    {districts.map((district, index) => (
      <Box key={index} sx={{ my: '.625em' }} data-testid='needsAttentionItem'>
        <OrganizationAccordion
          primaryOrg={props.primaryOrg}
          organization={district}
          canViewOrg={auth.user != null && hasPermission(auth.user, AuthFunctions.DISTRICT_MANAGEMENT)}
        />
      </Box>
    ))}

    {filteredAccessRequests.length > 0 && <Typography variant='h5' sx={{ fontWeight: '600', fontSize: '1.25em', mt: '.5em' }}>Access Request</Typography>}

    {filteredAccessRequests.map((accessRequest, index) => (
      <Box key={index} sx={{ my: '.625em' }} data-testid='needsAttentionItem'>
        {accessRequest != null && <AccessRequestAccordion primaryOrg={props.primaryOrg} accessRequest={accessRequest} />}
      </Box>
    ))}

    {filteredOrgs.length === 0 && filteredAccessRequests.length === 0 && <WarningCard message='No items need attention' />}
  </Card>
}
