import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { GetOrgSummary } from '../../../services/OrganizationService'
import { generatePath, useNavigate, useParams } from 'react-router'
import { SummaryCard } from './../../customComponents/SummaryCard'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ListAltIcon from '@mui/icons-material/ListAlt'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { OrganizationType } from '../../../utils/genericTypes'
import { AppRoutes } from './../../Routes'
import { OrgTabs } from './../../pageComponents/organizations/OrganizationDetails'
import { type OrganizationSummaryModel } from '../../../models/OrganizationSummaryModel'
import { FormatDateAsTimeAgo } from '../../../utils/dateUtils'
import { SetBreadcrumbContext } from '../../../contextProviders/BreadcrumbProvider'

export const Administration = (): JSX.Element => {
  const { id: orgId } = useParams()
  const nav = useNavigate()
  const setBreadcrumbs = useContext(SetBreadcrumbContext)

  const [org, setOrg] = useState<OrganizationSummaryModel | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (orgId == null) {
        return
      }

      const organization = await GetOrgSummary(orgId)
      setOrg(organization)
    }

    void fetchData()
  }, [orgId])

  const goToPage = (tab: string): void => {
    const adminRoute = generatePath(AppRoutes.ADMINISTRATION, { id: orgId ?? '' })
    setBreadcrumbs([{ name: 'Admin Home', route: adminRoute }])

    const detailsRoute = generatePath(AppRoutes.ORGANIZATION_DETAILS, { id: orgId ?? '', tab })
    nav(detailsRoute)
  }

  if (org == null) {
    return <CircularProgress />
  }

  return <>
    <Typography variant='h3' className='page-header' data-testid='pageHeader'>Administration - {org.name}</Typography>

    <Grid container spacing={2} sx={{ mt: '2em' }}>
      <Grid item>
        <SummaryCard
          title='Overview'
          icon={<InfoOutlinedIcon />}
          content='Review the details of your organization and make changes'
          footerContent={<Box>
            <Typography>{org.totalOutstandingOverviewActions} Outstanding Action{org.totalOutstandingOverviewActions === 1 ? '' : 's'}</Typography>
            <Typography>Last updated {org.lastUpdatedOverview != null ? FormatDateAsTimeAgo(org.lastUpdatedOverview) : 'never'}</Typography>
          </Box>}
          dataTestId='overviewCard'
          onClick={() => { goToPage(OrgTabs.Overview) }}
        />
      </Grid>

      <Grid item>
        <SummaryCard
          title='Organization List'
          icon={<ListAltIcon />}
          content={'Manage the organizations that are part of your organization\'s scope'}
          footerContent={<Box>
            <Typography>{org.totalOutstandingOrgActions} Outstanding Action{org.totalOutstandingOrgActions === 1 ? '' : 's'}</Typography>
            <Typography>Last updated {org.lastUpdatedOrgs != null ? FormatDateAsTimeAgo(org.lastUpdatedOrgs) : 'never'}</Typography>
          </Box>}
          dataTestId='organizationListCard'
          onClick={() => { goToPage(OrgTabs.OrganizationList) }}
        />
      </Grid>

      <Grid item>
        <SummaryCard
          title='User Access'
          icon={<AccountCircleOutlinedIcon />}
          content='Manage access to your organization'
          footerContent={<Box>
            <Typography>{org.totalUserAccessRequests} Outstanding Request{org.totalUserAccessRequests === 1 ? '' : 's'}</Typography>
            <Typography>Last updated {org.lastUpdatedUsers != null ? FormatDateAsTimeAgo(org.lastUpdatedUsers) : 'never'}</Typography>
          </Box>}
          dataTestId='userAccessCard'
          onClick={() => { goToPage(OrgTabs.Users) }}
        />
      </Grid>

      {(org.type === OrganizationType.Project || org.type === OrganizationType.ISD || org.type === OrganizationType.District) && <Grid item>
        <SummaryCard
          title='Configuration'
          icon={<SettingsOutlinedIcon />}
          content='Manage configuration settings for your organization'
          footerContent={<Box>
            <Typography>Last updated {org.lastUpdatedConfig != null ? FormatDateAsTimeAgo(org.lastUpdatedConfig) : 'never'}</Typography>
          </Box>}
          dataTestId='configurationCard'
          onClick={() => { goToPage(OrgTabs.Configuration) }}
        />
      </Grid>}
    </Grid>
  </>
}
