import type { AvailableCETAProcesses } from '../models/AvailableCETAProcesses'
import type { CETAPlanSectionModel } from '../models/CETAPlanSectionModel'
import { CETAClassroomStage } from '../models/CETAClassroomStage'
import { CETAClassroomStatus } from '../models/CETAClassroomStatus'
import { TranslateDate } from './dateUtils'

export function TranslateSectionDates (section: CETAPlanSectionModel): CETAPlanSectionModel {
  if (section.goal != null) {
    section.goal.targetGoalDate = TranslateDate(section.goal.targetGoalDate)
    section.goal.actions.forEach(a => {
      a.targetDate = TranslateDate(a.targetDate)
    })
  }

  if (section.goalLock != null) {
    section.goalLock.lockedUntil = TranslateDate(section.goalLock.lockedUntil)
  }

  section.goal?.notes.forEach(n => {
    n.updateDate = TranslateDate(n.updateDate)
  })

  section.indicators.forEach(i => {
    if (i.goal != null) {
      i.goal.targetGoalDate = TranslateDate(i.goal.targetGoalDate)
      i.goal.actions.forEach(a => {
        a.targetDate = TranslateDate(a.targetDate)
      })
    }

    if (i.goalLock != null) {
      i.goalLock.lockedUntil = TranslateDate(i.goalLock.lockedUntil)
    }

    i.goal?.notes.forEach(n => {
      n.updateDate = TranslateDate(n.updateDate)
    })
  })

  return section
}

export function GetAvailableCETAProcesses (status: string, stage: string): AvailableCETAProcesses {
  const processes: AvailableCETAProcesses = { planningReadonly: false, progressUpdatesReadonly: false, dashboardReadonly: false, planningTabLocked: false, progressUpdatesTabLocked: false, progressReportsLocked: false }
  if (status === CETAClassroomStatus.Active) {
    if (stage === CETAClassroomStage.CETAKickoff) {
      processes.planningReadonly = true
      processes.progressUpdatesReadonly = true
      processes.planningTabLocked = true
      processes.progressUpdatesTabLocked = true
      processes.progressReportsLocked = true
    }

    if (stage === CETAClassroomStage.CETAUpdateRequired) {
      processes.planningReadonly = true
      processes.progressUpdatesReadonly = true
      processes.planningTabLocked = false
      processes.progressUpdatesTabLocked = false
    }

    if (stage === CETAClassroomStage.Planning) {
      processes.progressUpdatesReadonly = true
      processes.progressUpdatesTabLocked = true
    }

    if (stage === CETAClassroomStage.CETAUpdateCompleted) {
      processes.planningReadonly = true
      processes.progressUpdatesReadonly = true
      processes.dashboardReadonly = true
    }
  }

  if (status === CETAClassroomStatus.Closed) {
    processes.planningReadonly = true
    processes.progressUpdatesReadonly = true
    processes.dashboardReadonly = true
    processes.planningTabLocked = true
    processes.progressUpdatesTabLocked = true
    processes.progressReportsLocked = true
  }

  if (status === CETAClassroomStage.AwaitingApproval) {
    processes.planningReadonly = true
    processes.progressUpdatesReadonly = true
    processes.dashboardReadonly = true
    processes.planningTabLocked = true
    processes.progressUpdatesTabLocked = true
    processes.progressReportsLocked = true
  }
  return processes
}
