import { type FieldError } from '../hooks/use-fetch'

export interface SelectableOption {
  id: number
  name: string
}

export enum SecurityLevel {
  ProjectAdmin = 'Project Admin',
  ProjectUser = 'Project User',
  RCNAdmin = 'RCN Admin',
  RCNUser = 'RCN User',
  ISDAdmin = 'ISD Admin',
  ISDUser = 'ISD User',
  DistrictAdmin = 'District Admin',
  DistrictUser = 'District User'
}

export enum OrganizationType {
  Project = 'Project',
  RCN = 'RCN',
  ISD = 'ISD',
  District = 'District'
}

export enum ActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum AccessStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending'
}

export enum OrganizationStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Onboarding = 'Onboarding'
}

export interface UserPermission {
  nodeName: string
  readPermissionName?: string
  writePermissionName?: string
  readImplicit: boolean
  writeImplicit: boolean
  subPermissions: UserPermission[]
}

export enum YesNoAnswer {
  Yes = 'Yes',
  No = 'No',
  Unsure = 'Unsure'
}

export interface ValidationResult {
  insertedId?: number
  errors: string[]
  fieldErrors: FieldError[]
  isDuplicate: boolean
}
