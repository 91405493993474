import { Box, Card, CircularProgress, Typography } from '@mui/material'
import { OrganizationTaskProgress } from './OrganizationTaskProgress'
import { useEffect, useState } from 'react'
import { GetAnnualPlanTeam, GetTasksForAnnualPlan, GetUsersPlanRole } from '../../../services/AnnualPlanService'
import type { AnnualPlanTeamMember } from '../../../models/AnnualPlanTeamMember'
import type { PlanOrganizationTask } from '../../../models/PlanOrganizationTask'
import { AnnualPlanTeamCard } from './AnnualPlanTeamCard'
import { AnnualPlanRole } from '../../../models/AnnualPlanRole'

interface Props {
  rcnId: number
  annualPlanId: number
}

export function AnnualPlanDashboard (props: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(true)
  const [orgTasks, setOrgTasks] = useState<PlanOrganizationTask[]>([])
  const [planOrganizations, setPlanOrganizations] = useState<string[]>([])
  const [planTeam, setPlanTeam] = useState<AnnualPlanTeamMember[]>([])
  const [usersPlanRole, setUsersPlanRole] = useState<string>()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (props.annualPlanId == null) {
        return
      }

      setIsLoading(true)
      await Promise.all([
        fetchTeam(),
        fetchTaskProgress(),
        fetchUsersPlanRole()
      ])
      setIsLoading(false)
    }

    void fetchData()
  }, [])

  const fetchTeam = async (): Promise<void> => {
    setPlanTeam(await GetAnnualPlanTeam(props.annualPlanId))
  }

  const fetchTaskProgress = async (): Promise<void> => {
    const orgTasks: PlanOrganizationTask[] = await GetTasksForAnnualPlan(props.annualPlanId)
    setOrgTasks(orgTasks)
    const distinctOrgNames = orgTasks.filter(
      (org, i, arr) => arr.findIndex(t => t.organizationName === org.organizationName) === i
    ).map(ot => { return ot.organizationName })
    setPlanOrganizations(distinctOrgNames)
  }

  const fetchUsersPlanRole = async (): Promise<void> => {
    const role: string = await GetUsersPlanRole(props.annualPlanId)
    setUsersPlanRole(role)
  }

  if (isLoading) {
    return <CircularProgress></CircularProgress>
  }

  return <Box sx={{ display: 'flex' }}>
    <Card data-testid='planningprogress-card' sx={{ backgroundColor: '#F3F3F3', width: '60%' }}>
      <Typography justifySelf='center' variant='h5' fontWeight='600' color='#021E40' my='.5em'>Annual Planning Progress</Typography>
      { planOrganizations.map(po => {
        return <Box sx={{ m: '1em' }}>
          <OrganizationTaskProgress
            organizationName={po}
            organizationTasks={orgTasks.filter(task => task.organizationName === po)}
          />
        </Box>
      })}
    </Card>
    <Box sx={{ width: '35%', ml: '1em' }}>
      <AnnualPlanTeamCard onTeamChange={fetchTeam} team={planTeam} annualPlanId={props.annualPlanId} readonly={usersPlanRole !== AnnualPlanRole.PlanAdministrator}></AnnualPlanTeamCard>
    </Box>
  </Box>
}
