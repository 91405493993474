import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import type { CETAProgressReportSectionModel } from '../../../models/CETAProgressReportModel'
import { GetProgressReport } from '../../../services/CETAClassroomService'
import { KeyboardArrowDown } from '@mui/icons-material'
import { ResourceLayers } from '../../../assets/svgs/ResourceLayers'
import { LineChart, lineElementClasses, markElementClasses } from '@mui/x-charts/LineChart'
import { TranslateDate } from '../../../utils/dateUtils'
import { GetTimeSpan } from '../../../services/ImplementationService'
import { ProgressReportLegendIcon } from '../../../assets/svgs/ProgressReportLegend'
import { axisClasses } from '@mui/x-charts/ChartsAxis'

interface Props {
  classroomId: string
  timeSpanId: number | null
}

export const CETAProgressReport = (props: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [reportSections, setReportSections] = useState<CETAProgressReportSectionModel[]>([])
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())

  const fetchData = async (): Promise<void> => {
    if (props.classroomId == null) {
      return
    }

    if (props.timeSpanId != null) {
      const response = await GetTimeSpan(props.timeSpanId.toString())
      setStartDate(TranslateDate(response.startDate))
      setEndDate(TranslateDate(response.endDate))
    }

    setReportSections(await GetProgressReport(props.classroomId))
    setIsLoading(false)
  }

  useEffect(() => {
    void fetchData()
  }, [props.classroomId])

  if (isLoading) {
    return <CircularProgress />
  }

  return <>
    { reportSections.map((section, sectionIndex) => (
      <Accordion
        key={sectionIndex}
        data-testid={`section-${section.name}`}
        sx={{ mb: '.5em', borderRadius: '6px' }}
      >
        <AccordionSummary
          sx={{ opacity: '1 !important' }}
          expandIcon={
            <KeyboardArrowDown
              data-testid='expand'
              sx={{
                color: 'white',
                bgcolor: '#726F74',
                width: '1.125em',
                height: '1.125em',
                borderRadius: '50%'
              }}
            />
          }
        >
          <Box display='flex' alignItems='center'>
            <ResourceLayers width='24px' height='24px' />
            <Typography variant='h6' ml='.5em'>
              {section.name}
            </Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          <Typography variant='body1' mb='1em' data-testid='progressInfo'>
            Progress is ranked from 0-4 with an initial kickoff, subsequent snapshots, and ending with the CETA update.
          </Typography>
          <Box sx={{ marginBottom: '1em' }}>
            <ProgressReportLegendIcon />
          </Box>
          {section.indicators.map((indicator, index) => (
            <Box key={`${indicator.name}-${index}`}>
              <Typography sx={{ width: '100%' }} variant='body1'><b>{indicator.name}</b>{indicator.description.replace(indicator.name, '')}</Typography>
              <Box sx={{ height: '300px', maxWidth: '750px' }}>
                <LineChart
                  data-testid='progresslinegraph'
                  sx={{
                    [`.${lineElementClasses.root}, .${markElementClasses.root}`]: {
                      strokeWidth: 3,
                      color: '#21AEDE',
                      fill: '#21AEDE'
                    },
                    [`.${axisClasses.root}`]: {
                      [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                        stroke: '#CBC8CC',
                        strokeWidth: 2
                      }
                    },
                    overflow: 'hidden',
                    alignItems: 'start',
                    height: 'fit-content'
                  }}
                  dataset={indicator.progressRatings.map((option) => ({ finalizedDate: TranslateDate(option.finalizedDate), rating: option.rating ?? 0 }))}
                  xAxis={[{
                    dataKey: 'finalizedDate',
                    scaleType: 'time',
                    valueFormatter: (value: Date) => value.toLocaleString('default', { month: 'short' }),
                    min: startDate,
                    max: endDate,
                    tickNumber: 12,
                    tickLabelInterval: (value, index) => index % 2 === 0
                  }]}
                  yAxis={[{
                    min: 0,
                    max: 4,
                    tickNumber: 5
                  }]}
                  series={[{
                    dataKey: 'rating',
                    id: 'rateId',
                    type: 'line'
                  }]}
                  leftAxis={{
                    label: 'Progress Rating',
                    labelStyle: { fontWeight: 'bold' },
                    disableTicks: true
                  }}
                  rightAxis={{
                    disableTicks: true,
                    tickLabelStyle: { display: 'none' }
                  }}
                  topAxis={{
                    disableTicks: true,
                    tickLabelStyle: { display: 'none' },
                    fill: '#CBC8CC'
                  }}
                  bottomAxis={{
                    label: 'Snapshots',
                    labelStyle: { fontWeight: 'bold' },
                    disableTicks: true
                  }}
                  grid={{ horizontal: true, vertical: true }}
                />
              </Box>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    ))}
  </>
}
