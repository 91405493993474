import { MoreHoriz } from '@mui/icons-material'
import { Box, Typography, Menu, MenuItem } from '@mui/material'
import { ProfileImage } from '../../../customComponents/user/ProfileImage'
import { type CETAGoal, type CETAGoalNote } from '../../../../models/CETAPlanSectionModel'
import { type MouseEvent, useState } from 'react'
import { ProgressNoteModal } from '../ProgressUpdates/ProgessNoteModal'
import { Modal } from '../../../customComponents/Modal'
import { DeleteProgressNote } from '../../../../services/CETAClassroomService'

interface Props {
  note: CETAGoalNote
  readOnly?: boolean
  goal: CETAGoal | undefined
  onSave?: () => void
}

export const CETAProgressNoteDetails = (props: Props): JSX.Element => {
  const [isProgressMenuOpen, setIsProgressMenuOpen] = useState(false)
  const [progressEllipses, setProgressEllipses] = useState<SVGSVGElement | null>(null)
  const [isDeletingProgressNote, setIsDeletingProgressNote] = useState(false)
  const [isEditingProgressNote, setIsEditingProgressNote] = useState(false)

  const openProgressMenu = (e: MouseEvent<SVGSVGElement>, note: CETAGoalNote): void => {
    setProgressEllipses(e.currentTarget)
    setIsProgressMenuOpen(true)
  }

  const startEditingProgressNote = (): void => {
    setIsEditingProgressNote(true)
    setIsProgressMenuOpen(false)
  }

  const startDeletingProgressNote = (): void => {
    setIsDeletingProgressNote(true)
    setIsProgressMenuOpen(false)
  }

  const deleteProgressNote = async (): Promise<void> => {
    await DeleteProgressNote(props.note.id)
    setIsDeletingProgressNote(false)
    if (props.onSave != null) {
      props.onSave()
    }
  }

  return <Box display='flex' sx={{ mb: '1em', maxWidth: '500px' }} data-testid='progressNoteContainer'>
    <ProfileImage name={props.note.owner.name} imageKey={props.note.owner.imageKey} />

    <Box ml='.5em'>
      <Box display='flex'>
        <Typography fontSize='14px'>{props.note.owner.name}</Typography>
        <Typography fontSize='14px' ml='1em'>{props.note.updateDate.toLocaleDateString()}</Typography>
      </Box>

      <Typography fontSize='10px'>{props.note.note}</Typography>
    </Box>

    {props.readOnly === false &&
      <MoreHoriz
        aria-label='menu'
        data-testid='progressNoteMenu'
        sx={{ cursor: 'pointer', ml: '1em' }}
        onClick={(e) => { openProgressMenu(e, props.note) }}
      />
    }

    <Menu open={isProgressMenuOpen} anchorEl={progressEllipses} onClose={() => { setIsProgressMenuOpen(false) }}>
      <MenuItem
        data-testid='editProgressNote'
        onClick={startEditingProgressNote}
      >
        Edit
      </MenuItem>

      <MenuItem
        data-testid='deleteProgressNote'
        onClick={startDeletingProgressNote}
      >
        Remove
      </MenuItem>
    </Menu>

    {isEditingProgressNote &&
      <ProgressNoteModal
        note={props.note}
        goalId={props.goal?.id ?? 0}
        onClose={() => { setIsEditingProgressNote(false) }}
        onSave={props.onSave}
      />
    }

    {isDeletingProgressNote &&
      <Modal
        open={true}
        title='Remove Progress Note'
        confirmButtonText='Remove'
        onClose={() => { setIsDeletingProgressNote(false) }}
        onConfirm={deleteProgressNote}
        bodyContent='Are you sure you want to remove this progress note?'
      />
    }
  </Box>
}
