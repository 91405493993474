import { Box, Input, InputLabel, type SxProps } from '@mui/material'
import { useEffect, useState } from 'react'

interface Props {
  initialValue?: string
  readOnly?: boolean
  name?: string
  label?: string
  labelContent?: JSX.Element
  required?: boolean
  showRequiredText?: boolean
  placeholder?: string
  sx?: SxProps
  inputSx?: SxProps
  error?: boolean
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
}

export const TextArea = (props: Props): JSX.Element => {
  const [value, setValue] = useState(props.initialValue ?? '')

  useEffect(() => {
    setValue(props.initialValue ?? '')
  }, [props.initialValue])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setValue(e.target.value)
    if (props.onChange != null) {
      props.onChange(e)
    }
  }

  return <Box sx={props.sx}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <InputLabel
        sx={{ color: 'black' }}
        id={props.name}
        required={props.required === true && props.showRequiredText !== true}
        disableAnimation={true}
        variant="standard"
      >
        {props.labelContent ?? props.label}
      </InputLabel>

      {props.required !== true && props.showRequiredText === true && <Box sx={{ fontWeight: 200 }}>Optional</Box>}
      {props.required === true && props.showRequiredText === true && <Box sx={{ fontWeight: 400, color: '#E00000' }}>Required</Box>}
    </Box>

    <Input
      multiline={true}
      disableUnderline={true}
      minRows={3}
      readOnly={props.readOnly}
      placeholder={props.placeholder}
      name={props.name}
      value={value}
      data-testid={props.name}
      error={props.error}
      onFocus={props.onFocus}
      onChange={handleChange}
      onBlur={props.onBlur}
      inputProps={{ 'aria-labelledby': props.name }}
      sx={{
        ...props.inputSx,
        width: '100%',
        border: props.error !== true ? '1px solid #CBC8CC' : '1px solid #E00000',
        borderRadius: '4px',
        padding: '1em'
      }}
    />
  </Box>
}
