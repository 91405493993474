import { Box } from '@mui/system'
// import supportIcon from '../../Assets/Icons/support-icon.png'

export function Footer (): JSX.Element {
  const year = new Date().getFullYear()

  return <Box sx={{ backgroundColor: '#fff', position: 'fixed', zIndex: '1201', bottom: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '29px' }}>
    <p data-test-copyright-text>© Copyright {year} Michigan Collaboration Hub at MAISA</p>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '44px', cursor: 'pointer' }}>
      {/* <img src={ supportIcon } alt='support-icon' style={{ marginRight: '6px' }}/>
      <p style={{ fontStyle: 'bold', lineHeight: '180%' }}><b data-test-support-link>Support</b></p> */}
    </Box>
  </Box>
}
