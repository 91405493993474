import { AccessTime, Cancel, CheckCircleOutline, DoNotDisturb, KeyboardArrowDown, WarningAmber } from '@mui/icons-material'
import { Box, Chip, Menu, MenuItem, Typography } from '@mui/material'
import { ProfileImage } from '../../../customComponents/user/ProfileImage'
import { ActionStatus, type CETAPlanAction } from '../../../../models/CETAPlanSectionModel'
import { useRef, useState } from 'react'
import { UpdateActionStatus } from '../../../../services/CETAClassroomService'

interface Props {
  readOnly: boolean
  action: CETAPlanAction
  index?: number
  showStatus?: boolean
  deleteAction?: (index: number) => void
  onUpdateStatus?: () => void
}

export const CETAGoalActionDetails = (props: Props): JSX.Element => {
  const statusChip = useRef<HTMLDivElement | null>(null)

  const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false)

  const handleDelete = (): void => {
    if (props.deleteAction != null && props.index != null) {
      props.deleteAction(props.index)
    }
  }

  const handleUpdateStatus = async (status: ActionStatus): Promise<void> => {
    setIsStatusMenuOpen(false)
    await UpdateActionStatus(props.action.id ?? 0, status)
    if (props.onUpdateStatus != null) {
      props.onUpdateStatus()
    }
  }

  let statusIcon = <></>
  let statusLabel = ''
  if (props.action.status === ActionStatus.NotStarted) {
    statusIcon = <DoNotDisturb sx={{ color: '#3B353E !important' }} />
    statusLabel = 'Not Started'
  } else if (props.action.status === ActionStatus.InProgress) {
    statusIcon = <AccessTime sx={{ color: '#3B353E !important' }} />
    statusLabel = 'In Progress'
  } else if (props.action.status === ActionStatus.OnHold) {
    statusIcon = <WarningAmber sx={{ color: '#3B353E !important' }} />
    statusLabel = 'On Hold'
  } else if (props.action.status === ActionStatus.Complete) {
    statusIcon = <CheckCircleOutline sx={{ color: '#3B353E !important' }} />
    statusLabel = 'Complete'
  }

  return <Box display='flex' alignItems='center' justifyContent='space-between' mb='1em' data-testid='actionDetails'>
    <Box display='flex'>
      <ProfileImage name={props.action.owner.name} imageKey={props.action.owner.imageKey} />

      <Box ml='.5em'>
        <Box display='flex'>
          <Typography fontSize='14px'>{props.action.owner.name}</Typography>
          <Typography fontSize='14px' ml='1em'>{new Date(props.action.targetDate).toLocaleDateString()}</Typography>
        </Box>

        <Typography fontSize='12px'>{props.action.summary}</Typography>
      </Box>

      {props.showStatus === true &&
        <Chip
          ref={statusChip}
          icon={statusIcon}
          data-testid='actionStatus'
          label={
            <Box display='flex' alignItems='center'>
              {statusLabel}

              {props.onUpdateStatus != null && !props.readOnly &&
                <KeyboardArrowDown sx={{ ml: '.25em' }} data-testid='actionStatus-clickable' />
              }
            </Box>
          }
          sx={{ ml: '1em', backgroundColor: 'white', border: '1px solid #CBC8CC' }}
          onClick={props.onUpdateStatus != null && !props.readOnly ? () => { setIsStatusMenuOpen(true) } : undefined}
        />
      }

      <Menu open={isStatusMenuOpen} anchorEl={statusChip.current} onClose={() => { setIsStatusMenuOpen(false) }}>
        <MenuItem
          data-testid='notStarted'
          onClick={async () => { await handleUpdateStatus(ActionStatus.NotStarted) }}
        >
          Not Started
        </MenuItem>

        <MenuItem
          data-testid='inProgress'
          onClick={async () => { await handleUpdateStatus(ActionStatus.InProgress) }}
        >
          In Progress
        </MenuItem>

        <MenuItem
          data-testid='onHold'
          onClick={async () => { await handleUpdateStatus(ActionStatus.OnHold) }}
        >
          On Hold
        </MenuItem>

        <MenuItem
          data-testid='complete'
          onClick={async () => { await handleUpdateStatus(ActionStatus.Complete) }}
        >
          Complete
        </MenuItem>
      </Menu>
    </Box>

    {props.deleteAction != null && !props.readOnly &&
      <Cancel
        sx={{ width: '16px', height: '16px', ml: '1em', color: '#BF0649', cursor: 'pointer' }}
        onClick={handleDelete}
        data-testid='deleteAction'
      />
    }
  </Box>
}
