import { useEffect, useState } from 'react'
import { GetRCNOptionsForUser } from '../../../services/UserManagementService'
import { Box, Typography } from '@mui/material'
import { RCNCard } from './RCNCard'
import { type OrganizationOption } from '../../../models/OrganizationOption'

interface RCNSelectionProps {
  onRCNSelect: (rcn: OrganizationOption) => void
}

export const RCNSelection = (props: RCNSelectionProps): JSX.Element => {
  const [rcns, setRcns] = useState<OrganizationOption[]>([])

  useEffect(() => {
    const getRcns = async (): Promise<void> => {
      const rcnOrgs = await GetRCNOptionsForUser()

      setRcns(rcnOrgs)
    }

    void getRcns()
  }, [])

  return (
    <Box data-testid="rcn-selection" sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '44px'
    }}
    >
      <Typography variant='h6'>RCNs</Typography>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '33px'
      }}
      >
        {rcns?.map((rcn) =>
          <RCNCard
            key={rcn.id}
            name={rcn.name}
            imageKey={rcn.imageKey}
            onClick={() => { props.onRCNSelect(rcn) }}
          />)}
      </Box>
    </Box>
  )
}
