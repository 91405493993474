import { type SelectableOption } from '../utils/genericTypes'

export interface OrganizationListModel {
  id: number
  name: string
  type: OrganizationListType
  imageKey?: string
  updatedAt: Date
  updatedBy: string
  status: string
  childOrgs: SelectableOption[]
}

export enum OrganizationListType {
  Organization = 'Organization',
  Building = 'Building'
}
