import { Avatar, Typography, type SxProps } from '@mui/material'

interface ProfileImageProps {
  imageKey?: string
  name: string
  height?: string
  width?: string
  backgroundColor?: string | null
  numberOfLetters?: number
  sx?: SxProps
}

const colorsList = ['#006699', '#6C3F88', '#50AE1F', '#21AEDE', '#7C7FBD']

export const ProfileImage = (props: ProfileImageProps): JSX.Element => {
  const height = props.height != null ? props.height : '1.6em'
  const width = props.width != null ? props.width : '1.6em'
  const heightString = height?.replace('em', '')
  const fontCalc = Number(heightString) * (0.4)
  const fontSizeString = fontCalc.toString() + 'em'
  const backgroundColor = props.backgroundColor == null ? colorsList[props.name.charCodeAt(0) % colorsList.length] : props.backgroundColor

  const getFileUrl = (imageKey?: string): string | undefined => {
    if (imageKey === null || imageKey === undefined || imageKey === '') {
      return undefined
    }
    return `${process.env.REACT_APP_BACKEND_URL ?? ''}/File/${imageKey}`
  }

  return <Avatar
    src={ getFileUrl(props.imageKey) }
    sx={{ width, height, background: `${backgroundColor}`, ...props.sx }}
    alt={`${props.name} image`}
  >
    <Typography sx={{ fontSize: fontSizeString }}>{props.name.substring(0, props.numberOfLetters ?? 1)}</Typography>
  </Avatar>
}
