export enum CETAClassroomStage {
  AwaitingApproval = 'Awaiting Approval',
  CETAKickoff = 'CETA Kickoff',
  Planning = 'Planning',
  InvitationDeclined = 'Invitation Declined',
  ProgressMonitoring = 'Progress Monitoring',
  CETAUpdateRequired = 'CETA Update Required',
  CETAUpdateCompleted = 'CETA Update Completed',
  Continued = 'Continued',
  Archived = 'Archived'
}
