import { Modal, type SelectOption, Autocomplete } from '@oaisd/michdev.components.react'
import { type SyntheticEvent, useEffect, useState } from 'react'
import type { UserOrganization } from '../../../models/UserOrganization'
import { Box, CircularProgress, Grid } from '@mui/material'
import { UpdateUserSubscriptions } from '../../../services/UserManagementService'
import type { User } from '../../../models/User'
import { UserDetails } from '../../customComponents/user/Details'
import { toast } from 'react-toastify'
import type { Subscription } from '../../../models/Subscription'
import { SubscriptionsEditView } from './SubscriptionsEditView'
import { DistrictSubscriptionOptions, ISDSubscriptionOptions, ProjectSubscriptionOptions, RCNSubscriptionOptions } from '../../../models/SubscriptionOptions'
import { OrganizationType } from '../../../utils/genericTypes'
import { type ProfileOrg } from './UserProfile'

interface Props {
  user: User
  org: ProfileOrg | null
  orgName: string
  closeModal: () => void
  onUpdateUser?: (updatedUser: User) => void
  handleSave: () => void
}

export function SubscriptionsEditModal (props: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(true)
  const orgOptions = getOptionsFromOrganizations(props.user.organizations)
  const [selectedOrgOption, setSelectedOrgOption] = useState<SelectOption<number> | undefined>({ id: props.org?.id ?? 0, name: props.org?.name ?? '' })
  const [selectedOrganization, setSelectedOrganization] = useState<ProfileOrg | null>(props.org)
  const [userSubscriptions, setUserSubscriptions] = useState<Subscription[] | undefined>([])
  const [user, setUser] = useState<User>(props.user)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = (): void => {
    if (props.org !== null) {
      mapUserSubscriptions(props.org?.organizationType ?? '', props.org)
    }
  }

  function getOrgSubscriptions (orgType: string): string[] {
    if (orgType === OrganizationType.RCN) {
      return RCNSubscriptionOptions
    } else if (orgType === OrganizationType.ISD) {
      return ISDSubscriptionOptions
    } else if (orgType === OrganizationType.District) {
      return DistrictSubscriptionOptions
    } else if (orgType === OrganizationType.Project) {
      return ProjectSubscriptionOptions
    }
    return []
  }

  function mapUserSubscriptions (orgType: string, userOrg: ProfileOrg): void {
    const subNames: string[] = getOrgSubscriptions(orgType)
    const userSubs: Subscription[] = []
    subNames.forEach(s => {
      const existing = userOrg.subscriptions.find((su) => su.name === s)
      if (existing !== null && existing !== undefined) {
        userSubs.push(existing)
      } else {
        userSubs.push({ id: 0, inApp: false, inEmail: false, userOrganizationId: userOrg?.id ?? 0, name: s })
      }
    })

    setUserSubscriptions(userSubs)
    setIsLoading(false)
  }

  const handleSubmit = async (): Promise<void> => {
    const currentOrg = user.organizations.find((uo) => uo.id === selectedOrganization?.id)
    if (currentOrg !== undefined && userSubscriptions !== undefined) {
      currentOrg.subscriptions = userSubscriptions
    }

    const subsToSave: Subscription[] = []
    user.organizations.forEach(uo => {
      uo.subscriptions.forEach(os => {
        subsToSave.push(os)
      })
    })

    const success = await UpdateUserSubscriptions(subsToSave)
    if (success) {
      toast.success('User access saved successfully.')
    } else {
      toast.error('User subscriptions failed to save.')
    }
    props.handleSave()
  }

  const updateSubscriptions = (newSubscriptions: Subscription[]): void => {
    setUserSubscriptions(newSubscriptions)
  }

  function getOptionsFromOrganizations (organizations: UserOrganization[]): Array<SelectOption<number>> {
    const organizationOptions: Array<SelectOption<number>> = []
    organizations.forEach(org => organizationOptions.push({ id: org.id, name: org.name }))
    return organizationOptions
  }

  function getOrgById (id: number | undefined): UserOrganization | null {
    const organizations: UserOrganization[] = props.user.organizations
    const org = organizations.find(org => org.id === id) ?? null
    return org
  }

  const onOrgChanged = async (e: SyntheticEvent, value: { id: string | number, name: string } | null): Promise<void> => {
    const orgUser = { ...user }
    const previousOrganization = orgUser.organizations.find((uo) => uo.id === selectedOrganization?.id)
    if (previousOrganization !== undefined && userSubscriptions !== undefined) {
      previousOrganization.subscriptions = userSubscriptions
    }
    setUser(orgUser)
    let newOrg
    if (value != null) {
      newOrg = orgOptions.find(o => o.id === value.id)
    }
    const organization: UserOrganization | null = getOrgById(Number(value?.id ?? 0))
    setSelectedOrgOption(newOrg)
    setSelectedOrganization(organization)
    if (organization !== null) {
      mapUserSubscriptions(organization?.organizationType ?? '', organization)
    }
  }

  return <Modal
    title={'Manage Subscriptions - ' + props.orgName}
    open={true}
    maxWidth='xl'
    cancelButtonText='Cancel'
    confirmButtonClassName='modal-confirm-button'
    buttonClassName='modal-button'
    contentSx={{ borderBottom: '1px solid #CBC8CC' }}
    onClose={props.closeModal}
    onConfirm={handleSubmit}
    confirmationContent={
      isLoading
        ? <CircularProgress />
        : <Grid container spacing={3}>
          <Grid item columns={3}>
            <Box sx={{ mb: '.5em' }}>
              <Autocomplete
                label='Organization'
                name='organization'
                value={selectedOrgOption?.id ?? ''}
                options={orgOptions}
                onChange={onOrgChanged}
                width='100%'
                topPadding='0'
                noBottomPadding={true}
                dataTestId='organizationSelect'
              />
            </Box>

            <UserDetails
              imageKey={selectedOrganization?.imageKey}
              detailName={selectedOrganization?.name ?? ''}
              isOrganizationView={true}
              infoDisplay={'Primary Contact'}
              users={[props.user]}
              isEditable={false}
              onUpdate={props.onUpdateUser}
            />
          </Grid>
          {<SubscriptionsEditView subscriptions={userSubscriptions} updateSubscriptions={updateSubscriptions} />}
        </Grid>
    }
  />
}
