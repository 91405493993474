import { useEffect, useState } from 'react'
import { AuthorizedFunctionsEditModal, type AuthFunctionsForm } from '../../userProfile/authorizedFunctions/AuthorizedFunctionsEditModal'
import { CircularProgress } from '@mui/material'
import { type OrganizationAccessRequest } from '../../../../models/OrganizationAccessRequest'
import { ApproveAccessRequest, DenyAccessRequest, GetAccessRequest } from '../../../../services/UserManagementService'
import { type OrganizationType } from '../../../../utils/genericTypes'
import { toast } from 'react-toastify'

interface Props {
  userId: number
  orgId: number
  orgType: OrganizationType
  closeModal: () => void
  onSubmit: () => void
}

export const AccessRequestModal = (props: Props): JSX.Element => {
  const [accessRequest, setAccessRequest] = useState<OrganizationAccessRequest | undefined>(undefined)

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const request = await GetAccessRequest(props.userId.toString(), props.orgId)
      setAccessRequest(request)
    }

    void loadData()
  }, [])

  const submitRequest = async (form: AuthFunctionsForm): Promise<void> => {
    if (accessRequest == null) {
      return
    }

    if (form.approved) {
      await ApproveAccessRequest(accessRequest.accessRequestId.toString(), form.securityLevel, form.functions, form.note)
      toast.success(`${accessRequest.user.firstName} ${accessRequest.user.lastName} was granted access to your organization`)
    } else {
      const { success, errors } = await DenyAccessRequest(accessRequest.accessRequestId.toString(), form.note)
      if (!success) {
        errors.forEach((error) => {
          toast.error(error)
        })
        return
      }
      toast.success(`${accessRequest.user.firstName} ${accessRequest.user.lastName} was denied access to your organization`)
    }

    props.closeModal()
    props.onSubmit()
  }

  if (accessRequest == null) {
    return <CircularProgress />
  }

  return <AuthorizedFunctionsEditModal
    member={accessRequest.user}
    org={{ id: props.orgId, name: '', type: props.orgType }}
    initialSecurityLevel={accessRequest.roleName}
    requestDetails={accessRequest.requestDetails}
    initialFunctions={accessRequest.functions}
    isAccessRequest={true}
    closeModal={props.closeModal}
    handleSave={submitRequest}
  />
}
