import { useState } from 'react'
import { Modal } from '../../../customComponents/Modal'
import { TextArea } from '../../../customComponents/TextArea'
import { MarkGoalComplete } from '../../../../services/CETAClassroomService'
import { type FieldError } from '../../../../hooks/use-fetch'
import { Typography } from '@mui/material'
import { RocketIcon } from '../../../../assets/svgs/RocketIcon'
import { toast } from 'react-toastify'

interface Props {
  goalId: number
  onClose: () => void
  onSave: () => void
}

export const GoalCompletionModal = (props: Props): JSX.Element => {
  const [notes, setNotes] = useState<string>('')
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([])
  const [isGoalComplete, setIsGoalComplete] = useState(false)

  const markGoalComplete = async (): Promise<void> => {
    const { response } = await MarkGoalComplete(props.goalId, notes)
    if (response.fieldErrors.length > 0) {
      toast.error('Please complete all fields.')
      setFieldErrors(response.fieldErrors)
      return
    }

    props.onSave()
    setIsGoalComplete(true)
  }

  return <>
    {!isGoalComplete &&
      <Modal
        open={true}
        title='Mark Goal Complete'
        confirmButtonText='Confirm'
        fullWidth={true}
        onClose={props.onClose}
        onConfirm={markGoalComplete}
        bodyContent={
          <TextArea
            label='Completion Notes'
            name='completionNotes'
            placeholder='Please provide a summary of how the goal was completed.'
            required={fieldErrors.some((error) => error.fieldName === 'completionNotes')}
            error={fieldErrors.some((error) => error.fieldName === 'completionNotes')}
            showRequiredText={fieldErrors.some((error) => error.fieldName === 'completionNotes')}
            onChange={(e) => { setNotes(e.target.value) }}
          />
        }
      />
    }

    {isGoalComplete &&
      <Modal
        open={true}
        title='Great Work!'
        centerTitle={true}
        confirmButtonText='Close'
        hideCancelButton={true}
        onClose={props.onClose}
        onConfirm={props.onClose}
        contentSx={{ textAlign: 'center' }}
        bodyContent={
          <>
            <RocketIcon />

            <Typography variant='h5' fontWeight='600' mt='1em'>You've completed a goal!</Typography>

            <Typography>
              Take a moment to celebrate your accomplishment. Share your success with those invested and interested in the goal.
            </Typography>
          </>
        }
      />
    }
  </>
}
