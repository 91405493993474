import { Box, Grid, InputLabel } from '@mui/material'
import { MapStateName, TextFieldWithLabel } from '@oaisd/michdev.components.react'
import { useState } from 'react'
import { type Address } from '../../../models/Address'
import { BlueButton } from '../buttons/BlueButton'
import { BlockButton } from '../buttons/BlockButton'
import { AsYouType } from 'libphonenumber-js'
import type { UserInformation } from '../../../models/UserInformation'
import { LocationSuggestBox } from '../bingmaps/LocationSuggestBox'

export interface UserInfoFormValues {
  id: number
  firstName: string
  lastName: string
  title: string
  email: string
  phoneNumber: string
  address: Address
}

interface Props {
  contact: UserInformation
  leaveEditMode: () => void
  handleSave: (form: UserInfoFormValues) => void
}

export const UserDetailsEdit = (props: Props): JSX.Element => {
  const emptyAddress = {
    id: 0,
    street: '',
    city: '',
    state: '',
    zip: '',
    apartment: undefined
  }
  const [editingAddress, setEditingAddress] = useState(props.contact.address ?? emptyAddress)

  const handlePlaceSelected = async (place: Microsoft.Maps.ISuggestionResult): Promise<void> => {
    const newplace: Address = { ...editingAddress }
    newplace.street = place.address.addressLine
    newplace.city = place.address.locality
    newplace.apartment = ''
    newplace.state = MapStateName(place.address.adminDistrict)
    newplace.zip = place.address.postalCode
    setEditingAddress(newplace)
    formValues.address = newplace
  }

  const [formValues, setFormValues] = useState<UserInfoFormValues>({
    id: props.contact.id,
    firstName: props.contact.firstName,
    lastName: props.contact.lastName,
    title: props.contact.title ?? '',
    email: props.contact.email,
    phoneNumber: props.contact.phoneNumber ?? '',
    address: props.contact.address ?? {
      id: 0,
      street: '',
      city: '',
      state: '',
      zip: ''
    }
  })

  const handleFieldUpdate = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    })
  }

  const handleLocChange = (e: any): void => {
    setEditingAddress({ ...editingAddress, street: e.target.value })
  }

  return <>
    <Box sx={{ display: 'flex' }}>
      <Grid container width='50%' spacing={2}>
        <Grid item>
          <TextFieldWithLabel
            name='firstName'
            label='First Name'
            value={formValues.firstName}
            dataTestId='firstName'
            className='pt-0'
            textFieldClassName='pb-0'
            onChange={handleFieldUpdate}
          />
        </Grid>
        <Grid item>
          <TextFieldWithLabel
            name='lastName'
            label='Last Name'
            value={formValues.lastName}
            dataTestId='lastName'
            className='pt-0'
            textFieldClassName='pb-0'
            onChange={handleFieldUpdate}
          />
        </Grid>
        <Grid item>
          <TextFieldWithLabel
            name='title'
            label='Title'
            value={formValues.title}
            dataTestId='title'
            className='pt-0'
            textFieldClassName='pb-0'
            onChange={handleFieldUpdate}
          />
        </Grid>
        <Grid item>
          <TextFieldWithLabel
            name='email'
            label='Email'
            value={formValues.email}
            dataTestId='email'
            className='pt-0'
            textFieldClassName='pb-0'
            onChange={handleFieldUpdate}
          />
        </Grid>
      </Grid>
      <Grid container width='50%' height='75%' spacing={2}>
        <Grid item>
          <LocationSuggestBox editData={editingAddress}
            onChange={handleLocChange}
            onPlaceSelected={handlePlaceSelected}
          />
          <InputLabel variant='standard' sx={{ marginLeft: '.875em' }}>
            { `${formValues.address.street}`}
          </InputLabel>
          <InputLabel variant='standard' sx={{ marginLeft: '.875em' }}>
            { `${formValues.address.city}, ${formValues.address.state} ${formValues.address.zip}`}
          </InputLabel>
        </Grid>
        <Grid item>
          <TextFieldWithLabel
            name='phoneNumber'
            label='Phone Number'
            value={new AsYouType('US').input(formValues.phoneNumber)}
            dataTestId='phoneNumber'
            className='pt-0'
            textFieldClassName='pb-0'
            onChange={handleFieldUpdate}
          />
        </Grid>
      </Grid>
    </Box>
    <Box sx={{ mt: '1.5em', display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      <BlockButton onClick={props.leaveEditMode} sx={{ height: '4em', width: '10em' }} dataTestId='cancelButton'>
        Cancel
      </BlockButton>
      <BlueButton onClick={() => { props.handleSave(formValues) }} sx={{ height: '4em', width: '10em' }} dataTestId='saveButton'>
        Save User
      </BlueButton>
    </Box>
  </>
}
