import { Box, CircularProgress, Typography } from '@mui/material'
import type { AnnualPlanTeamAndOptions } from '../../../models/AnnualPlanTeamAndOptions'
import { GetAnnualPlanTeamAndOptions, SaveAnnualPlanTeam } from '../../../services/AnnualPlanService'
import type { SelectableOption } from '../../../utils/genericTypes'
import { Modal } from '../../customComponents/Modal'
import { useEffect, useState } from 'react'
import MultiSelectWithChip from '../../customComponents/MultiSelectWithChip'
import type { AnnualPlanTeamMember } from '../../../models/AnnualPlanTeamMember'
import { AnnualPlanRole } from '../../../models/AnnualPlanRole'
import type { OrganizationOption } from '../../../models/OrganizationOption'
import Alert from '@mui/material/Alert'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import type { AnnualPlanTeamOption } from '../../../models/AnnualPlanTeamOptions'
import type { AnnualPlanTeamSaveRequest } from '../../../models/AnnualPlanTeamSaveRequest'

interface Props {
  closeModal: () => void
  onSubmit: () => Promise<void>
  annualPlanId: number
}

export const AnnualPlanningTeamModal = (props: Props): JSX.Element => {
  const [planAdministratorOptions, setPlanAdministratorOptions] = useState<AnnualPlanTeamOption[]>([])
  const [planContributorOptions, setPlanContributorOptions] = useState<AnnualPlanTeamOption[]>([])
  const [planContributorsWithOrg, setPlanContributorsWithOrg] = useState<AnnualPlanTeamOption[]>([])
  const [planReviewerOptions, setPlanReviewerOptions] = useState<AnnualPlanTeamOption[]>([])
  const [fiscalAgentOptions, setFiscalAgentOptions] = useState<AnnualPlanTeamOption[]>([])
  const [selectedPlanAdminstrators, setSelectedPlanAdminstrators] = useState<SelectableOption[]>([])
  const [selectedPlanContributors, setSelectedPlanContributors] = useState<SelectableOption[]>([])
  const [selectedPlanReviewers, setSelectedPlanReviewers] = useState<SelectableOption[]>([])
  const [selectedFiscalAgents, setSelectedFiscalAgents] = useState<SelectableOption[]>([])
  const [isdsForPlan, setIsdsForPlan] = useState<OrganizationOption[]>([])
  const [isdAlertMessage, setIsdAlertMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)
      const response: AnnualPlanTeamAndOptions = await GetAnnualPlanTeamAndOptions(props.annualPlanId)
      setPlanAdministratorOptions(response.allTeamOptions.planAdministrators)
      setPlanReviewerOptions(response.allTeamOptions.planReviewers)
      setPlanContributorsWithOrg(response.allTeamOptions.planContributors)
      setPlanContributorOptions(response.allTeamOptions.planContributors)
      setFiscalAgentOptions(response.allTeamOptions.fiscalAgents)
      setIsdsForPlan(response.associatedISDs)
      const tempSelectedPlanContributors = getMembersByRole(response.annualPlanTeamMembers, AnnualPlanRole.PlanContributor)
      setSelectedPlanAdminstrators(getMembersByRole(response.annualPlanTeamMembers, AnnualPlanRole.PlanAdministrator))
      setSelectedPlanContributors(tempSelectedPlanContributors)
      setSelectedPlanReviewers(getMembersByRole(response.annualPlanTeamMembers, AnnualPlanRole.PlanReviewer))
      setSelectedFiscalAgents(getMembersByRole(response.annualPlanTeamMembers, AnnualPlanRole.FiscalAgent))
      setIsdAlertMessage(getContributorInfoMessage(tempSelectedPlanContributors, response.allTeamOptions.planContributors, response.associatedISDs))
      setIsLoading(false)
    }

    void fetchData()
  }, [])

  const getMembersByRole = (team: AnnualPlanTeamMember[], roleName: AnnualPlanRole): SelectableOption[] => {
    return team.filter(member => member.annualPlanRole === roleName).map(member => { return { id: member.userId, name: `${member.firstName} ${member.lastName}` } })
  }

  const handleSubmit = async (): Promise<void> => {
    const saveRequest: AnnualPlanTeamSaveRequest = {
      annualPlanId: props.annualPlanId,
      planContributors: planContributorOptions.filter(pco => selectedPlanContributors.some(spco => spco.id === pco.id)),
      planAdministrators: planAdministratorOptions.filter(pao => selectedPlanAdminstrators.some(spao => spao.id === pao.id)),
      planReviewers: planReviewerOptions.filter(pro => selectedPlanReviewers.some(spro => spro.id === pro.id)),
      fiscalAgents: fiscalAgentOptions.filter(fa => selectedFiscalAgents.some(sfa => sfa.id === fa.id))
    }

    await SaveAnnualPlanTeam(saveRequest)

    await props.onSubmit()
  }

  const onPlanAdministratorsUpdated = (selectedPlanAdminstrators: SelectableOption[]): void => {
    setSelectedPlanAdminstrators(selectedPlanAdminstrators)
  }

  const getContributorInfoMessage = (selectedPlanContributors: SelectableOption[],
    contributorsWithOrg: AnnualPlanTeamOption[],
    isdsOnPlan: OrganizationOption[]): string => {
    let isdsMissing: string[] = []

    if (selectedPlanContributors.length > 0 && contributorsWithOrg.length > 0) {
      const orgIdsSelected = contributorsWithOrg.filter(pcw => selectedPlanContributors.some(item => item.id === pcw.id)).map(contributor => { return contributor.organizationId })
      isdsMissing = isdsOnPlan.filter(isd => !orgIdsSelected?.includes(isd.id)).map(isd => { return isd.name })
    } else {
      isdsMissing = isdsOnPlan.map(isd => { return isd.name })
    }

    let returnMessage = ''
    if (isdsMissing.length > 0) {
      returnMessage = `The following ISDs do not have a contributor assigned yet: ${isdsMissing.join(', ')}`
    }
    return returnMessage
  }

  const onPlanContributorsUpdated = (selectedPlanContributors: SelectableOption[]): void => {
    setSelectedPlanContributors(selectedPlanContributors)
    setIsdAlertMessage(getContributorInfoMessage(selectedPlanContributors, planContributorsWithOrg, isdsForPlan))
  }

  const onPlanReviewersUpdated = (selectedPlanReviewers: SelectableOption[]): void => {
    setSelectedPlanReviewers(selectedPlanReviewers)
  }

  const onFiscalAgentsUpdated = (selectedFiscalAgents: SelectableOption[]): void => {
    setSelectedFiscalAgents(selectedFiscalAgents)
  }

  return <Modal
    title='Set Annual Planning Team'
    open={true}
    data-testid='annualPlanningTeamModal'
    maxWidth='md'
    onClose={props.closeModal}
    onConfirm={handleSubmit}
    buttonClassName='modal-button'
    confirmButtonText='Save Changes'
    confirmButtonClassName='modal-confirm-button'
    cancelButtonText='Cancel'
    bodyContent={ isLoading
      ? <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box>
      : <>
        <Typography variant='h5' sx={{ my: '.75em' }} color='#021E40' fontWeight='600'>Plan Administrators</Typography>
        <Typography variant='body1' sx={{ mb: '1emm' }}>The plan administrators are the RCN team members who are responsible for facilitating the completion of the Contract / Plan.</Typography>
        <MultiSelectWithChip availableOptions={planAdministratorOptions}
          currentSelection={selectedPlanAdminstrators}
          name='planAdministrators'
          onUpdateSelection={onPlanAdministratorsUpdated}
          labelNoneSelected='Add Plan Administrator'
          labelWithSelected='Add Another Plan Administrator'
          placeholder='Select Plan Administrator'
          dataTestId='planadmin-select'
        ></MultiSelectWithChip>

        <Typography variant='h5' sx={{ my: '.75em' }} color='#021E40' fontWeight='600'>Plan Contributors</Typography>
        <Typography variant='body1' sx={{ mb: '1em' }}>Plan contributors are RCN / ISD team members who are filling out various parts of the plan.</Typography>
        {
          isdAlertMessage.length > 0 &&
            <Alert data-testid='contributorAlert' icon={<InfoOutlinedIcon fontSize="inherit" sx={{ fill: 'white' }} />} sx={{
              mb: '1em',
              backgroundColor: '#006699',
              color: 'white',
              fill: 'white'
            }} severity="info"
            >{isdAlertMessage}</Alert>
        }
        <MultiSelectWithChip availableOptions={planContributorOptions}
          currentSelection={selectedPlanContributors}
          name='planContributors'
          onUpdateSelection={onPlanContributorsUpdated}
          labelNoneSelected='Add Plan Contributor'
          labelWithSelected='Add Another Plan Contributor'
          placeholder='Select Plan Contributor'
          dataTestId='plancontributor-select'
        ></MultiSelectWithChip>

        <Typography variant='h5' sx={{ my: '.75em' }} color='#021E40' fontWeight='600'>Plan Reviewers</Typography>
        <Typography variant='body1' sx={{ mb: '1em' }}>The plan reviewers are members of the START project team who are responsible for final approvals.</Typography>
        <MultiSelectWithChip availableOptions={planReviewerOptions}
          currentSelection={selectedPlanReviewers}
          name='planReviewers'
          onUpdateSelection={onPlanReviewersUpdated}
          labelNoneSelected='Add Plan Reviewer'
          labelWithSelected='Add Another Plan Reviewer'
          placeholder='Select Plan Reviewer'
          dataTestId='planreviewer-select'
        ></MultiSelectWithChip>

        <Typography variant='h5' sx={{ my: '.75em' }} color='#021E40' fontWeight='600'>Fiscal Agents</Typography>
        <Typography variant='body1' sx={{ mb: '1em' }}>The fiscal agents are members of the organization that will facilitate the financial transactions related to the annual plan.</Typography>
        <MultiSelectWithChip availableOptions={fiscalAgentOptions}
          currentSelection={selectedFiscalAgents}
          name='fiscalAgents'
          onUpdateSelection={onFiscalAgentsUpdated}
          labelNoneSelected='Add Fiscal Agent'
          labelWithSelected='Add Another Fiscal Agent'
          placeholder='Select Fiscal Agent'
          dataTestId='fiscalAgent-select'
        ></MultiSelectWithChip>
      </>
    }
  />
}
