import { useEffect, useState } from 'react'
import { GetCETAResources } from '../../../services/CETAClassroomService'
import { type CETAResource } from '../../../models/CETAResource'
import { Box, CircularProgress, Typography } from '@mui/material'
import DataTable from '../../customComponents/dataTable/DataTable'
import { TranslateDate } from '../../../utils/dateUtils'
import { Column, ColumnSort, ColumnType } from '../../customComponents/dataTable/DataTable.model'
import { type PaginationProps, usePagination } from '../../../hooks/use-pagination'
import { Link } from 'react-router-dom'

const nameColumn = new Column('name', 'Name', ColumnType.CUSTOM)
nameColumn.customCellGenerator = (row: CETAResource) => {
  return <Link to={row.link} data-testid='resourceLink'>{row.name}</Link>
}

const lastUpdatedColumn = new Column('lastUpdated', 'Last Updated', ColumnType.CUSTOM)
lastUpdatedColumn.customCellGenerator = (row: CETAResource) => {
  return <Typography>{row.lastUpdated.toLocaleDateString()}</Typography>
}

const columns = [nameColumn, lastUpdatedColumn]

export const CETAResourceList = (): JSX.Element => {
  const [resources, setResources] = useState<CETAResource[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const paginationProps: PaginationProps<CETAResource> = {
    rows: resources
  }
  const pagination = usePagination(paginationProps)

  const fetchData = async (): Promise<void> => {
    const resources = await GetCETAResources()
    resources.forEach(resource => {
      resource.lastUpdated = TranslateDate(resource.lastUpdated)
    })
    setResources(resources)
    setIsLoading(false)
  }

  useEffect(() => {
    void fetchData()
  }, [])

  if (isLoading) {
    return <CircularProgress />
  }

  return <Box sx={{ ml: '2em' }}>
    <Typography variant='h5' fontWeight='600'>Resources</Typography>

    <Box sx={{ width: 'fit-content' }}>
      <DataTable
        name='resources'
        rows={pagination.internalRows}
        columns={columns}
        page={pagination.page}
        totalRecords={pagination.recordCount}
        loading={isLoading}
        initialColumnSorts={[new ColumnSort('name')]}
        onSortChange={(col, sorts) => { pagination.onSortChange(col, sorts[0].order) }}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
      />
    </Box>
  </Box>
}
