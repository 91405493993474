import { Breadcrumbs, CircularProgress, Grid, Link, Typography } from '@mui/material'
import { SummaryCard } from '../../customComponents/SummaryCard'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { type ImplementationCETA } from '../../../models/ImplementationSummary'
import { ArrowBack } from '@mui/icons-material'
import { FormatDateAsTimeAgo } from '../../../utils/dateUtils'
import { GetImplementationSummary } from '../../../services/ImplementationService'
import { AppRoutes } from '../../Routes'
import { generatePath } from 'react-router'

export const ImplementationInstance = (): JSX.Element => {
  const { timeSpanId } = useParams()
  const nav = useNavigate()

  const [ceta, setCeta] = useState<ImplementationCETA | null>(null)
  const [startYear, setStartYear] = useState<number | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (timeSpanId == null) {
        return
      }

      const implementationSummary = await GetImplementationSummary(timeSpanId)
      setCeta(implementationSummary.ceta)
      setStartYear(implementationSummary.startYear)
    }

    void fetchData()
  }, [timeSpanId])

  const goBack = (): void => {
    nav(AppRoutes.IMPLEMENTATION)
  }

  const goToCETA = (): void => {
    const route = generatePath(AppRoutes.CETA_CLASSROOMS, { timeSpanId: timeSpanId ?? '', classroomId: null })
    nav(route)
  }

  if (ceta == null || startYear == null) {
    return <CircularProgress />
  }

  return <>
    <Breadcrumbs maxItems={1} aria-label="breadcrumb" sx={{ mb: '.5em' }}>
      <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={goBack}>
        <ArrowBack sx={{ verticalAlign: 'middle', mr: '5px' }} />
        Back to Implementation
      </Link>
    </Breadcrumbs>

    <Typography variant='h3' className='page-header'>{startYear}/{startYear + 1} Implementation</Typography>

    <Grid container spacing={2} sx={{ mt: '2em' }}>
      <Grid item>
        <SummaryCard
          title='CETA'
          content={
            <ul style={{ paddingLeft: '1.5em' }}>
              <li>{ceta.classroomsCompleted} Completed</li>
              <li>{ceta.classroomsInProgress} In Progress</li>
            </ul>
          }
          footerContent={<>{ceta.lastUpdated != null && <Typography>{FormatDateAsTimeAgo(ceta.lastUpdated)}</Typography>}</>}
          dataTestId='cetaCard'
          onClick={goToCETA}
        />
      </Grid>
    </Grid>
  </>
}
