export interface Response {
  response: any
  errors: string[]
  success: boolean
  status: number
}

export interface FieldError {
  fieldName: string
  message: string
}

export async function sendPostFormData (request: string, data: FormData): Promise<Response> {
  const { response, success, errors, status } = await sendRequest(request, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json, application/xml, text/plain, text/html, *.*'
    },
    body: data
  })

  return { response, errors, success, status }
}

export async function sendPost (request: string, data: object): Promise<Response> {
  const { response, success, errors, status } = await sendRequest(request, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },

    body: JSON.stringify(data)
  })

  return { response, errors, success, status }
}

export async function sendGet (request: string): Promise<Response> {
  const { response, success, errors, status } = await sendRequest(request, {
    method: 'GET',
    credentials: 'include'
  })

  return { response, errors, success, status }
}

export async function sendPut (request: string, data: object): Promise<Response> {
  const { response, success, errors, status } = await sendRequest(request, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(data)
  })

  return { response, errors, success, status }
}

export async function sendDelete (request: string): Promise<Response> {
  const { response, success, errors, status } = await sendRequest(request, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'DELETE',
    credentials: 'include'
  })

  return { response, errors, success, status }
}

async function sendRequest (requestUrl: string, init?: RequestInit): Promise<Response> {
  const abortController = new AbortController()
  try {
    const url = (process.env.REACT_APP_BACKEND_URL ?? '') + '/' + requestUrl
    const response = await fetch(url, {
      signal: abortController.signal,
      ...init
    })
    let rsp = null
    // Ensure we don't try to parse an empty response
    if (response.status !== 204 && (response.headers.get('Content-Type')?.includes('application/json') ?? false)) {
      rsp = await response.json()
    }

    let success = response.ok
    let errors: string[] = []
    if (response.ok) {
      if (rsp?.errors != null && rsp.errors.length > 0) {
        errors = rsp.errors
        success = false
      }
    } else {
      if (rsp === null) {
        rsp = response
      } else {
        const errs = rsp?.errors
        const values = Object.keys(errs).map(k => errs[k][0])
        errors = values
      }
    }

    return { response: rsp, errors, success, status: response.status }
  } catch (e) {
    if (!isAbortError(e)) {
      const error: Error = e as Error
      return { response: null, errors: [error.toString()], success: false, status: 500 }
    }
  }

  return { response: null, errors: [], success: false, status: 500 }
}

function isAbortError (error: any): error is DOMException {
  if (error !== null && error.name === 'AbortError') {
    return true
  }
  return false
}
