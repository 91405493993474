import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Typography } from '@mui/material'
import { BlueButton } from '../../customComponents/buttons/BlueButton'
import { CETAInstructionsKey, type CETATemplateInstructions } from '../../../models/CETATemplateInstructions'
import { useState, useEffect } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import { GetTemplateInstructions } from '../../../services/CETAClassroomService'

interface Props {
  classroomId: string
  hasStarted: boolean
  readonly: boolean
  startClicked: () => void
}

export const CETAClassGettingStarted = (props: Props): JSX.Element => {
  const [instructions, setInstructions] = useState<CETATemplateInstructions | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const response = await GetTemplateInstructions(props.classroomId)
      setInstructions(response)
    }

    void fetchData()
  }, [])

  const startClick = (): void => {
    props.startClicked()
  }

  if (instructions == null) {
    return <CircularProgress />
  }

  return <Box>
    {(!props.hasStarted && !props.readonly) &&
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <BlueButton onClick={startClick} sx={{ px: '1.5em' }} dataTestId='getStartedButton'>
          Let's Get Started!
          <RocketLaunchIcon sx={{ verticalAlign: 'middle', ml: '.25em', width: '16px' }} />
        </BlueButton>
      </Box>
    }

    <Accordion sx={{ marginTop: '1em', marginBottom: '1em', width: '100%' }}>
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon data-testid='expandAboutTheCETA' />}>
        <Typography sx={{ fontSize: '1.25em', ml: '1em' }}>{CETAInstructionsKey.AboutTheCETA}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: instructions.aboutTheCETA }} data-testid='AboutTheCETA-text' />
      </AccordionDetails>
    </Accordion>

    <Accordion sx={{ marginTop: '1em', marginBottom: '1em', width: '100%' }}>
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon data-testid='expandCETABenefits' />}>
        <Typography sx={{ fontSize: '1.25em', ml: '1em' }}>{CETAInstructionsKey.CETABenefits}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: instructions.cetaBenefits }} data-testid='CETABenefits-text' />
      </AccordionDetails>
    </Accordion>

    <Accordion sx={{ marginTop: '1em', marginBottom: '1em', width: '100%' }}>
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon data-testid='expandRecommendedCollabForCETA' />}>
        <Typography sx={{ fontSize: '1.25em', ml: '1em' }}>{CETAInstructionsKey.RecommendedCollabForCETA}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: instructions.recommendedCollabForCETA }} data-testid='RecommendedCollabForCETA-text' />
      </AccordionDetails>
    </Accordion>

    <Accordion sx={{ marginTop: '1em', marginBottom: '1em', width: '100%' }}>
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon data-testid='ImplementingCETA' />}>
        <Typography sx={{ fontSize: '1.25em', ml: '1em' }}>{CETAInstructionsKey.ImplementingCETA}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: instructions.implementingCETA }} data-testid='ImplementingCETA-text' />
      </AccordionDetails>
    </Accordion>
  </Box>
}
