import './App.css'
import { Layout } from './masterComponents/layout'
import { Route, Routes } from 'react-router-dom'
import { Planning } from './pageComponents/planning/Planning'
import { Implementation } from './pageComponents/implementation/Implementation'
// import { Resources } from './Resources'
// import { Reports } from './Reports'
import { AuthProvider } from '../contextProviders/AuthProvider'
import { HomePage } from './HomePage'
import { UserProfile } from './pageComponents/userProfile/UserProfile'
import { ProtectedRoute } from './masterComponents/ProtectedRoute'
import { OrganizationDetails } from './pageComponents/organizations/OrganizationDetails'
import { AuthFunctions, hasPermission } from '../utils/permissions'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './ToastifyOverrides.css'
import { AppRoutes } from './Routes'
import { Administration } from './pageComponents/organizations/Administration'
import { BreadcrumbProvider } from '../contextProviders/BreadcrumbProvider'
import { CETAClassroomList } from './pageComponents/CETAs/CETAClassroomList'
import { CETAOverview } from './pageComponents/CETAs/CETAOverview'
import { CETASnapshot } from './pageComponents/CETAs/CETASnapshot'
import { CETAReconcile } from './pageComponents/CETAs/CETAReconcile'
import { ImplementationInstance } from './pageComponents/implementation/ImplementationInstance'
import { CETAPlanSectionPrint } from './pageComponents/CETAs/Plan/CETAPlanSectionPrint'
import { PlanningInstance } from './pageComponents/planning/PlanningInstance'
import { PlanningDetails } from './pageComponents/planning/PlanningDetails'

function App (): JSX.Element {
  return (
    <AuthProvider>
      <Layout>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          closeOnClick
          draggable={false}
          theme="colored"
        />
        <BreadcrumbProvider>
          <Routes>
            <Route
              path={AppRoutes.HOME}
              element={<HomePage />}
            />

            <Route element={
              <ProtectedRoute allow={(user) => !user.isOrgOnboarding} />}
            >

              <Route element={
                <ProtectedRoute allow={(user) =>
                  hasPermission(user, AuthFunctions.ANNUAL_PLANNING_ADMINISTRATION_EDIT) ||
                  hasPermission(user, AuthFunctions.ANNUAL_PLANNING_REVIEW) ||
                  hasPermission(user, AuthFunctions.ANNUAL_PLANNING_REVIEW_EDIT)}
                />
              }
              >
                <Route
                  path={AppRoutes.PLANNING}
                  element={<Planning />}
                />

                <Route
                  path={AppRoutes.PLANNING_INSTANCE}
                  element={<PlanningInstance />}
                />

                <Route
                  path={AppRoutes.PLANNING_DETAILS}
                  element={<PlanningDetails />}
                />

              </Route>

              <Route element={
                <ProtectedRoute allow={(user) =>
                  hasPermission(user, AuthFunctions.CETA_PARTICIPATION)}
                />}
              >
                <Route
                  path={AppRoutes.IMPLEMENTATION}
                  element={<Implementation />}
                />

                <Route
                  path={AppRoutes.IMPLEMENTATION_INSTANCE}
                  element={<ImplementationInstance />}
                />
              </Route>

              {/* <Route
                path={AppRoutes.RESOURCES}
                element={<Resources />}
              /> */}

              {/* <Route element={
                <ProtectedRoute allow={(user) =>
                  hasPermission(user, AuthFunctions.REPORTS)}
                />}
              >
                <Route
                  path={AppRoutes.REPORTS}
                  element={<Reports />}
                />
              </Route> */}

              <Route element={
                <ProtectedRoute allow={(user) =>
                  hasPermission(user, AuthFunctions.CETA_PARTICIPATION)}
                />}
              >
                <Route
                  path={AppRoutes.CETA_CLASSROOMS}
                  element={<CETAClassroomList />}
                />

                <Route
                  path={AppRoutes.CETA_OVERVIEW}
                  element={<CETAOverview />}
                />

                <Route
                  path={AppRoutes.CETA_SECTION_PRINT}
                  element={<CETAPlanSectionPrint />}
                />

                <Route
                  path={AppRoutes.CETA_SNAPSHOT}
                  element={<CETASnapshot />}
                />

                <Route
                  path={AppRoutes.CETA_RECONCILE}
                  element={<CETAReconcile />}
                />
              </Route>
            </Route>

            <Route element={
              <ProtectedRoute allow={(user) =>
                hasPermission(user, AuthFunctions.PROJECT_SETTINGS) ||
                hasPermission(user, AuthFunctions.RCN_MANAGEMENT) ||
                hasPermission(user, AuthFunctions.ISD_MANAGEMENT) ||
                hasPermission(user, AuthFunctions.DISTRICT_MANAGEMENT) ||
                hasPermission(user, AuthFunctions.RCN_ADMINISTRATION) ||
                hasPermission(user, AuthFunctions.ISD_ADMINISTRATION) ||
                hasPermission(user, AuthFunctions.DISTRICT_ADMINISTRATION)}
              />}
            >
              <Route
                path={AppRoutes.ADMINISTRATION}
                element={<Administration />}
              />
              <Route
                path={AppRoutes.ORGANIZATION_DETAILS}
                element={<OrganizationDetails />}
              />
              <Route
                path={AppRoutes.USER_DETAILS}
                element={<UserProfile />}
              />
            </Route>
          </Routes>
        </BreadcrumbProvider>
      </Layout>
    </AuthProvider>
  )
}

export default App
