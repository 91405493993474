import { type AnnualPlanTeamMember } from '../../../models/AnnualPlanTeamMember'
import { Box, Card, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ProfileImage } from '../../customComponents/user/ProfileImage'
import { LabelWithToolTip } from '../../customComponents/LabelWithTooltip'
import { AnnualPlanRole } from '../../../models/AnnualPlanRole'
import { AnnualPlanningTeamModal } from './AnnualPlanningTeamModal'
import { EditOutlined } from '@mui/icons-material'

interface Props {
  annualPlanId: number
  readonly: boolean
  team: AnnualPlanTeamMember[]
  onTeamChange: () => Promise<void>
}

export const AnnualPlanTeamCard = (props: Props): JSX.Element => {
  const purpleBackground: string = '#9C27B0'
  const blueBackground: string = '#21AEDE'
  const greenBackground: string = '#82DA53'
  const pinkBackground: string = '#DB7399'
  const [planAdministrators, setPlanAdministrators] = useState<AnnualPlanTeamMember[]>([])
  const [planContributors, setPlanContributors] = useState<AnnualPlanTeamMember[]>([])
  const [planReviewers, setPlanReviewers] = useState<AnnualPlanTeamMember[]>([])
  const [fiscalAgents, setFiscalAgents] = useState<AnnualPlanTeamMember[]>([])
  const [showEditModal, setShowEditModal] = useState(false)

  const setData = async (): Promise<void> => {
    const team = props.team
    const admins: AnnualPlanTeamMember[] = []
    const contributors: AnnualPlanTeamMember[] = []
    const reviewers: AnnualPlanTeamMember[] = []
    const agents: AnnualPlanTeamMember[] = []

    team.forEach(tm => {
      if (tm.annualPlanRole === AnnualPlanRole.PlanAdministrator) {
        admins.push(tm)
      } else if (tm.annualPlanRole === AnnualPlanRole.PlanContributor) {
        contributors.push(tm)
      } else if (tm.annualPlanRole === AnnualPlanRole.PlanReviewer) {
        reviewers.push(tm)
      } else {
        agents.push(tm)
      }
    })

    setPlanAdministrators(admins)
    setPlanContributors(contributors)
    setPlanReviewers(reviewers)
    setFiscalAgents(agents)
  }

  useEffect(() => {
    void setData()
  }, [props.annualPlanId, props.team])

  const closeModal = (): void => {
    setShowEditModal(false)
  }

  const teamSubmit = async (): Promise<void> => {
    await props.onTeamChange()
    setShowEditModal(false)
  }

  return <>
    <Card key='annualPlanCard' sx={{ backgroundColor: '#F3F3F3', minHeight: '3em', border: '1px solid #CBC8CC', p: '1em' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='h5' color='#021E40' fontWeight='600'>Planning Team</Typography>
        {
          !props.readonly && <EditOutlined data-testid='edit-team' sx={{ fill: 'black', cursor: 'pointer', ml: '.5em' }} onClick={() => { setShowEditModal(true) }} />
        }
      </Box>
      { planAdministrators.length > 0 &&
        <>
          <Box sx={{ display: 'grid', justifyContent: 'center', mt: '1em' }}>
            <Typography variant='body1' color='#021E40' fontWeight='600' fontSize='1em'>Plan Administrators</Typography>
          </Box>
          <Box data-testid='adminwrapper'>
            { planAdministrators.map((member) => (
              <Box key={`${member.userId}admin-labelcontainer`} sx={{ display: 'flex', alignItems: 'center', my: '1em', ml: '15%', width: '75%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }} data-testid={`user${member.userId}admin-chip`}>
                  <ProfileImage
                    name={`${member.firstName}${member.lastName}image`}
                    imageKey={member.imageKey}
                    backgroundColor={purpleBackground}
                  />
                  <LabelWithToolTip
                    data-testid={`${member.userId}admin--memberlabel`}
                    sx={{ marginLeft: '.5em' }}
                    labelText={`${member.firstName} ${member.lastName} (${member.organizationName})`}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </>
      }
      { planContributors.length > 0 &&
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: '1em' }}>
            <Typography variant='body1' color='#021E40' fontWeight='600' fontSize='1em'>Plan Contributors</Typography>
          </Box>
          <Box data-testid='contributorwrapper'>
            { planContributors.map((member) => (
              <Box key={`${member.userId}contributor-labelcontainer`} sx={{ display: 'flex', alignItems: 'center', my: '1em', ml: '15%', width: '75%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }} data-testid={`user${member.userId}contributor-chip`}>
                  <ProfileImage
                    name={`${member.firstName}${member.lastName}image`}
                    imageKey={member.imageKey}
                    backgroundColor={blueBackground}
                  />
                  <LabelWithToolTip
                    data-testid={`${member.userId}contributor--memberlabel`}
                    sx={{ marginLeft: '.5em' }}
                    labelText={`${member.firstName} ${member.lastName} (${member.organizationName})`}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </>
      }
      { planReviewers.length > 0 &&
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: '1em' }}>
            <Typography variant='body1' color='#021E40' fontWeight='600' fontSize='1em'>Plan Reviewers</Typography>
          </Box>
          <Box data-testid='reviewerwrapper'>
            { planReviewers.map((member) => (
              <Box key={`${member.userId}reviewer-labelcontainer`} sx={{ display: 'flex', alignItems: 'center', my: '1em', ml: '15%', width: '75%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }} data-testid={`user${member.userId}reviewer-chip`}>
                  <ProfileImage
                    name={`${member.firstName}${member.lastName}image`}
                    imageKey={member.imageKey}
                    backgroundColor={greenBackground}
                  />
                  <LabelWithToolTip
                    data-testid={`${member.userId}reviewer--memberlabel`}
                    sx={{ marginLeft: '.5em' }}
                    labelText={`${member.firstName} ${member.lastName} (${member.organizationName})`}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </>
      }
      { fiscalAgents.length > 0 &&
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: '1em' }}>
            <Typography variant='body1' color='#021E40' fontWeight='600' fontSize='1em'>Fiscal Agents</Typography>
          </Box>
          <Box data-testid='agentwrapper'>
            { fiscalAgents.map((member) => (
              <Box key={`${member.userId}agent-labelcontainer`} sx={{ display: 'flex', alignItems: 'center', my: '1em', ml: '15%', width: '75%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }} data-testid={`user${member.userId}agent-chip`}>
                  <ProfileImage
                    name={`${member.firstName}${member.lastName}image`}
                    imageKey={member.imageKey}
                    backgroundColor={pinkBackground}
                  />
                  <LabelWithToolTip
                    data-testid={`${member.userId}agent--memberlabel`}
                    sx={{ marginLeft: '.5em' }}
                    labelText={`${member.firstName} ${member.lastName} (${member.organizationName})`}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </>
      }
      { showEditModal &&
        <AnnualPlanningTeamModal closeModal={closeModal} onSubmit={teamSubmit} key='PlanTeamModal' annualPlanId={props.annualPlanId}></AnnualPlanningTeamModal>
      }
    </Card>
  </>
}
