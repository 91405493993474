import { useState } from 'react'
import { DatePickerWithLabel } from '../../../customComponents/DatePickerWithLabel'
import { Modal } from '../../../customComponents/Modal'
import { TextArea } from '../../../customComponents/TextArea'
import { Help } from '@mui/icons-material'
import { Box, Tooltip } from '@mui/material'
import { SaveProgressNote } from '../../../../services/CETAClassroomService'
import { toast } from 'react-toastify'
import { type FieldError } from '../../../../hooks/use-fetch'
import { type CETAGoalNote } from '../../../../models/CETAPlanSectionModel'

interface Props {
  note: CETAGoalNote | null
  goalId: number
  onClose: () => void
  onSave?: () => void
}

export const ProgressNoteModal = (props: Props): JSX.Element => {
  const [progressNote, setProgressNote] = useState(props.note?.note ?? '')
  const [progressNoteDate, setProgressNoteDate] = useState<Date | null>(props.note?.updateDate ?? new Date())
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([])

  const handleConfirm = async (): Promise<void> => {
    const { errors, response, success } = await SaveProgressNote(props.note?.id ?? 0, props.goalId, progressNote, progressNoteDate)
    if (!success) {
      toast.error(errors[0])
      setFieldErrors(response.fieldErrors)
      return
    }

    toast.success('Progress note saved')
    props.onClose()

    if (props.onSave != null) {
      props.onSave()
    }
  }

  return <Modal
    open={true}
    title='Progress Note'
    confirmButtonText={props.note == null ? 'Add Progress Note' : 'Save Progress Note'}
    onClose={props.onClose}
    onConfirm={handleConfirm}
    bodyContent={
      <>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DatePickerWithLabel
            name='progressNoteDate'
            label='Progress Note Date'
            dataTestId='progressNoteDate'
            value={progressNoteDate}
            required={fieldErrors.some((error) => error.fieldName === 'progressNoteDate')}
            error={fieldErrors.some((error) => error.fieldName === 'progressNoteDate')}
            showRequiredText={fieldErrors.some((error) => error.fieldName === 'progressNoteDate')}
            onChange={setProgressNoteDate}
          />

          <Tooltip title='The date on which the note was recorded'>
            <Help sx={{ color: '#59555B', width: '.7em', height: '.7em', ml: '.5em' }} />
          </Tooltip>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextArea
            label='Progress Note'
            name='progressNote'
            initialValue={progressNote}
            required={fieldErrors.some((error) => error.fieldName === 'progressNote')}
            error={fieldErrors.some((error) => error.fieldName === 'progressNote')}
            showRequiredText={fieldErrors.some((error) => error.fieldName === 'progressNote')}
            sx={{ mt: '1em', width: '100%' }}
            onChange={(e) => { setProgressNote(e.target.value) }}
          />

          <Tooltip title='A summary of the progress that has been made towards the goal'>
            <Help sx={{ color: '#59555B', width: '.7em', height: '.7em', ml: '.5em' }} />
          </Tooltip>
        </Box>
      </>
    }
  />
}
