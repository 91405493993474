import { ArrowBack } from '@mui/icons-material'
import { Box, Breadcrumbs, Link } from '@mui/material'
import { useContext } from 'react'
import { useNavigate } from 'react-router'
import { BreadcrumbContext, SetBreadcrumbContext } from '../../contextProviders/BreadcrumbProvider'

export interface BreadcrumbInfo {
  name: string
  route: string
}

export const BreadcrumbNav = (): JSX.Element => {
  const nav = useNavigate()
  const breadcrumbs = useContext(BreadcrumbContext)
  const setBreadcrumbs = useContext(SetBreadcrumbContext)

  const goToPage = (b: BreadcrumbInfo, index: number): void => {
    const newBreadcrumbs = breadcrumbs.slice(0, index)
    setBreadcrumbs(newBreadcrumbs)

    nav(b.route)
  }

  return <Box sx={{ display: 'flex', mb: '.5em' }} data-testid='breadcrumbs'>
    {breadcrumbs.length > 0 &&
      <ArrowBack
        onClick={() => { goToPage(breadcrumbs[breadcrumbs.length - 1], -1) }}
        sx={{ verticalAlign: 'middle', mr: '5px', cursor: 'pointer' }}
        data-testid='backButton'
      />
    }

    <Breadcrumbs>
      {breadcrumbs.map((b, index) => {
        return <Link
          key={index}
          sx={{ cursor: 'pointer' }}
          underline="hover"
          color="inherit"
          onClick={() => { goToPage(b, index) }}
          data-testid={b.name}
        >
          {b.name}
        </Link>
      })}
    </Breadcrumbs>
  </Box>
}
