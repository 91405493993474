import { type SyntheticEvent, useState, useCallback } from 'react'
import { type SelectOption } from '../../customComponents/SelectWithLabel'
import { ActiveStatus, OrganizationStatus, OrganizationType } from '../../../utils/genericTypes'
import { Box, Grid, TextField, debounce } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import MUIAutoComplete from '../../customComponents/MUIAutoComplete'
import { BlueButton } from '../../customComponents/buttons/BlueButton'
import AddCircleIcon from '@mui/icons-material/AddCircle'

export interface OrgSearchValues {
  parentOrgId?: number
  parentOrgType?: string
  status: OrganizationStatus | null
  name: string
}

interface Props {
  parentOrgId: number
  parentOrgType: string
  orgLabel?: string
  canAddOrg: boolean
  handleAddNewOrg?: () => void
  handleAddNewBuilding?: () => void
  filtersUpdated: (searchValues: OrgSearchValues) => void
}

export const OrganizationSearch = (props: Props): JSX.Element => {
  const statusOptions: Array<SelectOption<string>> = [{ id: '', name: 'All' }]
  if (props.parentOrgType === OrganizationType.District) {
    Object.values(ActiveStatus).forEach((key) => {
      statusOptions.push({ id: key, name: key })
    })
  } else {
    Object.values(OrganizationStatus).forEach((key) => {
      statusOptions.push({ id: key, name: key })
    })
  }

  const [selectedStatus, setSelectedStatus] = useState<OrganizationStatus | null>(null)
  const [selectedName, setSelectedName] = useState<string>('')

  const onStatusChange = (e: SyntheticEvent, value: { id: string | number, name: string } | null): void => {
    setSelectedStatus(value?.id as OrganizationStatus)
    props.filtersUpdated({ status: value?.id as OrganizationStatus, name: selectedName })
  }

  const onNameChange = (e: any): void => {
    setSelectedName(e.target.value)
    debouncedNameUpdate(e.target.value)
  }

  const debouncedNameUpdate = useCallback(
    debounce(name => { handleNameUpdate(name) }, 500),
    [props.parentOrgId, selectedStatus]
  )

  const handleNameUpdate = (name: any): void => {
    props.filtersUpdated({ status: selectedStatus, name })
  }

  return <Box>
    <Grid container spacing={1} rowSpacing={-1} justifyContent='space-between'>
      <Grid item>
        <MUIAutoComplete
          label='Status'
          name='status'
          value={selectedStatus != null ? { id: selectedStatus, name: selectedStatus } : undefined}
          onChange={onStatusChange}
          options={statusOptions}
          sx={{ width: '200px' }}
        />
      </Grid>

      <Grid container item justifyContent='flex-end' width='fit-content'>
        <Grid item>
          <Box sx={{ position: 'relative' }}>
            <TextField
              label={`${props.orgLabel ?? 'Organization'} Name`}
              name='name'
              onChange={onNameChange}
              value={selectedName}
              data-testid='name'
            />
            <SearchIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '0.5em' }} />
          </Box>
        </Grid>

        {props.handleAddNewOrg != null && props.canAddOrg &&
          <Grid item>
            <BlueButton onClick={props.handleAddNewOrg} sx={{ height: '4em', ml: '1em' }} dataTestId='addOrg'>
              Add New {props.orgLabel ?? 'Organization'}
              <AddCircleIcon sx={{ ml: '0.5em' }} />
            </BlueButton>
          </Grid>
        }

        {props.handleAddNewBuilding != null &&
          <Grid item>
            <BlueButton onClick={props.handleAddNewBuilding} sx={{ height: '4em', ml: '1em' }} dataTestId='addBuilding'>
              Add New Building
              <AddCircleIcon sx={{ ml: '0.5em' }} />
            </BlueButton>
          </Grid>
        }
      </Grid>
    </Grid>
  </Box>
}
