import { sendGet, sendPost } from '../hooks/use-fetch'
import { type UserPermission } from '../utils/genericTypes'

export async function GetPermissionsForRole (roleName: string): Promise<UserPermission[]> {
  const { response: permissions } = await sendGet(`User/UserPermissionsByRole?userSecurityLevel=${roleName}`)
  return permissions
}

export async function GetUserFunctionsForOrg (userId: number, orgId: number): Promise<string[]> {
  const { response: permissions } = await sendGet(`User/UserFunctionsByOrganization?userId=${userId}&organizationId=${orgId}`)
  return permissions
}

export async function SaveUserFunctions (userId: number, orgId: number, securityLevel: string, functions: string[]): Promise<void> {
  await sendPost('User/SaveUserFunctions', { userId, organizationId: orgId, securityLevel, functions })
}

export async function RemoveAccessToOrganization (userId: string, orgId: number): Promise<void> {
  await sendPost('User/RemoveAccessToOrganization', { userId, organizationId: orgId })
}
