import { type SxProps } from '@mui/material'
import { BlockButton } from './BlockButton'

interface Props {
  disabled?: boolean
  sx?: SxProps
  dataTestId?: string
  children: React.ReactNode
  onClick: () => void
}

export const BlueButton = (props: Props): JSX.Element => {
  return <BlockButton
    disabled={props.disabled}
    onClick={props.onClick}
    dataTestId={props.dataTestId ?? 'block-button'}
    color='white'
    hover={{
      background: 'linear-gradient(96.34deg, #01142b 0%, #005079 100%)',
      color: 'white'
    }}
    sx={{
      ...props.sx,
      background: 'linear-gradient(96.34deg, #021e40 0%, #006699 100%)'
    }}
    disabledSx={{ color: '#dddddd' }}
  >
    {props.children}
  </BlockButton>
}
