import { Box, Breadcrumbs, CircularProgress, Link, Tab, Tabs, Typography } from '@mui/material'
import { generatePath, useNavigate, useParams } from 'react-router'
import { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { AppRoutes } from '../../Routes'
import { TabDisplay } from '@oaisd/michdev.components.react'
import type { AnnualPlanNameAndOrgName } from '../../../models/AnnualPlanNameAndOrgName'
import { GetAnnualPlanAndOrgName } from '../../../services/AnnualPlanService'
import LockIcon from '@mui/icons-material/Lock'
import { AnnualPlanDashboard } from './AnnualPlanDashboard'

export class AnnualPlanTabs {
  static readonly GettingStarted = '0'
  static readonly Dashboard = '1'
  static readonly MeetingSchedules = '2'
  static readonly PriorityActivities = '3'
  static readonly MiscExpenses = '4'
  static readonly BudgetOverview = '5'
  static readonly FinalizePlan = '6'
}

export function PlanningDetails (): JSX.Element {
  const { rcnId, planID } = useParams()
  const nav = useNavigate()
  const [planName, setPlanName] = useState('')
  const [organizationName, setOrganizationName] = useState('')
  const [selectedTab, setSelectedTab] = useState<number>(Number(AnnualPlanTabs.GettingStarted))
  const [hasStarted, setHasStarted] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)

      const response: AnnualPlanNameAndOrgName = await GetAnnualPlanAndOrgName(planID ?? '', rcnId ?? '')
      setPlanName(response.annualPlanName)
      setOrganizationName(response.organizationName)
      setHasStarted(true)
      setIsLoading(false)
    }

    void fetchData()
  }, [])

  const goBack = (): void => {
    const route = generatePath(AppRoutes.PLANNING, {})
    nav(route)
  }

  const updateTab = (tab: number): void => {
    setSelectedTab(tab)
  }

  const getLockedLabel = (tabName: string): JSX.Element => {
    return <Box sx={{ display: 'flex' }}><>{tabName}<LockIcon sx={{ marginLeft: '.5em', fontSize: '.875rem' }}/></></Box>
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return <>
    <Breadcrumbs maxItems={1} aria-label="breadcrumb" sx={{ mb: '.5em' }}>
      <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={goBack} data-testid='backToPlanning'>
        <ArrowBackIcon sx={{ verticalAlign: 'middle', mr: '5px' }} />
        Back to Planning
      </Link>
    </Breadcrumbs>

    <Typography variant='h3' className='page-header'>{planName}</Typography>
    <Typography variant='h4' className='page-subheader' data-testid='subPageHeader' mt='.5em'>{organizationName}</Typography>

    <Tabs value={selectedTab} onChange={(e, val) => { updateTab(val) }} sx={{ mb: '2em', mt: '1em' }}>
      <Tab label='Getting Started' value={0} sx={{ textTransform: 'none' }} data-testid='gettingStartedTab' />
      <Tab disabled={!hasStarted} label={hasStarted ? 'Dashboard' : getLockedLabel('Dashboard')} value={1} sx={{ textTransform: 'none' }} data-testid='dashboardTab' />
      <Tab disabled={!hasStarted} label={hasStarted ? 'Meeting Schedules' : getLockedLabel('Meeting Schedules')} value={2} sx={{ textTransform: 'none' }} data-testid='meetinTab' />
      <Tab disabled={!hasStarted} label={hasStarted ? 'Priority Activities' : getLockedLabel('Priority Activities')} value={3} sx={{ textTransform: 'none' }} data-testid='activitiesTab' />
      <Tab disabled={!hasStarted} label={hasStarted ? 'Misc Expenses' : getLockedLabel('Misc Expenses')} value={4} sx={{ textTransform: 'none' }} data-testid='expensesTab' />
      <Tab disabled={!hasStarted} label={hasStarted ? 'Budget Overview' : getLockedLabel('Budget Overview')} value={5} sx={{ textTransform: 'none' }} data-testid='budgetTab' />
      <Tab disabled={!hasStarted} label={hasStarted ? 'Finalize Plan' : getLockedLabel('Finalize Plan')} value={6} sx={{ textTransform: 'none' }} data-testid='finalizeTab' />
    </Tabs>

    <TabDisplay index={0} value={selectedTab}>
      {hasStarted &&
        <></>
      }
    </TabDisplay>
    <TabDisplay index={1} value={selectedTab}>
      {hasStarted &&
        <AnnualPlanDashboard annualPlanId={Number(planID)} rcnId={Number(rcnId)}></AnnualPlanDashboard>
      }
    </TabDisplay>
    <TabDisplay index={2} value={selectedTab}>
      {hasStarted &&
        <></>
      }
    </TabDisplay>
    <TabDisplay index={3} value={selectedTab}>
      {hasStarted &&
        <></>
      }
    </TabDisplay>
    <TabDisplay index={4} value={selectedTab}>
      {hasStarted &&
        <></>
      }
    </TabDisplay>
    <TabDisplay index={5} value={selectedTab}>
      {hasStarted &&
        <></>
      }
    </TabDisplay>
    <TabDisplay index={6} value={selectedTab}>
      {hasStarted &&
        <></>
      }
    </TabDisplay>
  </>
}
