import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CircularProgress, Grid, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { type CETAGoal, type CETAPlanIndicator, type CETAPlanSectionModel } from '../../../../models/CETAPlanSectionModel'
import { FinalizeSectionPlan, GetCETAPlanSection, GetTemplateInstructions, LockIndicatorGoal, LockSectionGoal, SaveCETAPlanGoal, UnlockEntireSection, UnlockIndicatorGoal, UnlockSectionGoal } from '../../../../services/CETAClassroomService'
import { AddCircle, AddCircleOutline, CheckCircle, CheckCircleOutline, Edit, ErrorOutline, Help, KeyboardArrowDown, Lock, PrintOutlined } from '@mui/icons-material'
import { RatingRadio } from '../../../customComponents/RatingRadio'
import { BlueButton } from '../../../customComponents/buttons/BlueButton'
import { CETAGoalDetails } from './CETAGoalDetails'
import { IconWithLabel } from '../../../customComponents/IconWithLabel'
import { EditCETAGoalModal } from './EditCETAGoalModal'
import { toast } from 'react-toastify'
import { Modal } from '../../../customComponents/Modal'
import { useAuth } from '../../../../hooks/use-auth'
import { type CETAGoalLock } from '../../../../models/CETAGoalLock'
import { CETAInstructionsKey } from '../../../../models/CETATemplateInstructions'
import { TranslateDate } from '../../../../utils/dateUtils'
import { TranslateSectionDates } from '../../../../utils/cetaUtils'
import { generatePath, useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../Routes'
import { CETAOverviewTabs } from '../CETAOverview'

interface Props {
  readOnly: boolean
  classroomId: string
  sectionId: number
  goBack: () => void
  onFinalize: () => void
  refetchOverviewSections: () => void
}

export const CETAPlanSection = (props: Props): JSX.Element => {
  const { user } = useAuth()
  const nav = useNavigate()

  const [section, setSection] = useState<CETAPlanSectionModel | null>(null)
  const [planningInstructions, setPlanningInstructions] = useState('')
  const [isEditingSectionGoal, setIsEditingSectionGoal] = useState(false)
  const [isEditingIndicatorGoal, setIsEditingIndicatorGoal] = useState(false)
  const [selectedIndicator, setSelectedIndicator] = useState<CETAPlanIndicator | null>(null)
  const [isFinalizing, setIsFinalizing] = useState(false)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const results = await Promise.all([
        GetTemplateInstructions(props.classroomId),
        getSection(),
        UnlockEntireSection(props.sectionId)
      ])

      setPlanningInstructions(results[0].planning)
    }

    void fetchData()
  }, [props.sectionId])

  const getSection = async (): Promise<void> => {
    const sectionResponse = await GetCETAPlanSection(props.sectionId)
    setSection(TranslateSectionDates(sectionResponse))
  }

  const handleUpdateSectionGoal = async (): Promise<void> => {
    if (section == null) {
      return
    }

    const existingLock = await LockSectionGoal(props.sectionId)
    if (existingLock != null) {
      toast.error(`This goal is currently being edited by ${existingLock.lockedBy.fullName}.`)
      existingLock.lockedUntil = TranslateDate(existingLock.lockedUntil)
      const tmpSection = { ...section }
      tmpSection.goalLock = existingLock
      setSection(tmpSection)
      return
    }

    setIsEditingSectionGoal(true)
  }

  const handleUpdateIndicatorGoal = async (indicator: CETAPlanIndicator): Promise<void> => {
    if (section == null) {
      return
    }

    const existingLock = await LockIndicatorGoal(indicator.id)
    if (existingLock != null) {
      toast.error(`This goal is currently being edited by ${existingLock.lockedBy.fullName}.`)

      existingLock.lockedUntil = TranslateDate(existingLock.lockedUntil)
      const tmpSection = { ...section }
      const tmpIndicator = tmpSection.indicators?.find(i => i.id === indicator.id)
      if (tmpIndicator != null) {
        tmpIndicator.goalLock = existingLock
        setSection(tmpSection)
      }

      return
    }

    setSelectedIndicator(indicator)
    setIsEditingIndicatorGoal(true)
  }

  const handleUpdateIndicatorGoalConfirm = async (goal: CETAGoal): Promise<void> => {
    const request = {
      indicatorId: selectedIndicator?.id,
      goal
    }
    await SaveCETAPlanGoal(request)
    if (goal.id == null) {
      toast.success('Excellent! You have just created an Indicator Goal!')
      props.refetchOverviewSections()
    } else {
      toast.success('The edits have been made to your Indicator Goal.')
    }

    if (section == null) {
      return
    }

    await UnlockIndicatorGoal(selectedIndicator?.id ?? 0)
    await getSection()
    setIsEditingIndicatorGoal(false)
  }

  const handleUpdateSectionGoalConfirm = async (goal: CETAGoal): Promise<void> => {
    const request = {
      sectionId: props.sectionId,
      goal
    }
    await SaveCETAPlanGoal(request)
    if (goal.id == null) {
      toast.success('Excellent! You have just created a Section Goal!')
      props.refetchOverviewSections()
    } else {
      toast.success('The edits have been made to your Section Goal.')
    }

    if (section == null) {
      return
    }

    await UnlockSectionGoal(props.sectionId)
    await getSection()
    setIsEditingSectionGoal(false)
  }

  const handleCancelIndicatorGoal = async (): Promise<void> => {
    if (selectedIndicator == null) {
      return
    }

    await UnlockIndicatorGoal(selectedIndicator.id)
    setIsEditingIndicatorGoal(false)
  }

  const handleCancelSectionGoal = async (): Promise<void> => {
    if (section == null) {
      return
    }

    await UnlockSectionGoal(props.sectionId)
    setIsEditingSectionGoal(false)
  }

  const finalizeSection = async (): Promise<void> => {
    if (section == null) {
      return
    }

    if (section.goal == null && section.indicators.every(i => i.goal == null)) {
      toast.error('Please create at least one section or indicator goal.')
      return
    }

    setIsFinalizing(true)
  }

  const handleCancelFinalizeSection = async (): Promise<void> => {
    setIsFinalizing(false)
  }

  const confirmFinalizeSection = async (): Promise<void> => {
    const errors = await FinalizeSectionPlan(props.sectionId)
    if (errors.length > 0) {
      errors.forEach(e => toast.error(e))
      setIsFinalizing(false)
      return
    }

    toast.success('Wow! You created a section plan for the CETA!')
    props.onFinalize()
    props.goBack()
  }

  const printSection = async (): Promise<void> => {
    const printRoute = generatePath(AppRoutes.CETA_SECTION_PRINT, { classroomId: props.classroomId, tab: CETAOverviewTabs.Plan, sectionIds: section?.id.toString() ?? '' })
    nav(printRoute)
  }

  const isGoalLocked = (indicator?: CETAPlanIndicator): boolean => {
    if (props.readOnly) {
      return true
    }

    if (section == null) {
      return false
    }

    if (indicator == null) {
      return section.goalLock != null && section.goalLock.lockedBy.id !== user?.id && section.goalLock.lockedUntil > new Date()
    }

    return indicator?.goalLock != null && indicator.goalLock.lockedBy.id !== user?.id && indicator.goalLock.lockedUntil > new Date()
  }

  const indicatorColor = (rating: number): string => {
    if (rating < 4) {
      return '#AD67D9'
    }

    return '#50AE1F'
  }

  const indicatorBorderColor = (rating: number): string => {
    if (rating < 4) {
      return '#6C3F88'
    }

    return '#2D6510'
  }

  const lockText = (lock?: CETAGoalLock): string => {
    if (props.readOnly) {
      return 'This plan has been locked and cannot be edited.'
    }

    return `${lock?.lockedBy.fullName ?? ''} is editing until ${lock?.lockedUntil.toLocaleTimeString() ?? ''}`
  }

  if (section === null) {
    return <CircularProgress />
  }

  const sectionGoalLocked = isGoalLocked()

  return <Grid container spacing={5} ml='0'>
    <Grid item sx={{ maxWidth: '75%' }}>
      <Box display='flex' alignItems='center'>
        <Typography variant='h5' fontWeight='600' fontSize='1.5em'>{section.name}</Typography>

        <PrintOutlined sx={{ cursor: 'pointer', color: '#004A70', ml: '.5em' }} onClick={printSection} />
      </Box>

      {section.goal == null &&
        <>
          {!sectionGoalLocked &&
            <BlueButton onClick={handleUpdateSectionGoal} sx={{ my: '1em' }} dataTestId='addSectionGoal'>
              Add Section Goal
              <AddCircle sx={{ ml: '0.5em' }} />
            </BlueButton>
          }

          {sectionGoalLocked &&
            <Tooltip arrow={true} title={lockText(section.goalLock)} slotProps={{ tooltip: { sx: { fontSize: '1em' } } }}>
              <Box sx={{ width: 'fit-content', mb: '1em' }}>
                <BlueButton onClick={() => {}} disabled={true} dataTestId='addSectionGoalLocked'>
                  Add Section Goal
                  <Lock sx={{ ml: '0.5em' }} />
                </BlueButton>
              </Box>
            </Tooltip>
          }
        </>
      }

      {section.goal != null &&
        <>
          <Box display='flex' alignItems='center' mb='1em'>
            <Typography fontSize='1.25em'>Section Goal</Typography>

            {!sectionGoalLocked &&
              <Edit
                onClick={handleUpdateSectionGoal}
                data-testid='editSectionGoal'
                sx={{ fill: 'black', ml: '1em', cursor: 'pointer' }}
              />
            }

            {sectionGoalLocked &&
              <Tooltip arrow={true} title={lockText(section.goalLock)} slotProps={{ tooltip: { sx: { fontSize: '1em' } } }}>
                <Lock sx={{ ml: '1em' }} data-testid='editSectionGoalLocked' />
              </Tooltip>
            }
          </Box>

          <CETAGoalDetails readOnly={true} goal={section.goal} />
        </>
      }

      {section.indicators.map((indicator, index) => {
        const indicatorGoalLocked = isGoalLocked(indicator)

        return <Accordion
          key={index}
          disableGutters={true}
          sx={{ mb: '1em !important', border: '1px solid #CBC8CC', borderRadius: '16px !important', '&:before': { display: 'none' } }}
        >
          <AccordionSummary expandIcon={<KeyboardArrowDown data-testid='expandIndicator' />}>
            <Box width='100%' display='flex' justifyContent='space-between'>
              <Box display='flex' alignItems='center'>
                <Typography ml='.5em'>{indicator.name}</Typography>

                <Tooltip title={indicator.description}>
                  <Help sx={{ color: '#59555B', width: '.7em', height: '.7em', ml: '.25em' }} />
                </Tooltip>
              </Box>

              <Box display='flex' alignItems='center' ml='2em'>
                <RatingRadio
                  readOnly={true}
                  initialValue={indicator.rating}
                  checkedBackgroundColor={indicatorColor(indicator.rating)}
                  checkedBorderColor={indicatorBorderColor(indicator.rating)}
                />

                {indicator.rating <= 3 && indicator.goal == null &&
                  <ErrorOutline sx={{ mx: '.5em' }} />
                }
                {indicator.rating > 3 && indicator.goal == null &&
                  <AddCircleOutline sx={{ mx: '.5em' }} />
                }
                {indicator.goal != null &&
                  <CheckCircleOutline sx={{ mx: '.5em' }} />
                }
              </Box>
            </Box>
          </AccordionSummary>

          <AccordionDetails sx={{ borderTop: '1px solid #CBC8CC' }}>
            {indicator.goal == null &&
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Typography>{indicator.notes}</Typography>

                {!indicatorGoalLocked &&
                  <BlueButton onClick={async () => { await handleUpdateIndicatorGoal(indicator) }} sx={{ ml: '1em', minWidth: '200px' }} dataTestId='addIndicatorGoal'>
                    Add Indicator Goal
                    <AddCircle sx={{ ml: '0.5em' }} />
                  </BlueButton>
                }

                {indicatorGoalLocked &&
                  <Tooltip arrow={true} title={lockText(indicator.goalLock)} slotProps={{ tooltip: { sx: { fontSize: '1em' } } }}>
                    <Box>
                      <BlueButton onClick={() => {}} disabled={true} sx={{ ml: '1em', minWidth: '200px' }} dataTestId='addIndicatorGoalLocked'>
                        Add Indicator Goal
                        <Lock sx={{ ml: '0.5em' }} />
                      </BlueButton>
                    </Box>
                  </Tooltip>
                }
              </Box>
            }

            {indicator.goal != null &&
              <>
                <Box display='flex' alignItems='center' mb='1em' data-testid='indicatorGoalHeader'>
                  <Typography fontSize='1.25em' mr='1em'>Indicator Goal</Typography>

                  {!indicatorGoalLocked &&
                    <Edit
                      sx={{ fill: 'black', mr: '1em', cursor: 'pointer' }}
                      data-testid='editIndicatorGoal'
                      onClick={async () => { await handleUpdateIndicatorGoal(indicator) }}
                    />
                  }

                  {indicatorGoalLocked &&
                    <Tooltip arrow={true} title={lockText(indicator.goalLock)} slotProps={{ tooltip: { sx: { fontSize: '1em' } } }}>
                      <Lock sx={{ mr: '1em' }} data-testid='editIndicatorGoalLocked' />
                    </Tooltip>
                  }

                  <RatingRadio
                    readOnly={true}
                    initialValue={indicator.goal.targetMeasure}
                    checkedBackgroundColor='#50AE1F'
                    checkedBorderColor='#2D6510'
                  />
                </Box>

                <CETAGoalDetails readOnly={true} goal={indicator.goal} />
              </>
            }
          </AccordionDetails>
        </Accordion>
      })}

      {!section.finalized && !props.readOnly &&
        <BlueButton onClick={finalizeSection} dataTestId='publishSection'>
          Publish Section Plan
        </BlueButton>
      }
    </Grid>

    <Grid item>
      <Card
        sx={{
          backgroundColor: '#FAFEFF',
          maxWidth: '300px',
          border: '1px solid #21AEDE',
          p: '1em'
        }}
      >
        <Typography variant='h5' color={'#004A70'}>
          {CETAInstructionsKey.PlanningInstructions}
        </Typography>

        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: planningInstructions }} />

        <Typography variant='body1' fontWeight='600' sx={{ marginBottom: '1em', marginTop: '1em' }}>Icon Legend</Typography>
        <IconWithLabel sx={{ marginBottom: '.5em' }} icon={<CheckCircleOutline />} labelText='Goal Created - Indicator' />
        <IconWithLabel sx={{ marginBottom: '.5em' }} icon={<CheckCircle />} labelText='Goal Created - Section' />
        <IconWithLabel sx={{ marginBottom: '.5em' }} icon={<ErrorOutline />} labelText='Consider As Priority' />
        <IconWithLabel sx={{ marginBottom: '.5em' }} icon={<AddCircleOutline />} labelText='Strength' />
      </Card>
    </Grid>

    {isEditingSectionGoal &&
      <EditCETAGoalModal
        classroomId={props.classroomId}
        goal={section.goal}
        name={section.name}
        isSectionGoal={true}
        sectionId={props.sectionId}
        onClose={handleCancelSectionGoal}
        onConfirm={handleUpdateSectionGoalConfirm}
      />
    }

    {isEditingIndicatorGoal && selectedIndicator != null &&
      <EditCETAGoalModal
        classroomId={props.classroomId}
        goal={selectedIndicator.goal}
        name={selectedIndicator.name}
        isSectionGoal={false}
        indicatorId={selectedIndicator.id}
        onClose={handleCancelIndicatorGoal}
        onConfirm={handleUpdateIndicatorGoalConfirm}
      />
    }

    {isFinalizing &&
      <Modal
        open={true}
        title='Publish Section'
        bodyContent='By clicking "Publish", your Section and Indicator Goals will be saved. Would you like to continue?'
        confirmButtonText='Yes, Publish'
        cancelButtonText='No, Cancel'
        onClose={handleCancelFinalizeSection}
        onConfirm={confirmFinalizeSection}
        data-testid='publishSectionModal'
      />
    }
  </Grid>
}
