import { useState } from 'react'
import { type CETAResource } from '../../../../models/CETAResource'
import { Modal } from '../../../customComponents/Modal'
import { TextFieldWithLabel } from '@oaisd/michdev.components.react'
import { DeleteCETAResource, SaveCETAResource } from '../../../../services/CETAClassroomService'
import { toast } from 'react-toastify'

interface Props {
  resource: CETAResource | null
  onClose: () => void
  onSubmit: (resource: CETAResource, isNew: boolean) => void
  onDelete: (resourceId: number) => void
}

const emptyResource: CETAResource = {
  id: 0,
  name: '',
  link: '',
  lastUpdated: new Date()
}

export const CETAResourceEditModal = (props: Props): JSX.Element => {
  const [resource, setResource] = useState<CETAResource>(props.resource ?? emptyResource)
  const [showRequired, setShowRequired] = useState<boolean>(false)

  const isNew = resource.id === 0

  const handleDelete = async (): Promise<void> => {
    if (resource.id === 0) {
      return
    }

    await DeleteCETAResource(resource.id)
    toast.success('Resource deleted')

    props.onDelete(resource.id)
  }

  const handleSubmit = async (): Promise<void> => {
    if (resource.name === '' || resource.link === '') {
      setShowRequired(true)
      toast.error('Please fill out all required fields')
      return
    }

    resource.id = await SaveCETAResource(resource.id, resource.name, resource.link)
    toast.success('Resource saved')

    props.onSubmit(resource, isNew)
  }

  return <Modal
    open={true}
    title='Edit Resource'
    maxWidth='sm'
    cancelButtonText={isNew ? 'Cancel' : 'Delete Resource'}
    centerButtonText='Cancel'
    confirmButtonText='Save Resource'
    cancelButtonClassName={isNew ? undefined : 'modal-deny-button'}
    onClose={props.onClose}
    onCancel={isNew ? props.onClose : handleDelete}
    onCenterButtonClick={isNew ? undefined : props.onClose}
    onConfirm={handleSubmit}
    bodyContent={<>
      <TextFieldWithLabel
        name='name'
        label='Name'
        value={resource.name}
        required={showRequired && resource.name === ''}
        showRequiredText={showRequired && resource.name === ''}
        error={showRequired && resource.name === ''}
        dataTestId='resourceName'
        textFieldClassName='pb-0'
        onChange={(e) => { setResource({ ...resource, name: e.target.value }) }}
      />

      <TextFieldWithLabel
        name='link'
        label='Link'
        value={resource.link}
        required={showRequired && resource.link === ''}
        showRequiredText={showRequired && resource.link === ''}
        error={showRequired && resource.link === ''}
        dataTestId='resourceLink'
        textFieldClassName='pb-0'
        onChange={(e) => { setResource({ ...resource, link: e.target.value }) }}
      />
    </>}
  />
}
