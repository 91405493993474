import { Box, Card, Link, Typography } from '@mui/material'
import type { OnboardingResource } from '../../../models/OnboardingResource'
import { CheckCircle, LockOutlined, PendingActionsOutlined } from '@mui/icons-material'
import Checkbox from '@mui/material/Checkbox'
import { IconWithLabel } from '../IconWithLabel'
import { ResourceLayers } from '../../../assets/svgs/ResourceLayers'
import { BlueButton } from '../buttons/BlueButton'
import CheckIcon from '@mui/icons-material/Check'
import { InfoCard } from '../InfoCard'
import { type OnboardingTask } from '../../../models/OnboardingTask'

interface Props {
  readonly: boolean
  onboardingComplete: boolean
  tasks: OnboardingTask[]
  resources: OnboardingResource[]
  completeOnboardingText?: string
  title?: string
  subTitle?: string
  minWidth?: string
  borderColor?: string
  backgroundColor?: string
  titleColor?: string
  bottomContent?: JSX.Element
  toggleTask: (taskKey: string) => void
  verifyCompletion?: () => void
}

export const OnboardingSummary = (props: Props): JSX.Element => {
  const taskIcon = (task: OnboardingTask): JSX.Element => {
    if (task.completed && !task.manual) {
      return <CheckCircle style={{ color: '#004A70' }} data-testid='autoTaskComplete' />
    }
    if (task.locked) {
      return <LockOutlined style={{ color: '#004A70' }} data-testid='taskLocked' />
    }
    if (task.manual) {
      return <Checkbox
        checked={task.completed}
        sx={{ p: 0, color: '#004A70 !important', cursor: props.readonly ? 'default' : 'pointer' }}
        onClick={props.readonly ? undefined : () => { props.toggleTask(task.key) }}
        data-testid='manualTask'
      />
    }

    return <PendingActionsOutlined style={{ color: '#004A70' }} data-testid='autoTaskIncomplete' />
  }

  return <Card
    sx={{
      backgroundColor: props.backgroundColor ?? '#FAFEFF',
      minHeight: '3em',
      minWidth: props.minWidth ?? '',
      maxWidth: '300px',
      border: '1px solid ' + (props.borderColor ?? '#21AEDE'),
      p: '1em'
    }}
  >
    <Typography variant='h5' color={props.titleColor ?? '#004A70'} textAlign='center' fontWeight='600'>
      {props.title ?? 'Onboarding Process'}
    </Typography>

    <Box>
      <Typography variant='body2' sx={{ my: '1em' }}>{props.subTitle ?? 'Track your onboarding progress here:'}</Typography>

      {props.onboardingComplete && props.verifyCompletion != null &&
        <InfoCard message='Onboarding complete' dataTestid='onboardingComplete' />
      }

      {props.tasks.every(t => t.completed) && !props.readonly && props.verifyCompletion != null &&
        <BlueButton onClick={props.verifyCompletion} sx={{ display: 'block', mx: 'auto', px: '1.5em' }} dataTestId='verifyOnboarding'>
          {props.completeOnboardingText}
          <CheckIcon sx={{ verticalAlign: 'middle', ml: '.25em', width: '16px' }} />
        </BlueButton>
      }

      {props.tasks.map((task, taskIndex) => (
        <IconWithLabel
          key={`${taskIndex}-iconwithlabel`}
          sx={{ marginTop: '1em', marginBottom: '1em' }}
          labelText={task.name}
          labelColor='#004A70'
          dataTestId={`${task.key}-task`}
          icon={taskIcon(task)}
        />
      ))
      }
    </Box>

    {props.resources.length > 0 && <>
      <Typography variant='body2' sx={{ marginTop: '1em', marginBottom: '1em' }}>
        Here are some resources to help you on your way:
      </Typography>

      {props.resources.map((res, resIndex) => {
        return <Box key={`${resIndex}-box`} sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'row', width: '100%', marginTop: '1em', marginBottom: '1em' }}>
          <ResourceLayers />
          <Link
            sx={{ ml: '.625em' }}
            color='#000000'
            target='_blank'
            data-testid={res.name}
            href={(res.link.startsWith('https://') ? res.link : `https://${res.link}`)}
          >
            {res.name}
          </Link>
        </Box>
      })}
    </>
    }

    {props.bottomContent}
  </Card>
}
