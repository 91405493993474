import { Box, Typography } from '@mui/material'
import { TextFieldWithLabel } from '@oaisd/michdev.components.react'
import { useContext, useState } from 'react'
import { AddUser } from '../../../../services/UserManagementService'
import { toast } from 'react-toastify'
import { type FieldError } from '../../../../hooks/use-fetch'
import { generatePath, useNavigate } from 'react-router'
import { AppRoutes } from '../../../Routes'
import { BannerModal } from '../../../customComponents/BannerModal'
import { Modal } from '../../../customComponents/Modal'
import { OrgTabs } from '../OrganizationDetails'
import { BreadcrumbContext, SetBreadcrumbContext } from '../../../../contextProviders/BreadcrumbProvider'
import { type OrganizationDetailsModel } from '../../../../models/OrganizationDetailsModel'

export interface NewUserFormValues {
  organizationId: number
  firstName: string
  lastName: string
  email: string
}

interface Props {
  primaryOrg: OrganizationDetailsModel
  onClose: () => void
}

export function AddUserModal (props: Props): JSX.Element {
  const nav = useNavigate()
  const breadcrumbs = useContext(BreadcrumbContext)
  const setBreadcrumbs = useContext(SetBreadcrumbContext)

  const [duplicateUserId, setDuplicateUserId] = useState<number | null>(null)
  const [showDuplicateUserModal, setShowDuplicateUserModal] = useState<boolean>(false)
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([])
  const [formValues, setFormValues] = useState<NewUserFormValues>({
    organizationId: props.primaryOrg.id,
    firstName: '',
    lastName: '',
    email: ''
  })

  const handleFieldUpdate = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const handleSave = async (): Promise<void> => {
    const { response, success, errors } = await AddUser(formValues)
    if (!success) {
      if (response.id != null) {
        setDuplicateUserId(response.id)
        setShowDuplicateUserModal(true)
        return
      } else {
        setFieldErrors(response.fieldErrors)
        errors.forEach((error) => {
          toast.error(error)
        })
        return
      }
    }

    props.onClose()
    goToProfile(response.id, props.primaryOrg.id.toString())
  }

  const goToProfile = (userId: number, orgId: string | null): void => {
    const currentRoute = generatePath(AppRoutes.ORGANIZATION_DETAILS, { id: props.primaryOrg.id.toString(), tab: OrgTabs.Users })
    setBreadcrumbs([...breadcrumbs, { name: props.primaryOrg.name, route: currentRoute }])

    const profileRoute = generatePath(AppRoutes.USER_DETAILS, { userId: userId.toString(), orgId })
    nav(profileRoute)
  }

  const content = <Box>
    <TextFieldWithLabel
      name='firstName'
      label='First Name'
      value={formValues.firstName}
      required={fieldErrors.some((error) => error.fieldName === 'firstName')}
      showRequiredText={fieldErrors.some((error) => error.fieldName === 'firstName')}
      error={fieldErrors.some((error) => error.fieldName === 'firstName')}
      dataTestId='firstName'
      className='pt-0'
      textFieldClassName='pb-0'
      onChange={handleFieldUpdate}
    />
    <TextFieldWithLabel
      name='lastName'
      label='Last Name'
      value={formValues.lastName}
      required={fieldErrors.some((error) => error.fieldName === 'lastName')}
      showRequiredText={fieldErrors.some((error) => error.fieldName === 'lastName')}
      error={fieldErrors.some((error) => error.fieldName === 'lastName')}
      dataTestId='lastName'
      textFieldClassName='pb-0'
      onChange={handleFieldUpdate}
    />
    <TextFieldWithLabel
      name='email'
      label='Email'
      value={formValues.email}
      required={fieldErrors.some((error) => error.fieldName === 'email')}
      showRequiredText={fieldErrors.some((error) => error.fieldName === 'email')}
      error={fieldErrors.some((error) => error.fieldName === 'email')}
      dataTestId='email'
      onChange={handleFieldUpdate}
    />

    {showDuplicateUserModal &&
      <BannerModal
        title='User Already Exists'
        cancelButtonText='Close'
        confirmButtonText='View Profile'
        bodyContent={<Typography>This user already exists. Would you like to see their profile?</Typography>}
        dataTestId='duplicate-user-modal'
        onClose={() => { setShowDuplicateUserModal(false) }}
        onConfirm={() => { goToProfile(duplicateUserId ?? 0, null) }}
      />}
  </Box>

  return <>
    <Modal
      open={true}
      title='Add New User'
      confirmButtonText='Add User'
      bodyContent={content}
      onClose={props.onClose}
      onConfirm={handleSave}
    />
  </>
}
