import { LabelWithToolTip } from '@oaisd/michdev.components.react'
import { Box, Grid } from '@mui/material'
import type { User } from '../../../models/User'
import type { Subscription } from '../../../models/Subscription'
import { useEffect, useState } from 'react'
import { SquareSwitch } from '../../customComponents/SquareSwitch'

interface Props {
  subscriptions: Subscription[] | undefined
  updateSubscriptions: (newSubscriptions: Subscription[]) => void
  onUpdateUser?: (updatedUser: User) => void
}

export function SubscriptionsEditView (props: Props): JSX.Element {
  const [subscriptionValues, setSubscriptionValues] = useState(props.subscriptions)
  const overall: JSX.Element[] = []

  useEffect(() => {
    if (props.subscriptions !== undefined) {
      setSubscriptionValues(props.subscriptions)
      generateRows()
    }
  }, [props.subscriptions])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    const subscriptionName: string = e.target.name.split('-')[0]
    const subscriptionType: string = e.target.name.split('-')[1]
    const arraySubs: Subscription[] = []
    if (subscriptionValues !== undefined) {
      subscriptionValues.forEach(s => {
        if (s.name === subscriptionName) {
          if (subscriptionType === 'app') {
            s.inApp = checked
          } else {
            s.inEmail = checked
          }
        }
        arraySubs.push(s)
      })
      props.updateSubscriptions(arraySubs)
      setSubscriptionValues(arraySubs)
    }
  }

  function generateRows (): void {
    if (props.subscriptions !== undefined && props.subscriptions.length > 0) {
      props.subscriptions.forEach(s => {
        const inAppButton = <td>
          <SquareSwitch
            name={`${s.name}-app`}
            checked={s.inApp}
            onChange={handleChange}
            data-testid={`${s.name.replaceAll(' ', '')}-Checkbox-inapp`}
            sx={{ ml: '3em' }}
          />
        </td>

        const inEmailButton = <td>
          <SquareSwitch
            name={`${s.name}-email`}
            checked={s.inEmail}
            onChange={handleChange}
            data-testid={`${s.name.replaceAll(' ', '')}-Checkbox-inemail`}
            sx={{ ml: '3em' }}
          />
        </td>

        overall.push(
          <tr key={s.name} data-testid={s.name + 'Checkboxes'}>
            <Box component='td' sx={{ pl: 4 }}>
              {s.name}
            </Box>
            {inAppButton}
            {inEmailButton}
          </tr>
        )
      })
    }
  }

  if (props.subscriptions !== undefined) {
    generateRows()
  }

  return <>
    <Grid item columns={6}>
      <table width='100%'>
        <thead>
          <tr>
            <td><LabelWithToolTip
              labelText='Event'
              sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word', fontWeight: '600', ml: '3.75em' }}
            /></td>
            <td><LabelWithToolTip
              labelText='In App'
              sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word', fontWeight: '600', ml: '3.75em' }}
            /></td>
            <td><LabelWithToolTip
              labelText='In Email'
              sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word', fontWeight: '600', ml: '3.75em' }}
            /></td>
          </tr>
        </thead>
        <tbody>
          { overall.map(f => { return f }) }
        </tbody>
      </table>
    </Grid>
  </>
}
