import { useEffect, useState } from 'react'
import { CETAClassroomSearch, type CETAClassroomSearchValues } from './CETAClassroomSearch'
import { AddCETAClassroomModal } from './AddCETAClassroomModal'
import { type PaginationProps, usePagination } from '../../../hooks/use-pagination'
import DataTable from '../../customComponents/dataTable/DataTable'
import { Column, ColumnSort, ColumnType } from '../../customComponents/dataTable/DataTable.model'
import { GetCETAClassrooms } from '../../../services/CETAClassroomService'
import { BlockButton } from '../../customComponents/buttons/BlockButton'
import { Box, Breadcrumbs, Chip, CircularProgress, Typography } from '@mui/material'
import { type SelectableOption } from '../../../utils/genericTypes'
import { AccessTime, ArrowBack, CheckCircle, DoNotDisturb } from '@mui/icons-material'
import { ProfileImage } from '../../customComponents/user/ProfileImage'
import type { SelectOption } from '@oaisd/michdev.components.react'
import { GetBuildingOptionsForOrg } from '../../../services/UserManagementService'
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom'
import { CETAInvitationModal } from './CETAInvitationModal'
import { toast } from 'react-toastify'
import { AppRoutes } from '../../Routes'
import { CETAClassroomStage } from '../../../models/CETAClassroomStage'
import { CETAClassroomStatus } from '../../../models/CETAClassroomStatus'
import { GetTimeSpan } from '../../../services/ImplementationService'
import { type ImplementationTimeSpan } from '../../../models/ImplementationTimeSpan'
import { CETAOverviewTabs } from './CETAOverview'
import { GetAvailableOrganizations } from '../../../services/OrganizationService'

export interface CETAClassroomListModel {
  id: number
  teacherName: string
  district: string
  building: string
  coach: string
  coachImageKey: string
  status: string
  stage: string
  updatedAt: Date
  updatedBy: string
  hasStarted: boolean
  hasAccessTo: boolean
}

export function CETAClassroomList (): JSX.Element {
  const { id: inviteIdParam, timeSpanId } = useParams()
  const [cetaInviteId, setCetaInviteId] = useState(inviteIdParam)
  const [showInviteModal, setShowInviteModal] = useState(inviteIdParam !== undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [isAddingNewClassroom, setIsAddingNewClassroom] = useState(false)
  const [classrooms, setClassrooms] = useState<CETAClassroomListModel[]>([])
  const [buildingOptions, setBuildingOptions] = useState<Array<SelectOption<string>>>([])
  const [districtOptions, setDistrictOptions] = useState<Array<SelectOption<string>>>([])
  const [classSearchValues, setSearchValues] = useState<CETAClassroomSearchValues>({ timeSpanId: parseInt(timeSpanId ?? '0'), buildingId: null, districtId: null, stage: '', name: '', status: '' })
  const [timeSpan, setTimeSpan] = useState<ImplementationTimeSpan | null>(null)
  const nav = useNavigate()

  const paginationProps: PaginationProps<CETAClassroomListModel> = {
    rows: classrooms
  }
  const pagination = usePagination(paginationProps)

  const loadData = async (): Promise<void> => {
    setIsLoading(true)

    if (districtOptions.length === 0) {
      await getDistrictSearchOptions()
    }

    if (timeSpanId != null) {
      const response = await GetTimeSpan(timeSpanId)
      setTimeSpan(response)
    }

    await searchClassrooms()
    setIsLoading(false)
  }

  useEffect(() => {
    void loadData()
  }, [classSearchValues, timeSpanId])

  const getDistrictSearchOptions = async (): Promise<void> => {
    const options: SelectableOption[] = await GetAvailableOrganizations()
    const arrayDistricts: Array<SelectOption<string>> = []
    if (options !== undefined) {
      options.forEach((dist) => {
        arrayDistricts.push({ id: dist.id.toString(), name: dist.name })
      })
      setDistrictOptions(arrayDistricts)
    }
  }

  const getBuildingOptionsForOrg = async (orgId: number | null): Promise<void> => {
    if (orgId === null) {
      setBuildingOptions([])
    } else {
      const options: SelectableOption[] = await GetBuildingOptionsForOrg(orgId)
      if (options !== undefined) {
        const arrayBuildings: Array<SelectOption<string>> = []
        options.forEach((build) => {
          arrayBuildings.push({ id: build.id.toString(), name: build.name })
        })
        setBuildingOptions(arrayBuildings)
      }
    }
  }

  const addNewClassroom = async (): Promise<void> => {
    setIsLoading(true)
    await searchClassrooms()
    hideAddModal()
    setIsLoading(false)
  }

  const showAddModal = (): void => {
    setIsAddingNewClassroom(true)
  }

  const hideAddModal = (): void => {
    setIsAddingNewClassroom(false)
  }

  const filtersUpdated = async (searchValues: CETAClassroomSearchValues): Promise<void> => {
    setSearchValues(searchValues)
  }

  const searchClassrooms = async (): Promise<void> => {
    setClassrooms(await GetCETAClassrooms(classSearchValues))
  }

  const handleRowClicked = (classroom: CETAClassroomListModel): void => {
    if (classroom.stage === CETAClassroomStage.AwaitingApproval) {
      setCetaInviteId(classroom.id.toString())
      setShowInviteModal(true)
    } else {
      let tab = CETAOverviewTabs.GettingStarted
      if (classroom.hasStarted) {
        tab = CETAOverviewTabs.Dashboard
      }
      const route = generatePath(AppRoutes.CETA_OVERVIEW, { classroomId: classroom.id.toString(), tab })
      nav(route)
    }
  }

  const approveCetaInvite = async (): Promise<void> => {
    toast.info(customToastWithLink(`/CETAOverview/${cetaInviteId ?? '0'}/${CETAOverviewTabs.GettingStarted}`), { autoClose: false })
    setShowInviteModal(false)
    await searchClassrooms()
  }

  const customToastWithLink = (linkUrl: string): any => (
    <div>
      <p>Your CETA Classroom has been approved. Click <Link to={linkUrl}>here</Link> to view the details.</p>
    </div>
  )

  const denyCetaInvite = async (): Promise<void> => {
    toast.info('Your CETA has been denied')
    setShowInviteModal(false)
    await searchClassrooms()
  }

  const teacherNameColumn = new Column('teacherName', 'Classroom')
  const districtColumn = new Column('district', 'District')
  const buildingColumn = new Column('building', 'Building')
  const stageColumn = new Column('stage', 'Stage')

  const coachColumn = new Column('coach', 'Coach', ColumnType.CUSTOM)
  coachColumn.customCellGenerator = (row: CETAClassroomListModel) => {
    return row.coach === ''
      ? <></>
      : <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ProfileImage name={row.coach} imageKey={row.coachImageKey} />
        <Box sx={{ ml: '.5em' }}>{row.coach}</Box>
      </Box>
  }

  const statusColumn = new Column('status', 'Status', ColumnType.CUSTOM)
  statusColumn.customCellGenerator = (row: CETAClassroomListModel) => {
    let icon = <AccessTime />
    if (row.status === CETAClassroomStatus.Closed) {
      icon = <CheckCircle/>
    } else if (row.status === CETAClassroomStatus.Active) {
      icon = <AccessTime />
    } else if (row.status === CETAClassroomStatus.Pending) {
      icon = <DoNotDisturb/>
    }
    return <>
      <Chip icon={icon} label={row.status} />
    </>
  }

  const lastChangedColumn = new Column('updatedAt', 'Added By', ColumnType.CUSTOM)
  lastChangedColumn.customCellGenerator = (row: CETAClassroomListModel) => {
    return <>
      <Box>
        {row.updatedBy}
      </Box>
      <Box>
        {new Date(row.updatedAt.toString() + 'Z').toLocaleString([], { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}
      </Box>
    </>
  }

  const clickableColumn = new Column('', '', ColumnType.CUSTOM)
  clickableColumn.sortable = false
  clickableColumn.customCellGenerator = (row: CETAClassroomListModel) => {
    if (!row.hasAccessTo) {
      return <></>
    }

    const buttonTestId = row.id.toString() + row.stage + '-button'
    return <BlockButton onClick={() => { handleRowClicked(row) }} dataTestId={buttonTestId}>
      {row.stage === CETAClassroomStage.AwaitingApproval ? 'Review Invite' : 'View Classroom'}
    </BlockButton>
  }

  const columns = [teacherNameColumn, districtColumn, buildingColumn, coachColumn, stageColumn, statusColumn, lastChangedColumn, clickableColumn]

  if (timeSpan === null || timeSpanId == null) {
    return <CircularProgress />
  }

  return <>
    <Breadcrumbs maxItems={1} aria-label="breadcrumb" sx={{ mb: '.5em' }}>
      <Link to={generatePath(AppRoutes.IMPLEMENTATION_INSTANCE, { timeSpanId })} style={{ color: 'inherit', textDecoration: 'none' }} data-testid='backToImplementation'>
        <ArrowBack sx={{ verticalAlign: 'middle', mr: '5px' }} />
        Back to {timeSpan.startYear}/{timeSpan.startYear + 1}
      </Link>
    </Breadcrumbs>

    <Typography variant='h3' className='page-header' data-testid='pageHeader'>CETA Classrooms</Typography>

    <Box sx={{ width: 'fit-content', mt: '1em' }}>
      <CETAClassroomSearch
        timeSpanId={parseInt(timeSpanId)}
        districtList={districtOptions}
        buildingList={buildingOptions}
        handleAddNewClassroom={showAddModal}
        onDistrictChanged={getBuildingOptionsForOrg}
        filtersUpdated={filtersUpdated}
      />

      <Box sx={{ marginTop: '2em' }}>
        <DataTable
          name='classrooms'
          rows={pagination.internalRows}
          columns={columns}
          page={pagination.page}
          totalRecords={pagination.recordCount}
          loading={isLoading}
          initialColumnSorts={[new ColumnSort('name')]}
          onSortChange={(col, sorts) => { pagination.onSortChange(col, sorts[0].order) }}
          onPageChange={pagination.handleChangePage}
          onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        />
      </Box>
    </Box>

    {isAddingNewClassroom &&
      <AddCETAClassroomModal
        timeSpanId={parseInt(timeSpanId)}
        closeModal={hideAddModal}
        handleAddClassroom={addNewClassroom}
      />
    }

    {showInviteModal &&
      <CETAInvitationModal
        cetaId={parseInt(cetaInviteId ?? '0')}
        onApprove={approveCetaInvite}
        onDeny={denyCetaInvite}
        onCancel={() => { setShowInviteModal(false) }}
      />
    }
  </>
}
