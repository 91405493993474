import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Box, CircularProgress, Typography } from '@mui/material'
import { BreadcrumbNav } from '../../customComponents/BreadcrumbNav'
import { GetOrganization } from '../../../services/OrganizationService'
import { type OrganizationDetailsModel } from '../../../models/OrganizationDetailsModel'

export const PlanningInstance = (): JSX.Element => {
  const { rcnId } = useParams()

  const [rcn, setRcn] = useState<OrganizationDetailsModel | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (rcnId == null) {
        return
      }

      const rcn = await GetOrganization(rcnId)
      setRcn(rcn)
    }

    void fetchData()
  }, [rcnId])

  if (rcn == null) {
    return <CircularProgress />
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
      }}
      >
        <BreadcrumbNav />
        <Typography variant='h3' className='page-header'> Annual Planning </Typography>
      </Box>

      <Typography variant='h6'>{rcn.name}</Typography>
    </Box>
  )
}
