import { type SyntheticEvent, useState, useCallback } from 'react'
import { type SelectOption } from '../SelectWithLabel'
import { type SelectableOption } from '../../../utils/genericTypes'
import { Box, Grid, TextField, debounce } from '@mui/material'
import MUIAutoComplete from '../MUIAutoComplete'
import { BlueButton } from '../buttons/BlueButton'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import SearchIcon from '@mui/icons-material/Search'

export interface UserSearchValues {
  primaryOrgId: number
  secondaryOrgId: number | null
  building: number | null
  accessStatus: string | null
  name: string
}

interface UserSearchProps {
  primaryOrgId: number
  hideOrganizations?: boolean
  hideBuildings?: boolean
  accessLabel?: string
  buildingOptions?: SelectableOption[]
  organizationOptions?: SelectableOption[]
  accessStatusOptions?: Array<SelectOption<string>>
  onButtonClick: () => void
  filtersUpdated: (searchValues: UserSearchValues) => void
}

export const UserSearch = (props: UserSearchProps): JSX.Element => {
  const [selectedOrganization, setSelectedOrganization] = useState<SelectableOption | null>(null)
  const [selectedBuilding, setSelectedBuilding] = useState<SelectableOption | null>(null)
  const [selectedAccessStatus, setSelectedAccessStatus] = useState<string | null>(null)
  const [selectedName, setSelectedName] = useState<string>('')

  const onOrganizationChange = (e: SyntheticEvent, value: { id: string | number, name: string } | null): void => {
    setSelectedOrganization(value != null ? { id: value.id as number, name: value.name } : null)
    const values = getMemberSearchValues(value?.id as number, selectedBuilding?.id ?? null, selectedAccessStatus, selectedName)
    props.filtersUpdated(values)
  }

  const onBuildingChange = (e: SyntheticEvent, value: { id: string | number, name: string } | null): void => {
    setSelectedBuilding(value != null ? { id: value.id as number, name: value.name } : null)
    const values = getMemberSearchValues(selectedOrganization?.id ?? null, value?.id as number, selectedAccessStatus, selectedName)
    props.filtersUpdated(values)
  }

  const onAccessStatusChange = (e: SyntheticEvent, value: { id: string | number, name: string } | null): void => {
    setSelectedAccessStatus(value?.id as string)
    const values = getMemberSearchValues(selectedOrganization?.id ?? null, selectedBuilding?.id ?? null, value?.id as string, selectedName)
    props.filtersUpdated(values)
  }

  const onNameChange = (e: any): void => {
    setSelectedName(e.target.value)
    debouncedNameUpdate(e.target.value)
  }

  const debouncedNameUpdate = useCallback(
    debounce(name => { handleNameUpdate(name) }, 500),
    [props.primaryOrgId, selectedOrganization, selectedBuilding, selectedAccessStatus]
  )

  const handleNameUpdate = (name: string): void => {
    const values = getMemberSearchValues(selectedOrganization?.id ?? null, selectedBuilding?.id ?? null, selectedAccessStatus, name)
    props.filtersUpdated(values)
  }

  function getMemberSearchValues (secondaryOrgId: number | null, building: number | null, accessStatus: string | null, name: string): UserSearchValues {
    const searchValues: UserSearchValues = {
      primaryOrgId: props.primaryOrgId,
      secondaryOrgId,
      building,
      accessStatus,
      name
    }
    return searchValues
  }

  return <Box>
    <Grid container spacing={1} rowSpacing={-1} justifyContent='space-between'>
      <Grid item container spacing={1} rowSpacing={-1} width='fit-content'>
        {props.hideBuildings !== true &&
          <Grid item>
            <MUIAutoComplete
              label='Building'
              name='building'
              value={selectedBuilding != null ? { id: selectedBuilding.id, name: selectedBuilding.name } : undefined}
              onChange={onBuildingChange}
              options={props.buildingOptions ?? []}
              sx={{ width: '200px' }}
            />
          </Grid>
        }

        {props.hideOrganizations !== true &&
          <Grid item>
            <MUIAutoComplete
              label='Organization'
              name='organization'
              value={selectedOrganization != null ? { id: selectedOrganization.id, name: selectedOrganization.name } : undefined}
              onChange={onOrganizationChange}
              options={props.organizationOptions ?? []}
              sx={{ width: '200px' }}
            />
          </Grid>
        }

        <Grid item>
          <MUIAutoComplete
            label={props.accessLabel ?? 'Access Status'}
            name='accessStatus'
            value={selectedAccessStatus != null ? { id: selectedAccessStatus, name: selectedAccessStatus } : undefined}
            onChange={onAccessStatusChange}
            options={props.accessStatusOptions ?? []}
            sx={{ width: '200px' }}
          />
        </Grid>
      </Grid>

      <Grid item container spacing={1} rowSpacing={-1} justifyContent='flex-end' width='fit-content'>
        <Grid item>
          <Box sx={{ position: 'relative' }}>
            <TextField
              label='User Name'
              name='name'
              onChange={onNameChange}
              value={selectedName}
              sx={{ width: '100%' }}
              data-testid='name'
            />
            <SearchIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '0.5em' }} />
          </Box>
        </Grid>

        <Grid item>
          <BlueButton onClick={props.onButtonClick} sx={{ height: '4em' }} dataTestId='addUser'>
            Add New User
            <AddCircleIcon sx={{ ml: '0.5em' }} />
          </BlueButton>
        </Grid>
      </Grid>
    </Grid>
  </Box>
}
