import { useState } from 'react'
import { BannerModal } from '../../../customComponents/BannerModal'
import { DatePickerWithLabel } from '../../../customComponents/DatePickerWithLabel'
import { SelectWithLabel } from '../../../customComponents/SelectWithLabel'
import { TextArea } from '../../../customComponents/TextArea'
import { ActionStatus, type CETAPlanAction, type GoalOwner } from '../../../../models/CETAPlanSectionModel'
import { toast } from 'react-toastify'
import { type SxProps, Typography, type SelectChangeEvent } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'

interface Props {
  ownerOptions: GoalOwner[]
  onClose: () => void
  onConfirm: (newAction: CETAPlanAction) => void
}

export const AddCETAGoalActionModal = (props: Props): JSX.Element => {
  const [owner, setOwner] = useState<GoalOwner | undefined>()
  const [targetDate, setTargetDate] = useState<Date | null>(null)
  const [summary, setSummary] = useState<string>('')
  const [showActionRequired, setShowActionRequired] = useState(false)
  const [showOwnerHelpText, setShowOwnerHelpText] = useState(false)
  const [showTargetDateHelpText, setShowTargetDateHelpText] = useState(false)
  const [showSummaryHelpText, setShowSummaryHelpText] = useState(false)

  const handleOwnerChange = (e: SelectChangeEvent): void => {
    const selectedOwner = props.ownerOptions.find(o => o.id === parseInt(e.target.value))
    if (selectedOwner != null) {
      setOwner(selectedOwner)
    }
  }

  const handleConfirmAddAction = (): void => {
    if (owner == null || targetDate == null || summary === '') {
      setShowActionRequired(true)
      toast.error('Please complete all required fields.')
      return
    }

    const newAction: CETAPlanAction = {
      owner,
      targetDate,
      summary,
      status: ActionStatus.NotStarted
    }

    props.onConfirm(newAction)
  }

  const handleDateChange = (newValue: Date | null): void => {
    if (newValue == null) {
      return
    }

    if (newValue.getFullYear() < 1000) {
      return
    }

    if (newValue < new Date()) {
      toast.error('Goal dates and action dates must be in the future.')
    } else {
      setTargetDate(newValue)
    }
  }

  const labelTheme: SxProps = { width: '16px', height: '16px', ml: '.5em', color: '#004A70', cursor: 'pointer' }
  const ownerLabel = <>Owner<HelpIcon onClick={() => { setShowOwnerHelpText(true) }} sx={ labelTheme } /></>
  const targetDateLabel = <>Target Date<HelpIcon onClick={() => { setShowTargetDateHelpText(true) }} sx={ labelTheme } /></>
  const summaryLabel = <>Summary<HelpIcon onClick={() => { setShowSummaryHelpText(true) }} sx={ labelTheme } /></>

  return <BannerModal
    title='Add Action'
    confirmButtonText='Add Action'
    onClose={props.onClose}
    onConfirm={handleConfirmAddAction}
    dataTestId='addActionModal'
    bodyContent={<>
      <SelectWithLabel
        labelContent={ownerLabel}
        name='actionOwner'
        options={props.ownerOptions.map(o => ({ id: o.id.toString(), name: o.name }))}
        value={owner?.id.toString() ?? ''}
        required={showActionRequired && owner == null}
        showRequiredText={showActionRequired && owner == null}
        error={showActionRequired && owner == null}
        width='100%'
        onChange={handleOwnerChange}
      />

      <DatePickerWithLabel
        name='actionTargetDate'
        labelContent={targetDateLabel}
        value={targetDate}
        required={showActionRequired && targetDate == null}
        showRequiredText={showActionRequired && targetDate == null}
        error={showActionRequired && targetDate == null }
        dataTestId='actionTargetDate'
        onChange={handleDateChange}
        disablePast={true}
        sx={{ mt: '8px' }}
      />

      <TextArea
        initialValue={summary}
        name='actionSummary'
        labelContent={summaryLabel}
        placeholder='What is your action?'
        required={showActionRequired && summary === ''}
        showRequiredText={showActionRequired && summary === ''}
        error={showActionRequired && summary === ''}
        onChange={(e) => { setSummary(e.target.value) }}
        sx={{ mt: '8px' }}
      />

      {showOwnerHelpText &&
        <BannerModal
          title=''
          cancelButtonText='Close'
          hideConfirmButton={true}
          onClose={() => { setShowOwnerHelpText(false) }}
          onConfirm={() => { setShowOwnerHelpText(false) }}
          bodyContent={<Typography>The owner is the team member primarily responsible for the achievement of this goal or action item.</Typography>}
        />
      }

      {showTargetDateHelpText &&
        <BannerModal
          title=''
          cancelButtonText='Close'
          hideConfirmButton={true}
          onClose={() => { setShowTargetDateHelpText(false) }}
          onConfirm={() => { setShowTargetDateHelpText(false) }}
          bodyContent={<Typography>The target date is the date by which the goal or action item should be completed.</Typography>}
        />
      }

      {showSummaryHelpText &&
        <BannerModal
          title=''
          cancelButtonText='Close'
          hideConfirmButton={true}
          onClose={() => { setShowSummaryHelpText(false) }}
          onConfirm={() => { setShowSummaryHelpText(false) }}
          bodyContent={<Typography>The summary is the description of your goal or action item.</Typography>}
        />
      }
    </>}
  />
}
