import { sendGet, sendPost } from '../hooks/use-fetch'
import { type MiDXOnboardingDetails } from '../models/MiDXOnboardingDetails'
import { type MiDXStaffModel } from '../models/MiDXStaffModel'

export async function GetMiDXStaff (orgId: string): Promise<MiDXStaffModel[]> {
  const { response } = await sendGet(`MiDX/GetMiDXStaff?orgId=${orgId}`)
  return response
}

export async function GetOnboardingDetails (orgId: string): Promise<MiDXOnboardingDetails> {
  const { response } = await sendGet(`MiDX/GetOnboardingDetails?orgId=${orgId}`)
  return response
}

export async function VerifyMiDXConnection (orgId: number): Promise<void> {
  await sendPost('MiDX/VerifyMiDXConnection', { orgId })
}
