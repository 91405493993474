import { type CETAGoalLock } from './CETAGoalLock'

export interface CETAPlanSectionModel {
  id: number
  name: string
  finalized: boolean
  goalLock?: CETAGoalLock
  goal?: CETAGoal
  indicators: CETAPlanIndicator[]
}

export interface CETAPlanIndicator {
  id: number
  name: string
  description: string
  rating: number
  notes: string
  goal?: CETAGoal
  goalLock?: CETAGoalLock
}

export interface CETAGoal {
  id?: number
  targetMeasure?: number
  owner: GoalOwner
  targetGoalDate: Date
  summary: string
  isComplete: boolean
  actions: CETAPlanAction[]
  notes: CETAGoalNote[]
}

export interface CETAGoalNote {
  id: number
  owner: GoalOwner
  updateDate: Date
  note: string
}

export interface CETAPlanAction {
  id?: number
  owner: GoalOwner
  targetDate: Date
  summary: string
  status: ActionStatus
}

export interface GoalOwner {
  id: number
  name: string
  imageKey?: string
}

export enum ActionStatus {
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  OnHold = 'On Hold',
  Complete = 'Complete'
}
