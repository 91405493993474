import { useEffect, useState } from 'react'
import { GetCETAPlanSection, GetTemplateInstructions } from '../../../../services/CETAClassroomService'
import { TranslateSectionDates } from '../../../../utils/cetaUtils'
import { ActionStatus, type CETAPlanSectionModel } from '../../../../models/CETAPlanSectionModel'
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CircularProgress, Grid, Tooltip, Typography } from '@mui/material'
import { CETAGoalDetails } from '../Plan/CETAGoalDetails'
import { CheckCircle, Help, KeyboardArrowDown, PrintOutlined } from '@mui/icons-material'
import { RadioButton, RatingRadio } from '../../../customComponents/RatingRadio'
import { CETAInstructionsKey } from '../../../../models/CETATemplateInstructions'
import { generatePath, useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../Routes'
import { CETAOverviewTabs } from '../CETAOverview'

interface Props {
  readOnly: boolean
  classroomId: string
  sectionId: number
  goBack: () => void
  refetchOverviewSections: () => void
}

export const CETAProgressUpdateSection = (props: Props): JSX.Element => {
  const nav = useNavigate()

  const [section, setSection] = useState<CETAPlanSectionModel | null>(null)
  const [progressUpdateInstructions, setProgressUpdateInstructions] = useState('')

  const getSection = async (): Promise<void> => {
    const sectionResponse = await GetCETAPlanSection(props.sectionId)
    setSection(TranslateSectionDates(sectionResponse))
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const results = await Promise.all([
        GetTemplateInstructions(props.classroomId),
        getSection()
      ])

      setProgressUpdateInstructions(results[0].progressUpdate)
    }

    void fetchData()
  }, [props.sectionId])

  const printSection = async (): Promise<void> => {
    const printRoute = generatePath(AppRoutes.CETA_SECTION_PRINT, { classroomId: props.classroomId, tab: CETAOverviewTabs.ProgressUpdates, sectionIds: section?.id.toString() ?? '' })
    nav(printRoute)
  }

  const indicatorColor = (rating: number): string => {
    if (rating < 4) {
      return '#AD67D9'
    }

    return '#50AE1F'
  }

  const indicatorBorderColor = (rating: number): string => {
    if (rating < 4) {
      return '#6C3F88'
    }

    return '#2D6510'
  }

  if (section === null) {
    return <CircularProgress />
  }

  return <Grid container spacing={5} ml='0'>
    <Grid item sx={{ maxWidth: '75%' }}>
      <Box display='flex' alignItems='center'>
        <Typography variant='h5' fontWeight='600' fontSize='1.5em'>{section.name}</Typography>

        <PrintOutlined sx={{ cursor: 'pointer', color: '#004A70', ml: '.5em' }} onClick={printSection} />
      </Box>

      {section.goal != null &&
        <Box sx={{ mb: '1em' }}>
          <Typography fontSize='1.25em'>Section Goal</Typography>

          <CETAGoalDetails
            goal={section.goal}
            includeProgressUpdates={true}
            readOnly={props.readOnly}
            onSave={getSection}
          />
        </Box>
      }

      {section.indicators.map((indicator, index) => {
        return indicator.goal != null &&
          <Accordion
            key={index}
            disableGutters={true}
            sx={{ mb: '1em !important', border: '1px solid #CBC8CC', borderRadius: '16px !important', '&:before': { display: 'none' } }}
          >
            <AccordionSummary expandIcon={<KeyboardArrowDown data-testid='expandIndicator' />}>
              <Box width='100%' display='flex' justifyContent='space-between'>
                <Box display='flex' alignItems='center'>
                  <Typography ml='.5em'>{indicator.name}</Typography>

                  <Tooltip title={indicator.description}>
                    <Help sx={{ color: '#59555B', width: '.7em', height: '.7em', ml: '.25em' }} />
                  </Tooltip>
                </Box>

                <Box display='flex' alignItems='center' ml='2em'>
                  <Box sx={{ mr: '.5em', display: 'flex', alignItems: 'center', border: '1px solid #21AEDE', backgroundColor: '#88CAEA', borderRadius: '10px', px: '6px', py: '3px' }}>
                    <Typography fontSize='.875em' lineHeight='normal' data-testid='actionsCompleted'>
                      {indicator.goal.actions.filter(a => a.status === ActionStatus.Complete).length}
                      /
                      {indicator.goal.actions.length} Actions Completed
                    </Typography>

                    <CheckCircle sx={{ color: '#3B353E', ml: '.5em' }} />
                  </Box>

                  <RadioButton
                    readOnly={true}
                    checked={true}
                    value={indicator.rating}
                    checkedBackgroundColor={indicatorColor(indicator.rating)}
                    checkedBorderColor={indicatorBorderColor(indicator.rating)}
                  />
                </Box>
              </Box>
            </AccordionSummary>

            <AccordionDetails sx={{ borderTop: '1px solid #CBC8CC' }}>
              <Box display='flex' alignItems='center' mb='1em' data-testid='indicatorGoalHeader'>
                <Typography fontSize='1.25em' mr='1em'>Indicator Goal</Typography>

                <RatingRadio
                  readOnly={true}
                  initialValue={indicator.goal.targetMeasure}
                  checkedBackgroundColor='#50AE1F'
                  checkedBorderColor='#2D6510'
                />
              </Box>

              <CETAGoalDetails
                goal={indicator.goal}
                includeProgressUpdates={true}
                readOnly={props.readOnly}
                onSave={getSection}
              />
            </AccordionDetails>
          </Accordion>
      })}
    </Grid>

    <Grid item>
      <Card
        sx={{
          backgroundColor: '#FAFEFF',
          maxWidth: '350px',
          border: '1px solid #21AEDE',
          p: '1em'
        }}
      >
        <Typography variant='h5' color={'#004A70'}>
          {CETAInstructionsKey.ProgressUpdateInstructions}
        </Typography>

        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: progressUpdateInstructions }} />
      </Card>
    </Grid>
  </Grid>
}
