import { useState } from 'react'
import { Modal } from '../../../customComponents/Modal'
import { type CETAUpdateDates } from '../../../../models/CETAUpdateDates'
import { DatePickerWithLabel } from '../../../customComponents/DatePickerWithLabel'
import { SaveCETAUpdateDates } from '../../../../services/CETATemplateService'
import { toast } from 'react-toastify'

interface Props {
  dates: CETAUpdateDates | null
  onClose: () => void
  onSubmit: (dates: CETAUpdateDates) => void
}

export const CETAUpdateDatesEditModal = (props: Props): JSX.Element => {
  const [beginDate, setBeginDate] = useState<Date | null>(props.dates?.beginDate ?? null)
  const [endDate, setEndDate] = useState<Date | null>(props.dates?.endDate ?? null)
  const [showDateRequired, setShowDateRequired] = useState<boolean>(false)

  const handleSubmit = async (): Promise<void> => {
    if (beginDate == null || endDate == null) {
      setShowDateRequired(true)
      toast.error('Please fill out all required fields')
      return
    }

    if (beginDate > endDate) {
      toast.error('Begin Date must be before End Date')
      return
    }

    const dates: CETAUpdateDates = {
      beginDate,
      endDate
    }

    await SaveCETAUpdateDates(dates)

    toast.success('CETA Update dates saved')

    props.onSubmit(dates)
  }

  return <Modal
    open={true}
    title='Edit CETA Update Dates'
    maxWidth='sm'
    cancelButtonText='Cancel'
    confirmButtonText='Save Dates'
    onClose={props.onClose}
    onConfirm={handleSubmit}
    bodyContent={
      <>
        <DatePickerWithLabel
          name='beginDate'
          label='Begin Date'
          value={beginDate}
          required={showDateRequired && beginDate == null}
          showRequiredText={showDateRequired && beginDate == null}
          error={showDateRequired && beginDate == null }
          dataTestId='beginDate'
          onChange={setBeginDate}
          sx={{ mt: '.5em' }}
        />

        <DatePickerWithLabel
          name='endDate'
          label='End Date'
          value={endDate}
          required={showDateRequired && endDate == null}
          showRequiredText={showDateRequired && endDate == null}
          error={showDateRequired && endDate == null }
          dataTestId='endDate'
          onChange={setEndDate}
          sx={{ mt: '.5em' }}
        />
      </>
    }
  />
}
