import { Button, Card, Typography } from '@mui/material'
import { ProfileImage } from '../../customComponents/user/ProfileImage'
import { ArrowForward } from '@mui/icons-material'
import { type MouseEventHandler } from 'react'

interface RCNCardProps {
  name: string
  imageKey?: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

const cardColorsList = ['#FDE3E9', '#FEFCE4', '#EFFAE7', '#C3EAFE', '#EDD7FB']
const avatarColorsList = ['#BF0649', '#AC8700', '#46981B', '#006699', '#6C3F88']

export const RCNCard = (props: RCNCardProps): JSX.Element => {
  const backgroundColor = cardColorsList[props.name.charCodeAt(0) % cardColorsList.length]
  const avatarColor = avatarColorsList[props.name.charCodeAt(0) % avatarColorsList.length]

  return (
    <Card data-testid="rcn-card" sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '13.5px',
      alignItems: 'center',
      maxWidth: '285px',
      width: '100%',
      height: '270px',
      borderRadius: '16px',
      padding: '24px',
      backgroundColor
    }}
    >
      <Typography
        variant='h6'
        width='100%'
        textOverflow='ellipsis'
        overflow='hidden'
        whiteSpace='nowrap'
        title={props.name}
      >{props.name}</Typography>

      <ProfileImage
        width='7.5em'
        height='7.5em'
        backgroundColor={avatarColor}
        name={props.name}
        imageKey={props.imageKey}
      />

      <Button
        data-testid="view-annual-plans-button"
        onClick={props.onClick}
        sx={{
          padding: '12px 24px',
          backgroundColor: '#FFFFFF',
          color: '#3B353E'
        }}
      >
        View Annual Plans <ArrowForward sx={{ ml: '10px' }} />
      </Button>
    </Card>
  )
}
