import { Box, Chip, CircularProgress, Grid, MenuItem, Select, Typography } from '@mui/material'
import { generatePath, useNavigate, useParams } from 'react-router'
import { UserDetails } from '../../customComponents/user/Details'
import { useEffect, useState } from 'react'
import { type User } from '../../../models/User'
import { GetOrganizationOptionsForUser, GetUserDetails, SetDefaultOrganization, UpdateStatus } from '../../../services/UserManagementService'
import { AccessStatus, ActiveStatus, OrganizationType, SecurityLevel, type SelectableOption } from '../../../utils/genericTypes'
import { useAuth } from '../../../hooks/use-auth'
import { AuthFunctions, hasPermission } from '../../../utils/permissions'
import { MemberOrganizationsList } from './OrganizationsList'
import { AuthorizedFunctionsEditModal, type AuthFunctionsForm } from './authorizedFunctions/AuthorizedFunctionsEditModal'
import { type UserOrganization } from '../../../models/UserOrganization'
import { SubscriptionsEditModal } from './SubscriptionsEditModal'
import { Modal, type SelectOption } from '@oaisd/michdev.components.react'
import { RemoveAccessToOrganization, SaveUserFunctions } from '../../../services/AuthorizedFunctionService'
import { toast } from 'react-toastify'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Colors } from '../../../utils/colors'
import { type SelectableOrganization } from '../../../models/SelectableOrganization'
import { EditBuildingsModal } from './EditBuildingsModal'
import { BreadcrumbNav } from '../../customComponents/BreadcrumbNav'
import { type Subscription } from '../../../models/Subscription'
import { AppRoutes } from '../../Routes'

const initialMember: User = {
  id: 0,
  firstName: '',
  lastName: '',
  fullName: '',
  title: '',
  email: '',
  address: {
    id: 0,
    street: '',
    city: '',
    state: '',
    zip: ''
  },
  updatedAt: new Date(),
  updatedBy: '',
  accessStatus: AccessStatus.Active,
  isActive: true,
  buildings: [],
  organizations: [],
  securityLevel: SecurityLevel.RCNAdmin
}

const activeStatusOptions: Array<SelectOption<string>> = Object.values(ActiveStatus).map((key) => ({ id: key, name: key }))

export interface ProfileOrg {
  id: number
  name: string
  organizationType: OrganizationType
  roleName?: SecurityLevel
  buildings: SelectableOption[]
  subscriptions: Subscription[]
  imageKey?: string
}

export function UserProfile (): JSX.Element {
  const { userId, orgId } = useParams()
  const auth = useAuth()
  const nav = useNavigate()

  const [member, setMember] = useState<User>(initialMember)
  const [orgOptions, setOrgOptions] = useState<SelectableOrganization[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isEditingOrg, setIsEditingOrg] = useState<boolean>(false)
  const [isEditingSubs, setIsEditingSubs] = useState<boolean>(false)
  const [isRemovingOrg, setIsRemovingOrg] = useState<boolean>(false)
  const [isConfirmingInactive, setIsConfirmingInactive] = useState<boolean>(false)
  const [isEditingBuildings, setIsEditingBuildings] = useState<boolean>(false)
  const [selectedOrg, setSelectedOrg] = useState<ProfileOrg | null>(null)

  const hasOtherOrg = member.organizations.some((org) => !orgOptions.some((o) => o.id === org.id))
  const readOnly = auth.user == null ||
    !(hasPermission(auth.user, AuthFunctions.PROJECT_SETTINGS) ||
      hasPermission(auth.user, AuthFunctions.RCN_MANAGEMENT) ||
      hasPermission(auth.user, AuthFunctions.ISD_MANAGEMENT) ||
      hasPermission(auth.user, AuthFunctions.DISTRICT_MANAGEMENT) ||
      hasPermission(auth.user, AuthFunctions.RCN_ADMINISTRATION) ||
      hasPermission(auth.user, AuthFunctions.ISD_ADMINISTRATION) ||
      hasPermission(auth.user, AuthFunctions.DISTRICT_ADMINISTRATION)) ||
      auth.user.id.toString() === userId

  const loadUser = async (userId?: string): Promise<void> => {
    if (userId != null) {
      const user = await GetUserDetails(userId)
      setMember(user)
    }
    setIsLoading(false)
  }

  const sortOrgs = (orgs: SelectableOrganization[]): SelectableOrganization[] => {
    return orgs.sort((a, b) => {
      if (a.type === b.type) {
        return 0
      }

      if (a.type === OrganizationType.Project ||
          b.type === OrganizationType.District ||
         (a.type === OrganizationType.RCN && b.type === OrganizationType.ISD)) {
        return -1
      }

      return 1
    })
  }

  async function loadData (userId?: string): Promise<void> {
    if (userId != null) {
      const user = await GetUserDetails(userId)
      setMember(user)

      const orgs = await GetOrganizationOptionsForUser()
      setOrgOptions(sortOrgs(orgs))
    }
    setIsLoading(false)
  }

  useEffect(() => {
    void loadData(userId)
  }, [userId])

  useEffect(() => {
    if (orgId != null) {
      const org = orgOptions.find((o) => o.id === parseInt(orgId))
      if (org != null) {
        setSelectedOrg({ id: org.id, name: org.name, organizationType: org.type, buildings: [], subscriptions: [] })
        setIsEditingOrg(true)
      }
    }
  }, [orgId, orgOptions])

  const handleDefaultOrgClicked = async (organizationId: number): Promise<void> => {
    if (readOnly) {
      return
    }

    await SetDefaultOrganization(userId ?? '', organizationId)
    const tmpMember = { ...member }
    tmpMember.organizations.forEach((org) => {
      org.isDefault = org.id === organizationId
    })
    setMember(tmpMember)
    toast.success('Default organization updated')
  }

  const handleAddNewOrg = (): void => {
    setIsEditingOrg(true)
  }

  const handleEditOrg = (org: UserOrganization): void => {
    setSelectedOrg(org)
    setIsEditingOrg(true)
  }

  const handleEditOrgClose = (): void => {
    setIsEditingOrg(false)
    setSelectedOrg(null)
    if (orgId != null) {
      const route = generatePath(AppRoutes.USER_DETAILS, { userId: userId ?? '', orgId: null })
      nav(route)
    }
  }

  const handleRemoveOrg = (org: UserOrganization): void => {
    setSelectedOrg(org)
    setIsRemovingOrg(true)
  }

  const handleRemoveOrgClose = (): void => {
    setIsRemovingOrg(false)
    setSelectedOrg(null)
  }

  const handleEditBuildings = (org: UserOrganization): void => {
    setSelectedOrg(org)
    setIsEditingBuildings(true)
  }

  const handleEditBuildingsClose = (): void => {
    setIsEditingBuildings(false)
    setSelectedOrg(null)
  }

  const handleEditBuildingsSave = async (): Promise<void> => {
    handleEditBuildingsClose()
    setIsLoading(true)
    await loadUser(userId)
  }

  const handleSubscriptionsEdit = (org: UserOrganization): void => {
    setSelectedOrg(org)
    setIsEditingSubs(true)
  }

  const handleSubscriptionsEditSave = async (): Promise<void> => {
    setIsEditingSubs(false)
    setIsLoading(true)
    await loadUser(userId)
  }

  const handleSubscriptionsEditCancel = async (): Promise<void> => {
    setIsEditingSubs(false)
  }

  const onSaveMember = async (newId: number): Promise<void> => {
    setIsLoading(true)
    await loadUser(userId)
  }

  const onUpdateUser = async (member: User): Promise<void> => {
    setMember(member)
  }

  const handleSaveAccess = async (form: AuthFunctionsForm): Promise<void> => {
    await SaveUserFunctions(parseInt(userId ?? ''), form.orgId, form.securityLevel, form.functions)
    toast.success('User access saved successfully.')
    handleEditOrgClose()

    setIsLoading(true)
    await loadUser(userId)
  }

  const handleRemoveConfirm = async (): Promise<void> => {
    await RemoveAccessToOrganization(userId ?? '', selectedOrg?.id ?? 0)
    setIsRemovingOrg(false)
    setSelectedOrg(null)

    toast.success('Organization removed')

    setIsLoading(true)
    await loadUser(userId)
  }

  const handleStatusChange = async (event: any): Promise<void> => {
    const status = event.target.value as ActiveStatus
    if (status === ActiveStatus.Inactive) {
      setIsConfirmingInactive(true)
    } else {
      await onConfirmStatusChange(true)
    }
  }

  const onConfirmStatusChange = async (active: boolean): Promise<void> => {
    await UpdateStatus(userId ?? '', active)
    setIsConfirmingInactive(false)
    toast.success(`User ${active ? 'activated' : 'deactivated'}`)
    setIsLoading(true)
    await loadUser(userId ?? '')
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return <>
    <Box>
      <BreadcrumbNav />

      <Typography variant='h3' className='page-header'>User Profile</Typography>

      <Box sx={{ display: 'flex', mt: '1em' }}>
        <Typography variant='h4' sx={{ fontSize: '1.25em' }} data-testid='nameDisplay'>{member.firstName} {member.lastName}</Typography>

        {!hasOtherOrg && <Select
          value={member.isActive ? ActiveStatus.Active : ActiveStatus.Inactive}
          sx={{ fontSize: '.875em', height: '1.75em', ml: '3em' }}
          inputProps={{ 'aria-label': 'Active Status' }}
          data-testid='activeStatusSelect'
          onChange={handleStatusChange}
          renderValue={(value) =>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FiberManualRecordIcon sx={{ color: value === ActiveStatus.Active ? Colors.ACTIVE_TEXT : Colors.INACTIVE_TEXT, fontSize: '1em', mr: '5px' }} />
              {value}
            </Box>
          }
        >
          {activeStatusOptions.map((option) => (
            <MenuItem key={option.id} value={option.id} data-testid={`statusMenuItem-${option.name}`}>{option.name}</MenuItem>
          ))}
        </Select>}

        {hasOtherOrg && <Box data-testid='activeStatusDisplay' sx={{ ml: '3em' }}>
          <Chip label={
            <>
              <FiberManualRecordIcon sx={{ color: member.isActive ? Colors.ACTIVE_TEXT : Colors.INACTIVE_TEXT, fontSize: '1em', mr: '5px', verticalAlign: 'middle' }} />
              {member.isActive ? ActiveStatus.Active : ActiveStatus.Inactive}
            </>}
          />
        </Box>}
      </Box>

      <Grid container spacing={2}>
        <Grid item sx={{ ml: '2em', mt: '2em' }}>
          <UserDetails
            users={[member]}
            imageKey={member.imageKey}
            detailName={member.firstName}
            isOrganizationView={false}
            isEditable={!readOnly}
            onSave={onSaveMember}
            onUpdate={onUpdateUser}
          />
        </Grid>

        <Grid item sx={{ mt: '2em' }} data-testid='organizationsSection'>
          <MemberOrganizationsList
            organizations={member.organizations}
            orgOptions={orgOptions}
            readOnly={readOnly}
            handleDefaultOrgClicked={handleDefaultOrgClicked}
            handleAddNewOrg={handleAddNewOrg}
            handleEditOrg={handleEditOrg}
            handleRemoveOrg={handleRemoveOrg}
            handleBuildingsEdit={handleEditBuildings}
            handleSubscriptionsEdit={handleSubscriptionsEdit}
          />
        </Grid>
      </Grid>
    </Box>

    {isEditingOrg && <AuthorizedFunctionsEditModal
      member={member}
      org={selectedOrg != null ? { id: selectedOrg?.id, name: selectedOrg.name, type: selectedOrg.organizationType } : undefined}
      orgOptions={orgOptions}
      initialSecurityLevel={selectedOrg?.roleName}
      isAccessRequest={false}
      closeModal={handleEditOrgClose}
      handleSave={handleSaveAccess}
    />}

    {isRemovingOrg && <Modal
      open={true}
      title='Remove Organization'
      confirmationContent={`Are you sure you want to remove the access for this user in ${selectedOrg?.name ?? ''}?`}
      cancelButtonText='No, Cancel'
      confirmButtonText='Yes, Continue'
      buttonClassName='modal-button'
      confirmButtonClassName='modal-confirm-button'
      onClose={handleRemoveOrgClose}
      onConfirm={handleRemoveConfirm}
    />
    }

    {isEditingSubs && <SubscriptionsEditModal
      onUpdateUser={onUpdateUser}
      user={member}
      org={selectedOrg}
      orgName={`${member.firstName} ${member.lastName}`}
      closeModal={handleSubscriptionsEditCancel}
      handleSave={handleSubscriptionsEditSave}
    />}

    {isConfirmingInactive && <Modal
      open={true}
      title='Deactivate User'
      confirmationContent='This will prevent this user from accessing the system. Do you want to continue?'
      cancelButtonText='No, Cancel'
      confirmButtonText='Yes, Continue'
      buttonClassName='modal-button'
      confirmButtonClassName='modal-confirm-button'
      onClose={() => { setIsConfirmingInactive(false) }}
      onConfirm={async () => { await onConfirmStatusChange(false) }}
    />}

    {isEditingBuildings && selectedOrg != null && <EditBuildingsModal
      user={member}
      orgId={selectedOrg.id}
      buildings={selectedOrg.buildings}
      onClose={handleEditBuildingsClose}
      onSubmit={handleEditBuildingsSave}
    />}
  </>
}
