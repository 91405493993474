import { useCallback, useEffect, useState } from 'react'
import { SortOrder, type Column } from '../pages/customComponents/dataTable/DataTable.model'

export interface PaginationProps<RowData> {
  rows: RowData[]
  initialFilter?: SearchCriteria[]
  initialSize?: number
  pageSizeSteps?: number[]
}

export const toggleSortDirection = (
  currentDir: SortOrder
): SortOrder => {
  return currentDir === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC
}

export interface Pagination<RowData> {
  internalRows: RowData[]
  handleChangePage: (_e: unknown, newPage: number) => void
  handleChangeRowsPerPage: (value: number) => void
  onSortChange: (column: Column, direction: SortOrder) => void
  setSearchFields: (searchFields: SearchCriteria[]) => void
  sortDirection: SortOrder
  sortField: Column
  pageSteps: number[]
  page: number
  pageSize: number
  recordCount: number
}

export interface SearchCriteria {
  field: string
  value: string
  exact?: boolean
}

export function usePagination<RowData> (props: PaginationProps<RowData>): Pagination<RowData> {
  const { rows } = props
  const pageSteps = props.pageSizeSteps ?? [5, 10, 25]
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(props.initialSize ?? 5)
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const [sortField, setSortField] = useState<Column>({ key: 'id' } as Column)
  const [sortDirection, setSortDirection] = useState<SortOrder>(SortOrder.DESC)
  const [rowCount, setRowCount] = useState(props.rows.length)
  const [searchFields, setSearchFields] = useState<SearchCriteria[]>(props.initialFilter ?? [])

  const handleSetSearchFields = (newSearchCriteria: SearchCriteria[]): void => {
    setSearchFields(newSearchCriteria)
    setPage(0)
  }

  const handleChangePage = (_e: unknown, newPage: number): void => {
    setPage(newPage)
  }

  const onSortChange = (column: Column, direction: SortOrder): void => {
    setSortField(column)
    setSortDirection(direction)
  }

  const handleChangeRowsPerPage = (value: number): void => {
    setPageSize(value)
    setPage(0)
  }

  const [internalRows, setInternalRows] = useState<RowData[]>(rows)

  const sortFunction = useCallback(
    (a: RowData, b: RowData) => {
      if (Reflect.has(a as object, sortField.key)) {
        const aField = Reflect.get(a as object, sortField.key)
        const bField = Reflect.get(b as object, sortField.key)
        if (sortDirection === SortOrder.ASC) {
          if (aField < bField) return -1
          else return 1
        } else {
          if (aField > bField) return -1
          else return 1
        }
      } else return 0
    },
    [sortField.key, sortDirection]
  )

  useEffect(() => {
    let newRows = [...rows]

    // filter
    searchFields.map(sf => {
      if (sf.value === '' || sf.value == null) return true

      newRows = newRows.filter((r) => {
        let value = r as object
        let index = ''
        sf.field.split('.').forEach(i => {
          index = i
          if (!Array.isArray(value)) {
            value = Reflect.get(value, i)
          }
        })

        if (Array.isArray(value)) {
          const matches = value.filter(v => {
            return Reflect.get(v, index).toString() === sf.value.toString()
          })
          return matches.length > 0
        } else {
          const strVal = JSON.stringify(value).toLocaleLowerCase()
          if (sf.exact === true) {
            return strVal.replaceAll('"', '') === `${sf.value}`.toLocaleLowerCase()
          }
          return strVal.replaceAll('"', '').includes(`${sf.value}`.toLocaleLowerCase())
        }
      })
      return newRows
    })

    // sort
    newRows.sort(sortFunction)

    // paginate
    setRowCount(newRows.length)
    const current = page * pageSize
    const max = (page + 1) * pageSize
    const newRowSlice = newRows.slice(current, max)

    setInternalRows(newRowSlice)
  }, [
    sortDirection,
    sortField,
    pageSize,
    page,
    rows,
    sortFunction,
    searchFields
  ])

  return {
    internalRows,
    handleChangePage,
    handleChangeRowsPerPage,
    onSortChange,
    setSearchFields: handleSetSearchFields,
    sortDirection,
    sortField,
    pageSteps,
    page,
    pageSize,
    recordCount: rowCount
  }
}
