import { Box } from '@mui/material'
import type React from 'react'
import { Footer } from '../footer'
import Header from '../header'

export const PAGE_MARGIN_X = 5.2
export const PAGE_MARGIN_TOP = 3.7

export const Layout = (props: {
  children: React.ReactNode
}): JSX.Element => {
  return (
    <Box sx={{ height: '100vh', width: '100%' }}>
      <Header />
      <Box
        sx={{
          p: 0,
          mx: `${PAGE_MARGIN_X}vw`,
          mt: `${PAGE_MARGIN_TOP}vh`,
          pb: '40px',
          flexWrap: '',
          justifyContent: 'start'
        }}
      >
        <Box width='100%'>
          {props.children}
        </Box>
      </Box>

      <Footer />
    </Box>
  )
}
