export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum ColumnType {
  TEXT = 'TEXT',
  CUSTOM = 'CUSTOM'
}

export class ColumnSort {
  constructor (public key: string, public order = SortOrder.ASC) {
  }
}

export class Column {
  visibility = true
  sortable = true
  customCellGenerator: (row: any) => JSX.Element = row => row[this.key]

  constructor (public key: string,
    public label: string,
    public type = ColumnType.TEXT) {
  }
}

export type RowData = Record<string, any>

export const DEFAULT_CHECKBOX_ID_COLUMN_NAME = 'id'
export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100]
