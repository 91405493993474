import { Card, type SxProps, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

interface Props {
  dataTestid?: string
  message: string | JSX.Element
  sx?: SxProps
}

export const InfoCard = (props: Props): JSX.Element => {
  return <Card sx={{ ...props.sx, backgroundColor: '#88CAEA', border: '1px solid #21AEDE', p: '8px', display: 'flex', mt: '1em' }} data-testid={props.dataTestid}>
    <InfoOutlinedIcon sx={{ alignSelf: 'center' }} />
    <Typography sx={{ ml: '.5em' }}>
      {props.message}
    </Typography>
  </Card>
}
