import { sendGet } from '../hooks/use-fetch'
import { type ImplementationSummary } from '../models/ImplementationSummary'
import { type ImplementationTimeSpan } from '../models/ImplementationTimeSpan'

export async function GetImplementationTimeSpans (): Promise<ImplementationTimeSpan[]> {
  const { response } = await sendGet('Implementation/GetTimeSpans')
  return response
}

export async function GetImplementationSummary (timeSpanId: string): Promise<ImplementationSummary> {
  const { response } = await sendGet(`Implementation/GetSummary?timeSpanId=${timeSpanId}`)
  return response
}

export async function GetTimeSpan (timeSpanId: string): Promise<ImplementationTimeSpan> {
  const { response } = await sendGet(`Implementation/GetTimeSpan?timeSpanId=${timeSpanId}`)
  return response
}
