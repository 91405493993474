export interface PlanOrganizationTask {
  id: number
  taskName: string
  status: PlanOrganizationTaskStatus
  organizationName: string
  planOrganizationId: number
  dueDate: Date
}

export enum PlanOrganizationTaskStatus {
  Complete = 'Complete',
  Incomplete = 'Incomplete'
}
