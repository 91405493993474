import { useEffect, useRef } from 'react'
import { ListAddIcon } from '../../assets/svgs/ListAddIcon'
import { Divider } from '@mui/material'
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill-new'

const Quill = ReactQuill.Quill

// Make the following fonts available in the editor
const fonts = Quill.import('formats/font') as any
fonts.whitelist = ['Normal', 'Heading', 'MetaData']
Quill.register(fonts, true)

// Make the line break function available in the editor
const BlockEmbed = Quill.import('blots/block/embed') as any
class LineBreakBlot extends BlockEmbed {
  static blotName = 'lineBreak'
  static tagName = 'hr'
}
Quill.register(LineBreakBlot)

interface Props {
  content: string
  setContent: (content: string) => void
}

export const RichTextEditor = (props: Props): JSX.Element => {
  const quill = useRef<ReactQuill>(null)

  // Customize the line break button to also insert a new line and not open an alert
  useEffect(() => {
    const editor = quill.current?.getEditor()
    const toolbar = editor?.getModule('toolbar') as any
    toolbar.addHandler('lineBreak', () => {
      const currentSelectionIndex = editor?.getSelection()?.index ?? 0
      editor?.insertText(currentSelectionIndex, '\n')
      editor?.insertEmbed(currentSelectionIndex, 'lineBreak', true)
      editor?.setSelection(currentSelectionIndex + 2, 0)
    })
  }, [quill])

  return <>
    <div id="toolbar" style={{ display: 'flex', backgroundColor: '#F3F3F3' }}>
      <select className="ql-font">
        <option value="Normal">Normal</option>
        <option value="Heading">Heading</option>
        <option value="MetaData">MetaData</option>
      </select>

      <Divider orientation="vertical" flexItem={true} sx={{ backgroundColor: 'black', mx: '1em' }} />

      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>

      <Divider orientation="vertical" flexItem={true} sx={{ backgroundColor: 'black', mx: '1em' }} />

      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>

      <Divider orientation="vertical" flexItem={true} sx={{ backgroundColor: 'black', mx: '1em' }} />

      <button className="ql-link"></button>
      <button className="ql-image"></button>
      <button className="ql-lineBreak"><ListAddIcon /></button>
    </div>

    <ReactQuill
      ref={quill}
      theme="snow"
      modules={{
        toolbar: {
          container: '#toolbar'
        }
      }}
      value={props.content}
      onChange={props.setContent}
    />
  </>
}
